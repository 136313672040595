import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderBentwave1 from 'images/header/header-bentwave1.png';
import patternHeaderBentwave2 from 'images/header/header-bentwave2.png';
import patternHeaderBentwave3 from 'images/header/header-bentwave3.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderBentwave = ({ style, isVisible }) => (
  <>
    <svg
      className="header-bentwave sm:hidden"
      viewBox="0 0 450 210.56"
      style={style}
    >
      <defs>
        <pattern
          id="m-headerbentwave-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 854 57"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBentwave1}
            width="854"
            height="57"
          />
        </pattern>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headerbentwave-1)"
          strokeWidth="15px"
          d="M5.31,27.59,26.63,41l55-27,44,29,57-30,43,29,61-30,42,28,59-28,33,31,21-6"
        />
      </G>
    </svg>

    <svg
      className="header-bentwave hidden sm:block"
      viewBox="0 0 810.02 210.56"
      style={style}
    >
      <defs>
        <pattern
          id="headerbentwave-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 854 57"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBentwave1}
            width="854"
            height="57"
          />
        </pattern>
        <pattern
          id="headerbentwave-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 532 243"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBentwave2}
            width="532"
            height="243"
          />
        </pattern>
        <pattern
          id="headerbentwave-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 93 96"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBentwave3}
            width="93"
            height="96"
          />
        </pattern>
        <clipPath id="headerbentwave-clip-3">
          <path
            transform="scale(1.16) translate(-103 -24)"
            d="M740.63,163l27-18,21,44-47-23"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headerbentwave-1)"
          strokeWidth="15px"
          d="M5.31,27.59,26.63,41l55-27,44,29,57-30,43,29,61-30,42,28,59-28,33,31,21-6"
        />
        <Path
          fill="none"
          stroke="url(#headerbentwave-2)"
          strokeWidth="20px"
          d="M485.63,40l111-8,159,118"
        />
        <Path
          fill="none"
          stroke="url(#headerbentwave-3)"
          strokeWidth="18px"
          clipPath="url(#headerbentwave-clip-3)"
          transform="scale(1.06) translate(-48 -14)"
          d="M740.63,163l27-18,21,44-47-23"
        />
      </G>
    </svg>
  </>
);

HeaderBentwave.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderBentwave;
