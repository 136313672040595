import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  StyledImage,
  Flex,
  Number,
  Margin,
  Relative,
} from './collage.css';
import { CaptionMargin, Caption } from '../image/image.css';

const getWidth = width => {
  switch (width) {
    case 'Text Width':
      return 'max-w-4xl mx-auto';
    case 'Extra Wide':
      return 'max-w-6xl mx-auto';
    case 'Full Bleed':
      return 'max-w-screen -mx-px';
    default:
      return 'max-w-4xl';
  }
};

const Collage = ({ list, blockWidth, prev, next }) => (
  <Margin margin={blockWidth !== 'Full Bleed' && 'mx-4 xs:mx-10 sm:mx-20'}>
    <Container
      width={getWidth(blockWidth)}
      margin={`${next ? 'mb-10 sm:mb-20 md:mb-32' : ''} ${
        prev ? 'mt-10 sm:mt-20 md:mt-32' : ''
      }`}
    >
      {list && list.length >= 4 && (
        <>
          <Flex
            height={blockWidth === 'Full Bleed' && 'sm:max-h-screen'}
            flex="flex-col md:flex-row"
          >
            <Relative>
              <StyledImage key={list[0].id} fluid={list[0].image.fluid} />
              <Number>1</Number>
            </Relative>
            <Flex flex="flex-col sm:flex-row">
              <Relative>
                <StyledImage key={list[1].id} fluid={list[1].image.fluid} />
                <Number>2</Number>
              </Relative>
              <Flex flex="sm:flex-col">
                <Relative height="h-1/2vh sm:h-full">
                  <StyledImage key={list[2].id} fluid={list[2].image.fluid} />
                  <Number>3</Number>
                </Relative>
                <Relative height="h-1/2vh sm:h-full">
                  <StyledImage key={list[3].id} fluid={list[3].image.fluid} />
                  <Number>4</Number>
                </Relative>
              </Flex>
            </Flex>
          </Flex>
          <CaptionMargin
            margin={blockWidth === 'Full Bleed' && 'mx-4 xs:mx-10 sm:mx-20'}
          >
            <Caption>
              {list.map(
                ({ description, id }, i) =>
                  description && (
                    <span key={`caption-${id}`} className="mr-4">
                      {i + 1}. {description}
                    </span>
                  )
              )}
            </Caption>
          </CaptionMargin>
        </>
      )}
      {list && list.length === 3 && (
        <>
          <Flex
            height={blockWidth === 'Full Bleed' && 'sm:max-h-screen'}
            flex="flex-col sm:flex-row"
          >
            <Relative>
              <StyledImage key={list[0].id} fluid={list[0].image.fluid} />
              <Number>1</Number>
            </Relative>
            <Flex flex="sm:flex-col">
              <Relative height="h-1/2vh sm:h-full">
                <StyledImage key={list[1].id} fluid={list[1].image.fluid} />
                <Number>2</Number>
              </Relative>
              <Relative height="h-1/2vh sm:h-full">
                <StyledImage key={list[2].id} fluid={list[2].image.fluid} />
                <Number>3</Number>
              </Relative>
            </Flex>
          </Flex>
          <CaptionMargin
            margin={blockWidth === 'Full Bleed' && 'mx-4 xs:mx-10 sm:mx-20'}
          >
            <Caption>
              {list.map(
                ({ description, id }, i) =>
                  description && (
                    <span key={`caption-${id}`} className="mr-4">
                      {i + 1}. {description}
                    </span>
                  )
              )}
            </Caption>
          </CaptionMargin>
        </>
      )}
      {list && list.length === 2 && (
        <>
          <Flex
            height={blockWidth === 'Full Bleed' && 'sm:max-h-screen'}
            flex="flex-col sm:flex-row"
          >
            <Relative width="w-full sm:w-2/3">
              <StyledImage key={list[0].id} fluid={list[0].image.fluid} />
              <Number>1</Number>
            </Relative>
            <Relative width="w-full sm:w-1/3">
              <StyledImage key={list[1].id} fluid={list[1].image.fluid} />
              <Number>2</Number>
            </Relative>
          </Flex>
          <CaptionMargin
            margin={blockWidth === 'Full Bleed' && 'mx-4 xs:mx-10 sm:mx-20'}
          >
            <Caption>
              {list.map(
                ({ description, id }, i) =>
                  description && (
                    <span key={`caption-${id}`} className="mr-4">
                      {i + 1}. {description}
                    </span>
                  )
              )}
            </Caption>
          </CaptionMargin>
        </>
      )}
      {list && list.length === 1 && (
        <>
          <StyledImage key={list[0].id} fluid={list[0].image.fluid} />
          {list[0].description && (
            <CaptionMargin
              margin={blockWidth === 'Full Bleed' && 'mx-4 xs:mx-10 sm:mx-20'}
            >
              <Caption>{list[0].description}</Caption>
            </CaptionMargin>
          )}
        </>
      )}
    </Container>
  </Margin>
);

Collage.propTypes = {
  list: PropTypes.array.isRequired,
  blockWidth: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
};

export default Collage;
