import nanostyled from 'nanostyled';
import { Link } from 'gatsby';

export const Container = nanostyled('div', {
  base: '',
  align: '',
  space: 'mt-8 mb-4',
  inline: '',
});

export const StyledLink = nanostyled(Link, {
  base:
    'inline-block p-3 xxs:p-4 border-solid border-black border-2 font-extrabold font-display max-w-xssm leading-tight text-base xxs:text-lg md:text-2xl hover:text-white hover:bg-black',
  align: 'text-center',
  margin: '',
});

export const StyledA = nanostyled('a', {
  base:
    'inline-block p-3 xxs:p-4 border-solid border-black border-2 font-extrabold font-display max-w-xssm leading-tight text-base xxs:text-lg md:text-2xl hover:text-white hover:bg-black',
  align: 'text-center',
  margin: '',
});

export const StyledButton = nanostyled('button', {
  base:
    'inline-block p-3 xxs:p-4 border-solid border-black border-2 font-extrabold font-display max-w-xssm leading-tight text-base xxs:text-lg md:text-2xl hover:text-white hover:bg-black',
  align: 'text-center',
  margin: '',
});

export const Caption = nanostyled('caption', {
  base:
    'inline-block mx-auto max-w-4xl w-full text-center font-micro font-light italic leading-normal text-sm mt-4 tracking-tight',
});
