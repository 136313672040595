import nanostyled from 'nanostyled';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const Center = nanostyled('div', {
  base: 'mx-auto max-w-8xl',
});
export const Container = nanostyled('section', {
  base: 'flex flex-wrap',
});

export const ImageLinkWrapper = nanostyled('div', {
  base: 'border-solid border-2 border-brand-blue',
  width: 'w-1/4',
});

export const ImageLink = nanostyled(Link, {
  base:
    'w-full flex flex-col  relative h-full items-center border-solid border-white group cursor-pointer',
  border: '',
});

export const StyledImg = nanostyled(Img, {
  base: 'w-full h-0 aspect-ratio-3/2',
});

export const Overlay = nanostyled('div', {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 opacity-0 transition duration-200 ease-in-out group-hover:opacity-85',
  color: 'bg-brand-blue',
});

export const BgImg = nanostyled(Img, {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 opacity-0 transition duration-200 ease-in-out group-hover:opacity-100',
});

export const TextContainer = nanostyled('div', {
  base:
    'mx-8 xxs:mx-10 lg:mx-20 my-10 inset-0 z-10 flex flex-col justify-center typography large blue relative flex-grow',
  padding: '',
});

export const Text = nanostyled('h3', {
  base: 'transition duration-200 ease-in-out group-hover:text-white',
});

export const TextBold = nanostyled('h3', {
  base: '!text-3xl !font-extrabold transition duration-200 ease-in-out group-hover:text-white',
});

export const Type = nanostyled('h4', {
  base: 'mb-0 mr-4 text-brand-blue !text-xl transition duration-200 ease-in-out group-hover:text-white',
});

export const Date = nanostyled('span', {
  base: 'mb-0 text-xl transition duration-200 ease-in-out group-hover:text-white',
});
