import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';
import PageHeader from 'components/page-header';
import PageContent from 'components/page-content';
import OurworkFilter from 'containers/ourwork-filter';
import OurworkList from 'containers/ourwork-list';

const OurWork = ({ data }) => {
  const {
    header,
    listOfCaseStudies,
    content,
    seoDescription,
    seoImage,
  } = data.contentfulCaseStudies;
  const pageTitleFull =
    header && header.title
      ? `${header.title} | The Neighborhood Design Center`
      : 'The Neighborhood Design Center';
  const fullDescription =
    (seoDescription && seoDescription.seoDescription) ||
    (header && header.description) ||
    null;
  const imageUrl = (seoImage && `https:${seoImage.fixed.src}`) || null;
  return (
    <Layout>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>
      <div style={{ background: 'white' }}>
        <PageHeader size="large" align="left" pageColor={'blue'} {...header} />
        <OurworkFilter items={listOfCaseStudies} />
        <OurworkList items={listOfCaseStudies} />
        <PageContent content={content} />
      </div>
    </Layout>
  );
};

OurWork.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OurWork;

export const query = graphql`
  query OurWorkQuery {
    contentfulCaseStudies {
      id
      title
      seoDescription {
        seoDescription
      }
      seoImage {
        fixed(width: 1024, height: 512) {
          src
          srcWebp
        }
      }
      header {
        title
        drawing
        description {
          description
          json
        }
      }
      listOfCaseStudies {
        id
        categories
        title
        slug
        pageColor
        thumbnailImage {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        projectIcon {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      content {
        id
        json
      }
    }
  }
`;
