import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import slugify from 'slugify';

// temp
import IOPageheader from 'components/io-pageheader';
import HeaderArrows from 'components/draw-in-svg/header-arrows.js';
import HeaderWave from 'components/draw-in-svg/header-wave.js';
import HeaderSquiggle from 'components/draw-in-svg/header-squiggle.js';
import HeaderUnderline from 'components/draw-in-svg/header-underline.js';
import HeaderDashed from 'components/draw-in-svg/header-dashed.js';
import HeaderBracket from 'components/draw-in-svg/header-bracket.js';
import HeaderBentwave from 'components/draw-in-svg/header-bentwave.js';
import HeaderClouds from 'components/draw-in-svg/header-clouds.js';

import Button from 'components/blocks/button';
import urlParse from 'helpers/urlParse';

import {
  Container,
  Split,
  ImageLink,
  StyledScrollLink,
  ImageLinkWrapper,
  ImageAWrapper,
  StyledImg,
  Overlay,
  DrawOverlay,
  TextContainer,
  Text,
  Icon,
} from './page-header.css';

const PageHeader = ({
  pageColor,
  cta,
  title,
  image,
  cropCoordinatesX,
  cropCoordinatesY,
  description,
  linksSection,
  pageLinks,
  size,
  align,
  drawing,
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    window && window.scrollTo(0, 1);
    setLoaded(true);
  }, []);
  return (
    <Container
      width={image ? '' : 'max-w-8xl'}
      align="mx-auto"
      height={(image || linksSection) && 'min-h-screen'}
    >
      <div className={image ? 'min-h-screen' : 'h-full'}>
        <Split flex={image ? 'min-h-screen' : 'lg:flex-grow'}>
          <div
            className={`text-black flex items-center ${image &&
              `bg-brand-${pageColor} w-full sm:w-2/3 xxl:w-1/2`} ${!image &&
              'w-full transform-reset'} ${
              align === 'left' ? 'mx-4 xs:mx-10' : 'justify-center'
            }`}
          >
            <div
              className={
                size === 'large'
                  ? ' max-w-3xl w-full mt-32 xs:mt-48 mb-16 xs:mb-24'
                  : ' max-w-xl w-full mt-32 xs:mt-48 mb-16 xs:mb-24 mx-4 xs:mx-10 sm:mx-20'
              }
            >
              <span className="relative inline-block">
                <h1
                  className={
                    size === 'large'
                      ? 'text-5xl xs:text-7xl xl:text-8xl font-extralight leading-tight'
                      : 'text-5xl xs:text-6xl sm:text-6p5xl font-extralight leading-tight'
                  }
                >
                  {title}
                </h1>
                {loaded && (
                  <IOPageheader>
                    {({ hasBeenVisible }) => (
                      <div
                        className={`absolute left-0 bottom-0 header-wrapper ${
                          image ? 'z-10' : '-z-1 bg-white no-image'
                        } ${drawing}-offset`}
                      >
                        <div
                          className={
                            image
                              ? 'relative filter-invert'
                              : 'relative bg-white'
                          }
                        >
                          {drawing == 'arrows' && (
                            <HeaderArrows isVisible={hasBeenVisible} />
                          )}
                          {drawing === 'wave' && (
                            <HeaderWave isVisible={hasBeenVisible} />
                          )}
                          {drawing === 'squiggle' && (
                            <HeaderSquiggle isVisible={hasBeenVisible} />
                          )}
                          {drawing === 'underline' && (
                            <HeaderUnderline isVisible={hasBeenVisible} />
                          )}
                          {drawing === 'dashed' && (
                            <HeaderDashed isVisible={hasBeenVisible} />
                          )}
                          {drawing === 'bracket' && (
                            <HeaderBracket isVisible={hasBeenVisible} />
                          )}
                          {drawing === 'bentwave' && (
                            <HeaderBentwave isVisible={hasBeenVisible} />
                          )}
                          {drawing === 'clouds' && (
                            <HeaderClouds isVisible={hasBeenVisible} />
                          )}
                          {!image && (
                            <DrawOverlay color={`bg-brand-${pageColor}`} />
                          )}
                        </div>
                      </div>
                    )}
                  </IOPageheader>
                )}
              </span>
              {description && (
                <div className="mt-8 xs:mt-16 text-lg xs:text-xl sm:text-2xl font-light font-micro leading-normal tracking-tight">
                  {documentToReactComponents(description.json)}
                </div>
              )}
              {pageLinks && pageLinks.length > 0 && (
                <ul className="mt-8">
                  {pageLinks.map(item => (
                    <li className="my-6" key={item.data.target.sys.id}>
                      <StyledScrollLink
                        to={slugify(item.data.target.fields.text['en-US'], {
                          lower: true,
                        })}
                        offset={-100}
                        smooth={true}
                      >
                        {item.data.target.fields.text['en-US']}
                      </StyledScrollLink>
                    </li>
                  ))}
                </ul>
              )}

              {cta && (
                <div className="mt-10 md:mt-16 flex">
                  <Button {...cta} />
                </div>
              )}
            </div>
          </div>
          {image && (
            <Image
              className="w-full sm:w-1/3 xxl:w-1/2 h-screen sm:h-auto border-l-2 header-img-reset border-white border-solid"
              fluid={image.fluid}
              imgStyle={{
                objectPosition: `${cropCoordinatesX ||
                  50}% ${cropCoordinatesY || 50}%`,
              }}
            />
          )}
        </Split>
      </div>
      {linksSection && (
        <div className="h-full block">
          <Split>
            {linksSection.map(
              ({ id, text, icon, backgroundImage, overlayColor, link }) =>
                urlParse(link).isInternal ? (
                  <ImageLinkWrapper to={urlParse(link).url} key={id}>
                    <ImageLink>
                      <StyledImg
                        fluid={backgroundImage.fluid}
                        style={{ position: 'absolute' }}
                      />
                      <Overlay
                        color={`bg-brand-${slugify(overlayColor, {
                          lower: true,
                        })}`}
                      />
                      <TextContainer>
                        {icon && <Icon fluid={icon.fluid} />}
                        <Text>{text}</Text>
                      </TextContainer>
                    </ImageLink>
                  </ImageLinkWrapper>
                ) : (
                  <ImageAWrapper
                    href={urlParse(link).url}
                    target="_blank"
                    key={id}
                  >
                    <ImageLink>
                      <StyledImg
                        fluid={backgroundImage.fluid}
                        style={{ position: 'absolute' }}
                      />
                      <Overlay
                        color={`bg-brand-${slugify(overlayColor, {
                          lower: true,
                        })}`}
                      />
                      <TextContainer>
                        {icon && <Icon fluid={icon.fluid} />}
                        <Text>{text}</Text>
                      </TextContainer>
                    </ImageLink>
                  </ImageAWrapper>
                )
            )}
          </Split>
        </div>
      )}
    </Container>
  );
};

PageHeader.propTypes = {
  pageColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  description: PropTypes.object,
  cropCoordinatesX: PropTypes.number,
  cropCoordinatesY: PropTypes.number,
  linksSection: PropTypes.array,
  pageLinks: PropTypes.array,
  size: PropTypes.string,
  align: PropTypes.string,
  cta: PropTypes.object,
  drawing: PropTypes.string,
};

export default PageHeader;
