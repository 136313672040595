import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { filter } from 'lodash';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import Layout from 'components/layout';
import PageHeader from 'components/page-header';
import PageContent from 'components/page-content';
import IOMenu from 'containers/io-menu';

const PageTemplate = ({ data }) => {
  const {
    pageColor,
    slug,
    seoDescription,
    seoImageOptional,
    header,
    content,
  } = data.contentfulPage;
  const pageTitleFull =
    header && header.title
      ? `${header.title} | The Neighborhood Design Center`
      : 'The Neighborhood Design Center';
  const fullDescription =
    (seoDescription && seoDescription.seoDescription) ||
    (header &&
      header.description &&
      documentToPlainTextString(header.description.json).substring(0, 160)) ||
    (content &&
      content.json &&
      documentToPlainTextString(content.json).substring(0, 160)) ||
    null;
  const imageUrl =
    (seoImageOptional && `https:${seoImageOptional.fixed.src}`) ||
    (header && header.image && `https:${header.image.fixed.src}`) ||
    null;

  return (
    <Layout menuColor={header.image ? 'white' : 'black'}>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>

      <div className={`bg-white ${slug}`}>
        <IOMenu withImage={header.image ? true : false}>
          <PageHeader
            align={!header.image && 'left'}
            size={!header.image && 'large'}
            pageColor={slugify(pageColor, { lower: true })}
            pageLinks={filter(content.json.content, item => {
              return (
                item.data.target &&
                item.data.target.sys.contentType &&
                item.data.target.sys.contentType.sys.id ===
                  'blockScrollAnchor' &&
                item.data.target.fields.type['en-US'] === 'Scroll Anchor'
              );
            })}
            {...header}
          />
        </IOMenu>
        <PageContent
          content={content}
          pageColor={slugify(pageColor, { lower: true })}
        />
      </div>
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageTemplate;

export const query = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      seoDescription {
        seoDescription
      }
      seoImageOptional {
        fixed(width: 1024, height: 512) {
          src
        }
      }
      pageColor
      header {
        title
        drawing
        cta {
          text
          link
          caption
          opensNewTab
        }
        image {
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 1024, height: 512) {
            src
          }
        }
        cropCoordinatesX
        cropCoordinatesY
        description {
          description
          json
        }
        linksSection {
          id
          text
          link
          overlayColor
          icon {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          backgroundImage {
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          opensNewTab
        }
      }
      content {
        id
        json
      }
    }
  }
`;
