import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import Sticky from 'react-sticky-el';
import slugify from 'slugify';
import { SizeMe } from 'react-sizeme';

import Layout from 'components/layout';
import HomepageDrawInWrapper from 'containers/homepage-draw-in-wrapper';
import HomepageSlideWrapper from 'containers/homepage-slide-wrapper';
import Button from 'components/blocks/button';
import IndexSectionInfo from 'components/index-section-info';
import IndexLinksSection from 'components/index-links-section';
import urlParse from 'helpers/urlParse';

import Logo from 'images/ndc-logo.svg';

const calcStyle = ({ width, height }) => {
  let style;
  if (width >= 1000) {
    // 1/2 width
    height / width < 3 / 2
      ? (style = {
          backgroundSize: '50vw auto',
          backgroundPosition: `50vw -${(width * 1.5 - height) / 2}px`,
        })
      : (style = {
          backgroundSize: 'auto 100vh',
          backgroundPosition: `calc(50vw - ${(height / 1.5 - width) / 2}px) 0`,
        });
  } else {
    // 1/3 width
    height / width < 3 / 2
      ? (style = {
          backgroundSize: '33.33vw auto',
          backgroundPosition: `66.66vw -${(width * 1.5 - height) / 2}px`,
        })
      : (style = {
          backgroundSize: 'auto 100vh',
          backgroundPosition: `calc(66.66vw - ${(height / 1.5 - width) /
            2}px) 0`,
        });
  }
  return style;
};

const Index = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    window && window.scrollTo(0, 1);
    setLoaded(true);
  }, []);
  return (
    <Layout>
      <div className="overflow-x-hidden logo-boundary">
        <Sticky
          topOffset={-1}
          bottomOffset={2000}
          boundaryElement=".slide-boundary"
          hideOnBoundaryHit={false}
          className="disable-transform relative"
          stickyStyle={{
            zIndex: data.contentfulHomepage.animatedSlides.length + 1,
            display: 'block',
          }}
          style={{
            zIndex: data.contentfulHomepage.animatedSlides.length + 1,
            height: '0vh',
            position: 'fixed',
            display: 'none',
          }}
        >
          <Logo className="relative w-3/4 xs:w-1/2 lg:w-1/3 h-24 xs:h-1/5vh left-8 xxs:left-10 sm:left-16 md:left-32 top-24 tall:top-20vh xs:top-20vh md:top-25vh" />
        </Sticky>
        <div className="relative w-screen">
          {data.contentfulHomepage.animatedSlides.map(
            ({ id, image, backgroundColor, text, cta }, i) => (
              <React.Fragment key={id}>
                <HomepageSlideWrapper key={id} order={i}>
                  <div
                    className={`lg:h-screen bg-white ${
                      i > 0 ? 'lg:mb-0' : 'mb-0'
                    }`}
                  >
                    <div
                      className={`bg-brand-${slugify(backgroundColor, {
                        lower: true,
                      })} fixed lg:relative flex h-screen w-full`}
                    >
                      <div className="w-full lg:w-2/3 xxl:w-1/2 p-8 xxs:p-10 sm:p-16 md:p-32">
                        {loaded && <HomepageDrawInWrapper active={i} />}
                        <div className="lg:w-1/2 xxl:w-1/3 absolute mr-8 xxs:mr-10 sm:mr-16 md:mr-32 lg:mr-10 top-48 tall:top-33vh xs:top-40vh md:top-45vh">
                          {text && (
                            <p className="text-2xl tall:text-3xl xs:text-3xl lg:text-4xl xl:text-4p5xl font-light leading-tight my-6 sm:my-10">
                              {text.text}
                            </p>
                          )}
                          {cta && (
                            <Button
                              {...cta}
                              isInternal={urlParse(cta.link).isInternal}
                              link={urlParse(cta.link).url}
                              align="text-left"
                            />
                          )}
                        </div>
                      </div>

                      <div className="relative w-1/3 xxl:w-1/2 hidden lg:block">
                        <SizeMe monitorHeight>
                          {({ size }) => (
                            <BackgroundImage
                              Tag="section"
                              className="homepage-background-images border-solid border-l-2 border-white"
                              style={calcStyle(size)}
                              fluid={image.fluid}
                            />
                          )}
                        </SizeMe>
                      </div>
                    </div>
                  </div>
                </HomepageSlideWrapper>
                {i > 0 && <div className="hidden lg:block lg:h-1/2vh" />}
                {/* mobile */}
                <div className="lg:hidden h-screen" />
                <div
                  className={`lg:hidden relative h-screen ${i > 0 &&
                    'mt-screen'} ${i <
                    data.contentfulHomepage.animatedSlides.length - 1 &&
                    '-mb-screen'} w-screen`}
                >
                  <Img
                    className="h-screen w-screen"
                    style={{ zIndex: i + 2 }}
                    fluid={image.fluid}
                  />
                </div>
              </React.Fragment>
            )
          )}
        </div>
      </div>
      <div
        className="bg-white relative"
        style={{
          minHeight: '150vh',
          zIndex: data.contentfulHomepage.animatedSlides.length + 1,
        }}
      >
        <div className="py-32 overflow-x-hidden bottom-boundary">
          <IndexSectionInfo
            title={data.contentfulHomepage.journalSectionTitle}
            description={
              data.contentfulHomepage.journalSectionDescription
                .journalSectionDescription
            }
            color="blue"
            type="journal"
            all="/journal"
            items={data.allContentfulJournalPost.edges}
          />
          <IndexSectionInfo
            title={data.contentfulHomepage.volunteerSectionTitle}
            color="orange"
            type="volunteer"
            all="/volunteer#opportunities"
            //items={data.contentfulHomepage.volunteerLinks}
            items={data.allContentfulVolunteerOpportunity.edges}
          />
          <IndexSectionInfo
            title={data.contentfulHomepage.caseStudySectionTitle}
            color="pink"
            type="case-study"
            all="/our-work"
            items={data.contentfulHomepage.caseStudyLinks}
          />
        </div>
        {loaded && (
          <IndexLinksSection
            text={data.contentfulHomepage.linksSection}
            links={data.contentfulHomepage.linksSectionLinks}
          />
        )}
      </div>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    contentfulHomepage {
      id
      animatedSlides {
        id
        image {
          id
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        cropCoordinatesX
        cropCoordinatesY
        backgroundColor
        text {
          id
          text
        }
        cta {
          id
          text
          link
          opensNewTab
        }
      }
      journalSectionTitle
      journalSectionDescription {
        journalSectionDescription
      }
      journalSectionAmount
      volunteerSectionTitle
      volunteerLinks {
        id
        title
        location
      }
      caseStudySectionTitle
      caseStudyLinks {
        id
        title
        slug
        projectIcon {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        thumbnailImage {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headerImage {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      linksSection {
        id
        json
      }
      linksSectionLinks {
        id
        text
        link
        opensNewTab
        backgroundImage {
          id
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }

    allContentfulVolunteerOpportunity(limit: 2) {
      edges {
        node {
          id
          title
          location
          description {
            id
            json
          }
        }
      }
    }


    allContentfulJournalPost(
      filter: { published: { eq: true }, date: { ne: null } }
      sort: { fields: [date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          slug
          type
          date
          thumbnailImage {
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
