import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import JournalList from 'components/journal-list';

const JournalListContainer = ({ items }) => (
  <Consumer>
    {({ filterJournal, searchJournal }) => (
      <JournalList
        items={items}
        filterJournal={filterJournal}
        searchJournal={searchJournal}
      />
    )}
  </Consumer>
);

JournalListContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

export default JournalListContainer;
