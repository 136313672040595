import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderBracket1 from 'images/header/header-bracket1.png';
import patternHeaderBracket2 from 'images/header/header-bracket2.png';
import patternHeaderBracket3 from 'images/header/header-bracket3.png';
import patternHeaderBracket4 from 'images/header/header-bracket4.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderDashed = ({ style, isVisible }) => (
  <>
    <svg
      className="header-bracket sm:hidden"
      viewBox="0 0 470 141.52"
      style={style}
    >
      <defs>
        <pattern
          id="m-headerbracket-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 82 255"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBracket1}
            width="82"
            height="255"
          />
        </pattern>
        <pattern
          id="m-headerbracket-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 92 260"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBracket2}
            width="92"
            height="260"
          />
        </pattern>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headerbracket-1)"
          strokeWidth="16px"
          transform="scale(1.16) translate(-9 -10)"
          d="M52.41,129l-37,2-5-119,36-2"
        />
        <Path
          fill="none"
          stroke="url(#m-headerbracket-2)"
          strokeWidth="14px"
          transform="scale(1.16) translate(-9 -10)"
          d="M457.41,11h34l7,119-31,1"
        />
      </G>
    </svg>
    <svg
      className="header-bracket hidden sm:block"
      viewBox="0 0 990 141.52"
      style={style}
    >
      <defs>
        <pattern
          id="headerbracket-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 82 255"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBracket1}
            width="82"
            height="255"
          />
        </pattern>
        <pattern
          id="headerbracket-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 92 260"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBracket2}
            width="92"
            height="260"
          />
        </pattern>
        <pattern
          id="headerbracket-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 576 37"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBracket3}
            width="576"
            height="37"
          />
        </pattern>
        <pattern
          id="headerbracket-4"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 80 53"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderBracket4}
            width="80"
            height="53"
          />
        </pattern>
        <clipPath id="headerbracket-clip-4">
          <path
            transform="scale(1.12) translate(-92 -10)"
            d="M844.41,83l43,18-42,11-2-30"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headerbracket-1)"
          strokeWidth="16px"
          transform="scale(1.16) translate(-9 -10)"
          d="M52.41,129l-37,2-5-119,36-2"
        />
        <Path
          fill="none"
          stroke="url(#headerbracket-2)"
          strokeWidth="14px"
          transform="scale(1.16) translate(-9 -10)"
          d="M457.41,11h34l7,119-31,1"
        />
        <Path
          fill="none"
          stroke="url(#headerbracket-3)"
          strokeWidth="20px"
          transform="scale(1.16) translate(-30 -20)"
          d="M558.41,80c99.83,3.84,195.16,10,285,19"
        />
        <Path
          fill="none"
          stroke="url(#headerbracket-4)"
          strokeWidth="20px"
          clipPath="url(#headerbracket-clip-4)"
          transform="scale(1.05) translate(54 -16)"
          d="M844.41,83l43,18-42,11-2-30"
        />
      </G>
    </svg>
  </>
);

HeaderDashed.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderDashed;
