import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  StyledImage,
  Margin,
  Caption,
  CaptionMargin,
} from './image.css';

const getWidth = width => {
  switch (width) {
    case 'Text Width':
      return 'max-w-4xl';
    case 'Extra Wide':
      return 'max-w-6xl';
    case 'Full Bleed':
      return 'max-h-screen max-w-screen';
    default:
      return 'max-w-4xl';
  }
};

const getMargin = width => {
  switch (width) {
    case 'Text Width':
      return 'mx-4 xs:mx-10 sm:mx-20';
    case 'Extra Wide':
      return 'xs:mx-4 sm:mx-10';
    case 'Full Bleed':
      return '';
    default:
      return 'mx-4 xs:mx-10 sm:mx-20';
  }
};

const Image = ({ image, caption, blockWidth, next, prev }) => (
  <Margin margin={getMargin(blockWidth)}>
    <Container
      width={getWidth(blockWidth)}
      margin={`${next ? 'mb-10 sm:mb-20' : ''} ${prev ? 'mt-10 sm:mt-20' : ''}`}
    >
      {image && image.fluid && <StyledImage fluid={image.fluid} />}
      {caption && (
        <CaptionMargin
          margin={blockWidth === 'Full Bleed' && 'mx-4 xs:mx-10 sm:mx-20'}
        >
          <Caption>{caption}</Caption>
        </CaptionMargin>
      )}
    </Container>
  </Margin>
);

Image.propTypes = {
  image: PropTypes.object.isRequired,
  blockWidth: PropTypes.string,
  caption: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
};

export default Image;
