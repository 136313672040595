import nanostyled from 'nanostyled';

export const Container = nanostyled('div', {
  base: 'max-w-4xl w-full',
  margin: 'mx-auto',
});

export const Center = nanostyled('div', {
  base: 'mx-4 xs:mx-10 sm:mx-20',
});
