import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { reverse, sortBy } from 'lodash';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';
import JournalFilter from 'containers/journal-filter';
import JournalList from 'containers/journal-list';
import JournalLogo from 'components/journal-logo';

const Journal = ({ data }) => {
  const { allContentfulJournalPost, contentfulJournal } = data;
  const { seoDescription, seoImage, description, title } = contentfulJournal;

  const pageTitleFull = title
    ? `${title} | The Neighborhood Design Center`
    : 'The Neighborhood Design Center';
  const fullDescription =
    (seoDescription && seoDescription.seoDescription) ||
    (description && description) ||
    null;
  const imageUrl = (seoImage && `https:${seoImage.fixed.src}`) || null;

  const allJournalPosts = reverse(
    sortBy(allContentfulJournalPost.edges, [i => new Date(i.node.date)])
  );

  return (
    <Layout>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>
      <div style={{ background: 'white' }}>
        <JournalLogo
          description={contentfulJournal.description.childMarkdownRemark.html}
        />
        <JournalFilter items={allContentfulJournalPost.edges} />
        <JournalList items={allJournalPosts} />
      </div>
    </Layout>
  );
};

Journal.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Journal;

export const query = graphql`
  query JournalQuery {
    contentfulJournal {
      id
      title
      seoDescription {
        seoDescription
      }
      seoImage {
        fixed(width: 1024, height: 512) {
          src
          srcWebp
        }
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulJournalPost(
      filter: { published: { eq: true }, date: { ne: null } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          type
          date
          thumbnailImage {
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
