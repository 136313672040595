import nanostyled from 'nanostyled';

export const Background = nanostyled('section', {
  base: 'w-full mt-20 sm:mt-32 -mb-36 pt-2 pb-16 sm:py-16 md:py-32',
  bg: '',
});

export const Container = nanostyled('div', {
  base: 'w-full max-w-4xl',
  margin: 'mx-auto',
});

export const Type = nanostyled('div', {
  base: 'typography white',
});

export const Flex = nanostyled('div', {
  base: 'flex',
  justify: '',
});

export const Margin = nanostyled('div', {
  base: '',
  margin: 'mx-4 xs:mx-10 sm:mx-20',
});
