import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FooterCity from 'components/draw-in-svg/footer-city.js';
import FooterStreets from 'components/draw-in-svg/footer-streets.js';
import FooterParks from 'components/draw-in-svg/footer-parks.js';
import FooterSchools from 'components/draw-in-svg/footer-schools.js';
import FooterPower from 'components/draw-in-svg/footer-power.js';
import FooterPlayground from 'components/draw-in-svg/footer-playground.js';
import FooterNeighborhood from 'components/draw-in-svg/footer-neighborhood.js';
import FooterLights from 'components/draw-in-svg/footer-lights.js';
import FooterCorner from 'components/draw-in-svg/footer-corner.js';
import FooterCommunity from 'components/draw-in-svg/footer-community.js';
import FooterBlock from 'components/draw-in-svg/footer-block.js';
import IOFooter from 'components/io-footer';
import SearchComponent from 'components/search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import {
  Container,
  Wrapper,
  Slogan,
  Links,
  StyledLink,
  StyledA,
  StyledIconLink,
  IconWrapper,
  LinksWrapper,
  TextWrapper,
  // Credit,
} from './footer.css';

const searchIndices = [
  { name: 'CaseStudies', title: 'Case Studies', hitComp: 'CaseStudyHit' },
  { name: 'JournalPosts', title: 'Journal Posts', hitComp: 'JournalPostHit' },
  { name: 'Pages', title: 'Pages', hitComp: 'PageHit' },
];

const Footer = ({ footerOpen, showFooter, footerMessage }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    window && window.scrollTo(0, 1);
    setLoaded(true);
  }, []);
  return (
    <>
      {loaded && <IOFooter showFooter={showFooter} />}
      <Container>
        <Wrapper>
          <Slogan to="/">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              {footerMessage == 'city' && (
                <FooterCity
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '38.625rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'streets' && (
                <FooterStreets
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '46.875rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'parks' && (
                <FooterParks
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '46.875rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'schools' && (
                <FooterSchools
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '43.75rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'power' && (
                <FooterPower
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '38.75rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'playground' && (
                <FooterPlayground
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '57rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'neighborhood' && (
                <FooterNeighborhood
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '67.75rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'lights' && (
                <FooterLights
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '44rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'corner' && (
                <FooterCorner
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '43.5rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'community' && (
                <FooterCommunity
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '57.25rem',
                    maxHeight: '100%',
                  }}
                />
              )}
              {footerMessage == 'block' && (
                <FooterBlock
                  footerOpen={footerOpen}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '44.75rem',
                    maxHeight: '100%',
                  }}
                />
              )}
            </div>
          </Slogan>
          <Links>
            <IconWrapper>
              <StyledIconLink
                href="https://www.facebook.com/NeighborhoodDesignCenter/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </StyledIconLink>
              <StyledIconLink
                href="https://www.instagram.com/neighborhooddesigncenter/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </StyledIconLink>
              <StyledIconLink
                href="https://twitter.com/ndcmaryland/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </StyledIconLink>
              <StyledIconLink
                href="https://www.linkedin.com/company/the-neighborhood-design-center/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </StyledIconLink>
            </IconWrapper>
            <TextWrapper>
              <LinksWrapper>
                <StyledLink to="/work-with-us#contact-ndc">
                  contact us
                </StyledLink>
                <StyledA href="https://www.volgistics.com/ex2/vicnet.dll?FROM=96083">
                  volunteer login
                </StyledA>
                <StyledLink to="/work-with-us#subscribe-ndc">
                  newsletter
                </StyledLink>
              </LinksWrapper>
              <div className="relative">
                {searchIndices && (
                  <SearchComponent collapse indices={searchIndices} />
                )}
              </div>
            </TextWrapper>
          </Links>
          {/* <Credit to="/">Site Credits</Credit> */}
        </Wrapper>
      </Container>
    </>
  );
};

Footer.propTypes = {
  footerOpen: PropTypes.bool.isRequired,
  showFooter: PropTypes.func.isRequired,
  footerMessage: PropTypes.string.isRequired,
};

export default Footer;
