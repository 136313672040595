import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import Sticky from 'react-sticky-el';
import { Portal } from 'react-portal';
import { truncate } from 'lodash';

import IOMenu from 'containers/io-menu';

import {
  Container,
  StyledImg,
  InfoBox,
  InfoList,
  Background,
  ListItem,
  PrevLink,
  PrevTitle,
  NextLink,
  NextTitle,
} from './case-study-header.css';

const CaseStudyHeader = ({
  pageColor,
  projectNumber,
  image,
  title,
  description,
  projectIcon,
  whatWeDid,
  partners,
  supporters,
  previous,
  next,
}) => (
  <Container className="header-boundary relative">
    <IOMenu withImage="true">
      <div
        className={`relative z-1 text-white bg-brand-${pageColor} flex justify-center items-center min-h-screen`}
      >
        <div className="max-w-4xl w-full my-32 mx-4 xs:mx-10 sm:mx-20">
          <div className="flex flex-col xs:flex-row xs:items-center">
            {projectIcon && (
              <Image
                loading="eager"
                className="w-32 xs:w-48"
                fluid={projectIcon.fluid}
              />
            )}
            {projectNumber !== 'undefined' && (
              <div className="xs:ml-5 mt-2 mb-10 xs:mb-0 xs:mt-10 uppercase text-black font-micro font-extrabold">
                Project No. {projectNumber}
              </div>
            )}
          </div>
          <h1 className="text-4xl xs:text-5xl md:text-6xl font-extralight">
            {title}
          </h1>
          {description && (
            <div className="mt-10 text-xl sm:text-2xl font-light font-micro leading-normal tracking-tight">
              {description}
            </div>
          )}
        </div>
      </div>
      {image && (
        <>
          <Sticky
            topOffset={-10000}
            //bottomOffset={1000}
            boundaryElement=".boundary"
            hideOnBoundaryHit={true}
            className="disable-transform relative h-screen pointer-events-none"
          >
            <div className="absolute top-0 bottom-0 left-0 right-0 flex">
              <StyledImg fluid={image.fluid} />
            </div>
          </Sticky>
          {/* <div style={{ marginBottom: '100vh' }} /> */}
        </>
      )}
    </IOMenu>
    {(whatWeDid || partners || supporters) && (
      <Background>
        <InfoBox>
          {whatWeDid && (
            <InfoList color={`border-brand-${pageColor}`}>
              <h2>What We Did</h2>
              <ul>
                {whatWeDid.map(item => (
                  <ListItem color={`text-brand-${pageColor}`} key={item}>
                    {item}
                  </ListItem>
                ))}
              </ul>
            </InfoList>
          )}
          {partners && (
            <InfoList color={`border-brand-${pageColor}`}>
              <h2>Partners</h2>
              <ul>
                {partners.map(item => (
                  <ListItem color={`text-brand-${pageColor}`} key={item}>
                    {item}
                  </ListItem>
                ))}
              </ul>
            </InfoList>
          )}
          {supporters && (
            <InfoList color={`border-brand-${pageColor}`}>
              <h2>Supporters</h2>
              <ul>
                {supporters.map(item => (
                  <ListItem color={`text-brand-${pageColor}`} key={item}>
                    {item}
                  </ListItem>
                ))}
              </ul>
            </InfoList>
          )}
        </InfoBox>
      </Background>
    )}
    <Portal>
      {previous && (
        <PrevLink to={`case-study/${previous.slug}`}>
          <span className="border-solid border-black border-b-2 pb-1">
            Previous Case Study
          </span>
          <PrevTitle>{truncate(previous.title)}</PrevTitle>
        </PrevLink>
      )}
      {next && (
        <NextLink to={`case-study/${next.slug}`}>
          <NextTitle>{truncate(next.title)}</NextTitle>
          <span className="border-solid border-black border-b-2 pb-1">
            Next Case Study
          </span>
        </NextLink>
      )}
    </Portal>
  </Container>
);

CaseStudyHeader.propTypes = {
  pageColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  description: PropTypes.object,
  projectNumber: PropTypes.number,
  projectIcon: PropTypes.object,
  whatWeDid: PropTypes.array,
  partners: PropTypes.array,
  supporters: PropTypes.array,
  next: PropTypes.string,
  previous: PropTypes.string,
};

export default CaseStudyHeader;
