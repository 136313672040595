import React from 'react';
import PropTypes from 'prop-types';

import IO from './io';
import { Container } from './io-pageheader.css';

class IOPageheader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, className } = this.props;

    return (
      <IO id="io-pageheader" key="io-pageheader" rootMargin="0px">
        {({ hasBeenVisible }) => (
          <Container className={className}>
            {children({ hasBeenVisible })}
          </Container>
        )}
      </IO>
    );
  }
}

IOPageheader.propTypes = {
  showFooter: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default IOPageheader;
