/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */

import React from 'react';
// import PropTypes from 'prop-types';
import { Highlight, Snippet } from 'react-instantsearch-dom';
import { Link } from 'gatsby';

// const truncateWholeWords = (text, maxLength) => {
//   return (
//     text
//       .split(' ')
//       .filter(function(word) {
//         length += word.length + 1;
//         return length <= maxLength;
//       })
//       .join(' ')
//       .replace(/([.,\/#!$%\^&\*;:{}=\-_`~()\]\[])+$/g, '') + '...'
//   );
// };

export const PageHit = clickHandler => ({ hit }) => (
  <div className="flex flex-col my-8">
    <h4 className="mt-4 -mb-2">
      <Link className="border-black" to={`/${hit.slug}`} onClick={clickHandler}>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Link>
    </h4>

    <div className="">
      <p className="!mb-2">
        {hit.description ? (
          <Snippet attribute="description" hit={hit} tagName="mark" />
        ) : (
          <Snippet attribute="content" hit={hit} tagName="mark" />
        )}
      </p>
      <Link className="border-black" to={`/${hit.slug}`} onClick={clickHandler}>
        Read More
      </Link>
    </div>
  </div>
);

export const CaseStudyHit = clickHandler => ({ hit }) => (
  <div className="flex flex-col my-8">
    <h4 className="mt-4 -mb-2">
      <Link
        className="border-black"
        to={`/case-study/${hit.slug}`}
        onClick={clickHandler}
      >
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Link>
    </h4>

    <div className="">
      <p className="!mb-2">
        {hit.description ? (
          <Snippet attribute="description" hit={hit} tagName="mark" />
        ) : (
          <Snippet attribute="content" hit={hit} tagName="mark" />
        )}
      </p>
      <Link
        className="border-black"
        to={`/case-study/${hit.slug}`}
        onClick={clickHandler}
      >
        Read More
      </Link>
    </div>
  </div>
);

export const JournalPostHit = clickHandler => ({ hit }) => (
  <div className="flex flex-col my-8">
    <h4 className=" mt-4 -mb-2">
      <Link
        className="border-black"
        to={`/journal/${hit.slug}`}
        onClick={clickHandler}
      >
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Link>
    </h4>

    <div className="">
      <p className="!mb-2">
        {hit.excerpt ? (
          <Snippet attribute="excerpt" hit={hit} tagName="mark" />
        ) : (
          <Snippet attribute="content" hit={hit} tagName="mark" />
        )}
      </p>
      <Link
        className="border-black"
        to={`/journal/${hit.slug}`}
        onClick={clickHandler}
      >
        Read More
      </Link>
    </div>
  </div>
);
