import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderWave1 from 'images/header/header-wave1.png';
import patternHeaderWave2 from 'images/header/header-wave2.png';
import patternHeaderWave3 from 'images/header/header-wave3.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderWave = ({ style, isVisible }) => (
  <>
    <svg
      className="header-wave sm:hidden"
      viewBox="0 0 378.33 40.84"
      style={style}
    >
      <defs>
        <pattern
          id="m-headerwave-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 742 47"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderWave1}
            width="742"
            height="47"
          />
        </pattern>
        {/* <clipPath id="headerarrows-clip-3">
          <path
            transform="scale(1.03) translate(-22 -0)"
            d="M775.63,11c9.29.79,14.59,7,15.71,16.65-1.5,8.24-6.27,13-16.28,14.93-8.41-1.86-15.49-5.69-16.71-13.67C759.92,19.8,765.55,14.41,775.63,11Z"
          />
        </clipPath> */}
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headerwave-1)"
          strokeWidth="14px"
          d="M3.91,15.6,30.28,30.39l33-19,30,20,40-22,33,22,37-22,35,21,37-21,33,22,38-21,27,22"
        />
      </G>
    </svg>
    <svg
      className="header-wave hidden sm:block"
      viewBox="0 0 947.87 225.67"
      style={style}
    >
      <defs>
        <pattern
          id="headerwave-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 742 47"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderWave1}
            width="742"
            height="47"
          />
        </pattern>
        <pattern
          id="headerwave-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 553 330"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderWave2}
            width="553"
            height="330"
          />
        </pattern>
        <pattern
          id="headerwave-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 175 159"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderWave3}
            width="175"
            height="159"
          />
        </pattern>
        {/* <clipPath id="headerarrows-clip-3">
          <path
            transform="scale(1.03) translate(-22 -0)"
            d="M775.63,11c9.29.79,14.59,7,15.71,16.65-1.5,8.24-6.27,13-16.28,14.93-8.41-1.86-15.49-5.69-16.71-13.67C759.92,19.8,765.55,14.41,775.63,11Z"
          />
        </clipPath> */}
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headerwave-1)"
          strokeWidth="14px"
          d="M244.65,112.89,271,127.68l33-19,30,20,40-22,33,22,37-22,35,21,37-21,33,22,38-21,27,22"
        />
        <Path
          fill="none"
          stroke="url(#headerwave-2)"
          strokeWidth="14px"
          d="M656.51,55.83C787.45,28.26,868,95.16,923,209.68"
        />
        <Path
          fill="none"
          stroke="url(#headerwave-3)"
          strokeWidth="14px"
          d="M940,136.68l-15,77-70-36"
        />
      </G>
    </svg>
  </>
);

HeaderWave.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderWave;
