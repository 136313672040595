/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS, INLINES } from '@contentful/rich-text-types';

import { Container, Margin } from './list.css';

const opt = {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderMark: {
    [MARKS.BOLD]: text => {
      return (
        <b className="font-bold" key={`${text}-key`}>
          {text}
        </b>
      );
    },
    [MARKS.ITALIC]: text => {
      return (
        <i className="italic" key={`${text}-key`}>
          {text}
        </i>
      );
    },
  },
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { description, file } = node.data.target.fields;
      return (
        <a
          alt={description ? description['en-US'] : null}
          href={file['en-US'].url}
        >
          {children}
        </a>
      );
    },
  },
};

const List = ({
  columnCount,
  list,
  includeIcon,
  textColor,
  pageColor,
  isInline,
  //options,
  addUl,
  prev,
  next,
}) => (
  <Margin margin={isInline && ''}>
    <Container
      margin={
        !isInline &&
        `${next ? 'mb-10 sm:mb-20' : ''} ${prev ? 'mt-10 sm:mt-20' : ''}`
      }
    >
      <div
        className={`typography ${pageColor} ${
          columnCount ? 'sm:col-count-2 col-gap-lg' : ''
        }`}
      >
        <div
          className={`list ${includeIcon ? 'with-icon' : ''} ${
            textColor ? 'with-color' : ''
          }`}
        >
          {addUl ? (
            <ul>{list && documentToReactComponents(list, opt)}</ul>
          ) : (
            list && documentToReactComponents(list, opt)
          )}
        </div>
      </div>
    </Container>
  </Margin>
);

List.propTypes = {
  link: PropTypes.string.isRequired,
  columnCount: PropTypes.bool.isRequired,
  includeIcon: PropTypes.bool.isRequired,
  textColor: PropTypes.bool.isRequired,
  pageColor: PropTypes.string,
  list: PropTypes.object.isRequired,
  isInline: PropTypes.bool,
  addUl: PropTypes.bool,
  next: PropTypes.string,
  prev: PropTypes.string,
};

export default List;
