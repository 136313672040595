import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledLink,
  StyledA,
  StyledButton,
  Container,
  Caption,
} from './button.css';

const Button = ({
  text,
  align,
  margin,
  space,
  link,
  isInternal,
  isInline,
  onClick,
  caption,
  opensNewTab,
}) => {
  if (link) {
    return (
      <Container
        space={isInline ? 'my-2' : space}
        inline={isInline && 'inline-block mx-4'}
        align={(align == 'center' || isInline) && 'text-center'}
      >
        {isInternal ? (
          <StyledLink
            to={link}
            margin={margin}
            style={{borderStyle: 'solid'}}
            align={align && (align == 'center' ? 'mx-auto' : align)}
          >
            {text}
          </StyledLink>
        ) : (
          <StyledA
            href={link}
            margin={margin}
            target={opensNewTab ? '_blank' : ''}
            style={{borderStyle: 'solid'}}
            align={align && (align == 'center' ? 'mx-auto' : align)}
          >
            {text}
         </StyledA>
        )}
        <div className="max-w-xs mx-auto flex justify-center text-center">
          {caption && <Caption align="text-center">{caption}</Caption>}
        </div>
      </Container>
    );
  }
  return (
    <Container
      space={isInline ? 'my-2' : space}
      align={align == 'center' && 'text-center'}
    >
      <div>
        <StyledButton
          onClick={onClick}
          margin={margin}
          align={align && (align == 'center' ? 'mx-auto' : align)}
        >
          {text}
        </StyledButton>
        {caption && <Caption>{caption}</Caption>}
      </div>
    </Container>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  align: PropTypes.string,
  margin: PropTypes.string,
  isInternal: PropTypes.bool.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
  space: PropTypes.string,
  isInline: PropTypes.bool,
  next: PropTypes.string,
  prev: PropTypes.string,
  caption: PropTypes.string,
  opensNewTab: PropTypes.bool,
};

export default Button;
