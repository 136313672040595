import React from 'react';
import PropTypes from 'prop-types';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from 'react-google-maps';
import { GoogleMap, LoadScript, OverlayView } from '@react-google-maps/api';

import mapStyles from './styles.json';
import { Container, Margin } from './map.css';

const getWidth = width => {
  switch (width) {
    case 'Text Width':
      return 'max-w-4xl';
    case 'Extra Wide':
      return 'max-w-6xl';
    case 'Full Bleed':
      return 'max-h-screen max-w-screen';
    default:
      return 'max-w-4xl';
  }
};

const getMargin = width => {
  switch (width) {
    case 'Text Width':
      return 'mx-4 xs:mx-10 sm:mx-20';
    case 'Extra Wide':
      return 'xs:mx-4 sm:mx-10';
    case 'Full Bleed':
      return '';
    default:
      return 'mx-4 xs:mx-10 sm:mx-20';
  }
};

// const getColorHex = pageColor => {
//   switch (pageColor) {
//     case 'blue':
//       return '#00abe6';
//     case 'yellow':
//       return '#f3e404';
//     case 'pink':
//       return '#ee3d96';
//     case 'orange':
//       return '#f99d21';
//     case 'green':
//       return '#70bf41';
//     case 'dark-teal':
//       return '#10565f';
//     case 'dark-purple':
//       return '#573249';
//     default:
//       return '#000000';
//   }
// };

const Map = ({
  blockWidth,
  mapCenter,
  mapMarker,
  pageColor,
  title,
  next,
  prev,
}) => {
  return (
    <Margin margin={getMargin(blockWidth)}>
      <Container
        width={getWidth(blockWidth)}
        margin={`${next ? 'mb-10 sm:mb-20' : ''} ${
          prev ? 'mt-10 sm:mt-20' : ''
        }`}
      >
        <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyCwiR1iwRHZXET4iuE12y3I_3WE_-pKDeU"
        >
          <GoogleMap
            id="example-map"
            mapContainerStyle={{ height: '50vh' }}
            center={{
              lat: mapCenter.lat,
              lng: mapCenter.lon,
            }}
            zoom={13}
            options={{ styles: mapStyles, disableDefaultUI: true }}
          >
            {mapMarker && (
              <OverlayView
                position={{
                  lat: mapMarker.lat,
                  lng: mapMarker.lon,
                }}
                getPixelPositionOffset={(width, height) => ({
                  x: -(width / 2),
                  y: -(height / 2),
                })}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div className="flex flex-col items-center">
                  <div
                    className={`w-8 h-8 rounded-full bg-brand-${pageColor}`}
                  />
                  <span className="font-bold text-base mt-1">{title}</span>
                </div>
              </OverlayView>
            )}
          </GoogleMap>
        </LoadScript>
      </Container>
    </Margin>
  );
};

Map.propTypes = {
  blockWidth: PropTypes.string,
  mapCenter: PropTypes.object,
  mapMarker: PropTypes.object,
  title: PropTypes.string,
  pageColor: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
};

export default Map;
