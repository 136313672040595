import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern from 'images/footer/footer-schools-drawing.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 800 }) => ({
      duration: dur,
    }),
  },
});

const FooterSchools = ({ style, footerOpen }) => (
  <svg viewBox="0 0 699.28 340.13" height="340.13" style={style}>
    <defs>
      <style>
        {
          '.b18b1ba3-a6b4-40ce-b2b0-00ade1469faf{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;}.a0594400-9524-4803-bd6f-817765f35441,.b18b1ba3-a6b4-40ce-b2b0-00ade1469faf{letter-spacing:-0.02em;}.b191cb32-97c6-4fbe-81ef-bff9c70023a8{letter-spacing:-0.01em;}.a0c8d30a-4686-4d76-8a24-08ba73255d2e{letter-spacing:-0.04em;}.b16d6aec-25c8-4ed4-a1fb-b7c2b4f7216d{letter-spacing:-0.04em;}.b8ec948c-1c8b-4769-a80a-b8ae5bcd4050{letter-spacing:-0.03em;}.bba3b82e-a178-4592-a26f-a41e40c1a20b{letter-spacing:-0.02em;}.b1077bfe-2f5d-410f-b6b1-cc8d3c8e3c88{fill:none;stroke:#000;stroke-miterlimit:10;}'
        }
      </style>
      <pattern
        id="footer-schools"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 1118 145"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern}
          width="1118"
          height="145"
        />
      </pattern>
    </defs>
    <g>
      <text
        className="font-display font-extrabold b18b1ba3-a6b4-40ce-b2b0-00ade1469faf"
        transform="translate(0 114.94)"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the schools
        <tspan className="b191cb32-97c6-4fbe-81ef-bff9c70023a8">
          <tspan x="0" y="120.85">
            a
          </tspan>
          <tspan
            className="a0c8d30a-4686-4d76-8a24-08ba73255d2e"
            x="73.04"
            y="120.85"
          >
            r
          </tspan>
          <tspan
            className="a0594400-9524-4803-bd6f-817765f35441"
            x="121.65"
            y="120.85"
          >
            e{' '}
          </tspan>
          <tspan
            className="b16d6aec-25c8-4ed4-a1fb-b7c2b4f7216d"
            x="227.32"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="b8ec948c-1c8b-4769-a80a-b8ae5bcd4050"
            x="296.2"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="371.8" y="120.85">
            u
          </tspan>
          <tspan
            className="bba3b82e-a178-4592-a26f-a41e40c1a20b"
            x="448.06"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="498.55" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>
      <g data-name="outlined-text">
        <path d="M32.76,61V93.32c0,5.1,2.55,6,7.93,6h5.37v15.58a79.87,79.87,0,0,1-9.67.53c-17.32,0-24.57-5.64-24.57-19.87V61H1.48V45.65H11.82V25.11H32.76V45.65h13.3V61Z" />
        <path d="M119.1,69.55v45.39H98.15V73.71C98.15,66.06,94.8,61.9,87,61.9c-6.45,0-13.16,4.16-13.16,14.37v38.67H52.9V19.33H73.85V54.78A25.52,25.52,0,0,1,95.07,44C108.76,44,119.1,52.36,119.1,69.55Z" />
        <path d="M197.79,86.47h-51.3c1.48,9.94,7.65,14.64,15.85,14.64,7,0,11.54-2.82,14-7.79h19.87C192.41,108,179,116.55,162.34,116.55c-21.49,0-36-14.23-36-36.26S140.45,44,162.07,44s35.72,14.9,35.72,38.27ZM146.62,73.18h30.62c-1.48-8.6-7-13.7-15.44-13.7C154.14,59.48,148.24,63.51,146.62,73.18Z" />
        <path d="M230.68,92.92h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.72s-2.55-6.17-11.95-7.65l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21C231.75,52.1,242.63,44,262.9,44c23.37,0,31.56,10.2,32.23,23.77H275.66c-.27-7.52-5.24-9.81-12.76-9.81s-10.61,2.56-10.61,6.58c0,4.17,3.36,5.91,10.08,7L272.71,73c16.38,2.55,24.3,9.13,24.3,21.49C297,108.9,284.79,117,264,117,242.76,117,231.88,110.1,230.68,92.92Z" />
        <path d="M299.83,80.29c0-22,13.83-36.25,35.58-36.25,19.07,0,32,10.61,33.84,27.66H348.43c-1.21-7.39-6.31-10.61-13-10.61C326.55,61.09,321,67,321,80.29s5.64,19.07,14.37,19.07c7,0,12.49-3.49,13.43-11.68h20.81c-1.48,17.86-15,28.87-34.24,28.87C313.79,116.55,299.83,102.32,299.83,80.29Z" />
        <path d="M441.75,69.55v45.39H420.81V73.71c0-7.65-3.36-11.81-11.15-11.81-6.44,0-13.16,4.16-13.16,14.37v38.67H375.55V19.33H396.5V54.78A25.52,25.52,0,0,1,417.72,44C431.41,44,441.75,52.36,441.75,69.55Z" />
        <path d="M449,80.29c0-22,14.23-36.25,36.39-36.25s36.25,14.23,36.25,36.25-14.23,36.26-36.25,36.26S449,102.32,449,80.29Zm51.43,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47S476,99.76,485.39,99.76,500.43,93.45,500.43,80.29Z" />
        <path d="M526.07,80.29c0-22,14.24-36.25,36.39-36.25s36.26,14.23,36.26,36.25-14.24,36.26-36.26,36.26S526.07,102.32,526.07,80.29Zm51.43,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S577.5,93.45,577.5,80.29Z" />
        <path d="M606,19.33h20.95v95.61H606Z" />
        <path d="M633,92.92h20.41c.81,7.52,4.57,10.07,13.29,10.07,8.46,0,11.69-2.55,11.69-6.72s-2.55-6.17-12-7.65l-9-1.48C642.75,84.86,634,78.28,634,65.93,634,52.1,644.9,44,665.18,44c23.36,0,31.55,10.2,32.22,23.77H677.93c-.27-7.52-5.23-9.81-12.75-9.81s-10.61,2.56-10.61,6.58c0,4.17,3.36,5.91,10.07,7L675,73c16.38,2.55,24.3,9.13,24.3,21.49,0,14.37-12.22,22.42-33,22.42C645,117,634.16,110.1,633,92.92Z" />
        <path d="M51.7,235.78A19.72,19.72,0,0,1,50,228c-4.43,5.91-12,9.4-22.16,9.4-14.5,0-25.24-7.38-25.24-21.21,0-12,7.12-19.87,27.13-21.62l10.87-.94c5.91-.67,9-2.42,9-6.85,0-4.16-2.28-7-10.61-7s-11.55,2-12.22,9.13H5.91c1.07-15.31,10.88-24,33.16-24,21.76,0,30.89,8.2,30.89,21.35V223c0,5.1.94,10.07,2.82,12.75Zm-2.15-27.26v-5.1c-1.48,1.61-3.76,2.28-7,2.69l-8.6.94c-7.11.8-10.07,3.49-10.07,8.32s3.63,7.92,10.21,7.92C42.16,223.29,49.55,219.4,49.55,208.52Z" />
        <path d="M123.8,165.69v19.07h-5.37c-11.68,0-18.4,5.77-18.4,18.8v32.22H79.09V166.5H99.5v12.89c3-8.6,9.39-14,19.73-14C120.85,165.42,122.32,165.56,123.8,165.69Z" />
        <path d="M196.31,207.32H145c1.48,9.93,7.66,14.63,15.85,14.63,7,0,11.55-2.82,14-7.79H194.7c-3.76,14.64-17.19,23.23-33.84,23.23-21.49,0-36-14.23-36-36.25s14.1-36.26,35.72-36.26,35.72,14.91,35.72,38.27ZM145.15,194h30.61c-1.47-8.59-7-13.69-15.44-13.69C152.67,180.33,146.76,184.35,145.15,194Z" />
        <path d="M301.17,166.5l-25.11,72c-6,17.45-14,23.77-34.51,23.77-4,0-6-.14-8.19-.27V245.85h6.72c7.78,0,10.47-4.43,10.47-9.66,0-3.9-1.61-8.87-3.22-13.43L227.45,166.5H250l3.09,9.8c4,13.56,8.06,27.79,11.82,43.64,3.76-16,7.65-30.08,11.54-43.51l3.09-9.93Z" />
        <path d="M299.42,201.14c0-22,14.24-36.26,36.39-36.26s36.26,14.24,36.26,36.26-14.24,36.25-36.26,36.25S299.42,223.16,299.42,201.14Zm51.43,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S350.85,214.3,350.85,201.14Z" />
        <path d="M444,166.5v69.28H423.63V226c-4.57,6.45-11.55,11.41-21.75,11.41-13.7,0-24-8.32-24-25.51V166.5h21v41.22c0,7.65,3.35,11.82,11.14,11.82,6.45,0,13.16-4.17,13.16-14.37V166.5Z" />
        <path d="M498.82,165.69v19.07h-5.37c-11.68,0-18.4,5.77-18.4,18.8v32.22H454.11V166.5h20.41v12.89c3-8.6,9.39-14,19.73-14C495.87,165.42,497.34,165.56,498.82,165.69Z" />
        <path d="M500.43,213.76h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-11.95-7.66l-9-1.47c-14.64-2.29-23.37-8.87-23.37-21.22,0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.21,32.23,23.77H545.41c-.27-7.52-5.24-9.8-12.76-9.8s-10.6,2.55-10.6,6.58,3.35,5.91,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.43-33,22.43C512.51,237.8,501.64,231,500.43,213.76Z" />
        <path d="M570.92,212.69h23.63v23.09H570.92Z" />
      </g>
      <Path
        pose={footerOpen ? 'to' : 'from'}
        fill="none"
        // stroke="red"
        stroke="url(#footer-schools)"
        strokeWidth="38px"
        // clipPath="url(#city-cut-off)"
        d="M8.48,331.74l75.25-65.18,58.4,72.81,62.51-64.86,39.25,54.54,45.26-53.32,59.09,54.52,49.42-64.65L441.75,326,482,269.44l47.45,65.18,52.24-59.43"
      />
    </g>
  </svg>
);

FooterSchools.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterSchools;
