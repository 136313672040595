import React from 'react';
import PropTypes from 'prop-types';

import { Consumer } from 'store/createContext';
import Footer from 'components/footer';

const FooterContainer = ({ key }) => (
  <Consumer>
    {({ footerOpen, showFooter, footerMessage }) => (
      <Footer
        key={key}
        footerOpen={footerOpen}
        showFooter={showFooter}
        footerMessage={footerMessage}
      />
    )}
  </Consumer>
);

FooterContainer.propTypes = {
  key: PropTypes.string,
};

export default FooterContainer;
