import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderSquiggle1 from 'images/header/header-squiggle1.png';
import patternHeaderSquiggle2 from 'images/header/header-squiggle2.png';
import patternHeaderSquiggle3 from 'images/header/header-squiggle3.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderSquiggle = ({ style, isVisible }) => (
  <>
    <svg
      className="header-squiggle md:hidden"
      viewBox="0 0 353.5 64.03"
      style={style}
    >
      <defs>
        <pattern
          id="m-headersquiggle-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 623 78"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderSquiggle1}
            width="623"
            height="78"
          />
        </pattern>
        <clipPath id="m-clip-headersquiggle-1">
          <path
            transform="translate(-2, 14)"
            d="M13,11,147,1l74-1,52,7,26,4,47,8-4,9-92,3-69,2-52,5L84,36,34,38,0,37Z"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headersquiggle-1)"
          strokeWidth="30px"
          clipPath="url(#m-clip-headersquiggle-1)"
          d="M353.19,35.74l-44,3,9-9-45,12,24-19-57,16,21-19-69,20,33-24-84,30,44-30-85,30,38-28-86,31,35-25-69,24,20.5-19.5"
        />
      </G>
    </svg>
    <svg
      className="header-squiggle hidden md:block"
      viewBox="0 0 984.84 228"
      style={style}
    >
      <defs>
        <pattern
          id="headersquiggle-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 623 78"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderSquiggle1}
            width="623"
            height="78"
          />
        </pattern>
        <pattern
          id="headersquiggle-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 766 367"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderSquiggle2}
            width="766"
            height="367"
          />
        </pattern>
        <pattern
          id="headersquiggle-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 156 122"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderSquiggle3}
            width="156"
            height="122"
          />
        </pattern>
        <clipPath id="clip-headersquiggle-1">
          <path d="M209.31,109.16l134-10,74-1,52,7,26,4,47,8-4,9-92,3-69,2-52,5-45-2-50,2-34-1Z" />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headersquiggle-1)"
          strokeWidth="30px"
          clipPath="url(#clip-headersquiggle-1)"
          d="M538.31,120.16l-44,3,9-9-45,12,24-19-57,16,21-19-69,20,33-24-84,30,44-30-85,30,38-28-86,31,35-25-69,24,20.5-19.5"
        />
        <Path
          fill="none"
          stroke="url(#headersquiggle-2)"
          strokeWidth="60px"
          transform="scale(1.05) translate(-85 -15)"
          d="M590.09,62.41C790.21-48.47,934.81,36.66,950,208.35"
        />
        <Path
          fill="none"
          stroke="url(#headersquiggle-3)"
          strokeWidth="14px"
          transform="scale(1.05) translate(-89 -26)"
          d="M979.31,153.16l-27,64-45-48"
        />
      </G>
    </svg>
  </>
);

HeaderSquiggle.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderSquiggle;
