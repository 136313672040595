import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern1 from 'images/footer/footer-community-drawing-1.png';
import pattern2 from 'images/footer/footer-community-drawing-2.png';
import pattern3 from 'images/footer/footer-community-drawing-3.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const FooterCommunity = ({ style, footerOpen }) => (
  <svg viewBox="0 0 917.21 368.87" height="368.87" style={style}>
    <defs>
      <style>
        {
          '.b20e1ee7-c6fe-4c54-abd9-55885d91041d{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;letter-spacing:-0.02em;}.b7135b5b-7459-4a36-af26-9b17d2328d44{letter-spacing:-0.01em;}.e08ee1bc-b868-43b5-9a6c-8e18c9b64b92{letter-spacing:-0.01em;}.a14dfe11-b978-4988-92f6-c00304090925{letter-spacing:-0.03em;}.a2136dab-952b-4fb1-a2de-88a95b37eef6{letter-spacing:-0.04em;}.a9013114-c7a2-4f3b-8cef-2ee3b2165841{letter-spacing:-0.03em;}.b9458c16-38b6-45a5-9f79-188047270377{letter-spacing:-0.02em;}.a3acfb12-0228-4cea-89a1-1493f622e333{fill:none;stroke:#000;stroke-miterlimit:10;}'
        }
      </style>
      <pattern
        id="footer-community-1"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 752 566"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern1}
          width="752"
          height="566"
        />
      </pattern>
      <pattern
        id="footer-community-2"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 165 211"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern2}
          width="165"
          height="211"
        />
      </pattern>
      <pattern
        id="footer-community-3"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 140 186"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern3}
          width="140"
          height="186"
        />
        <clipPath id="community-clip-1">
          <path
            transform="translate(0 47.5)"
            d="M695.26,7.79l13.35-5.47L889,.5l23.68,2.43-6.07,40L717.72,46l5.47,237.45-190.66,3.64-1.21-38.82,166.37-8.5Z"
          />
        </clipPath>
      </pattern>
    </defs>
    <g>
      <text
        className="font-display font-extrabold b20e1ee7-c6fe-4c54-abd9-55885d91041d"
        transform="translate(0 202.1)"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the{' '}
        <tspan className="b7135b5b-7459-4a36-af26-9b17d2328d44" x="228.8" y="0">
          c
        </tspan>
        <tspan
          className="e08ee1bc-b868-43b5-9a6c-8e18c9b64b92"
          x="302.24"
          y="0"
        >
          om
        </tspan>
        <tspan
          className="a14dfe11-b978-4988-92f6-c00304090925"
          x="496.94"
          y="0"
        >
          m
        </tspan>
        <tspan
          className="e08ee1bc-b868-43b5-9a6c-8e18c9b64b92"
          x="612.81"
          y="0"
        >
          unity
        </tspan>
        <tspan className="e08ee1bc-b868-43b5-9a6c-8e18c9b64b92">
          <tspan x="0" y="120.85">
            is{' '}
          </tspan>
          <tspan
            className="a2136dab-952b-4fb1-a2de-88a95b37eef6"
            x="128.23"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="a9013114-c7a2-4f3b-8cef-2ee3b2165841"
            x="197.11"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="272.7" y="120.85">
            u
          </tspan>
          <tspan
            className="b9458c16-38b6-45a5-9f79-188047270377"
            x="348.97"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="399.45" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <G pose={footerOpen ? 'to' : 'from'}>
        <Path
          fill="transparent"
          stroke="url(#footer-community-1)"
          // stroke="red"
          strokeWidth="40px"
          clipPath="url(#community-clip-1)"
          d="M538.57,325.94l170.62-7.53L705.42,55.28l195.72,3.43"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-community-2)"
          // stroke="red"
          strokeWidth="34px"
          // clipPath="url(#parks-clip-1)"
          transform="translate(6 9)"
          d="M844.69.37l65.17,58.17L836.6,108.16"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-community-3)"
          // stroke="red"
          strokeWidth="31px"
          // clipPath="url(#parks-clip-1)"
          transform="translate(-12 -5)"
          d="M593.09,366.89,530,326.17l42.77-51.87"
        />
      </G>

      <g data-name="outlined-text">
        <path d="M32.76,148.12v32.36c0,5.11,2.55,6.05,7.93,6.05h5.37V202.1a77.93,77.93,0,0,1-9.67.54c-17.32,0-24.57-5.64-24.57-19.87V148.12H1.48v-15.3H11.82V112.27H32.76v20.55h13.3v15.3Z" />
        <path d="M119.1,156.72V202.1H98.15V160.88c0-7.65-3.35-11.82-11.14-11.82-6.45,0-13.16,4.17-13.16,14.37V202.1H52.9V106.5H73.85V142A25.53,25.53,0,0,1,95.07,131.2C108.76,131.2,119.1,139.53,119.1,156.72Z" />
        <path d="M197.79,173.64h-51.3c1.48,9.93,7.65,14.63,15.85,14.63,7,0,11.54-2.82,14-7.79h19.87c-3.76,14.64-17.18,23.23-33.83,23.23-21.49,0-36-14.23-36-36.25s14.1-36.26,35.72-36.26,35.72,14.91,35.72,38.27Zm-51.17-13.3h30.62c-1.48-8.59-7-13.69-15.44-13.69C154.14,146.65,148.24,150.68,146.62,160.34Z" />
        <path d="M232,167.46c0-22,13.83-36.26,35.58-36.26,19.07,0,32,10.61,33.84,27.67H280.63c-1.21-7.39-6.31-10.61-13-10.61-8.86,0-14.36,5.91-14.36,19.2s5.64,19.07,14.36,19.07c7,0,12.49-3.49,13.43-11.69h20.81c-1.47,17.86-15,28.87-34.24,28.87C246,203.71,232,189.48,232,167.46Z" />
        <path d="M305.47,167.46c0-22,14.23-36.26,36.39-36.26s36.25,14.24,36.25,36.26-14.23,36.25-36.25,36.25S305.47,189.48,305.47,167.46Zm51.42,0c0-13.16-5.77-19.47-15-19.47s-15.18,6.31-15.18,19.47,5.78,19.47,15.18,19.47S356.89,180.62,356.89,167.46Z" />
        <path d="M492.91,157.12v45H472V160.75c0-7.52-3-11.69-10.34-11.69-6.31,0-11.95,4.17-11.95,14.77V202.1H428.73V160.75c0-7.52-3-11.69-10.34-11.69-6.31,0-12.09,4.17-12.09,14.77V202.1H385.36V132.82h20.41v9.8c4.43-6.58,10.61-11.42,19.74-11.42,10.07,0,17.72,4.44,21.48,12.49,4.7-7,12.09-12.49,21.62-12.49C483.51,131.2,492.91,140.47,492.91,157.12Z" />
        <path d="M610.53,157.12v45H589.59V160.75c0-7.52-3-11.69-10.34-11.69-6.31,0-12,4.17-12,14.77V202.1H546.35V160.75c0-7.52-3-11.69-10.34-11.69-6.31,0-12.08,4.17-12.08,14.77V202.1H503V132.82h20.41v9.8C527.82,136,534,131.2,543.13,131.2c10.07,0,17.72,4.44,21.48,12.49,4.7-7,12.09-12.49,21.62-12.49C601.13,131.2,610.53,140.47,610.53,157.12Z" />
        <path d="M685.05,132.82V202.1H664.64v-9.8c-4.56,6.45-11.54,11.41-21.75,11.41-13.69,0-24-8.32-24-25.51V132.82H639.8V174c0,7.65,3.36,11.82,11.15,11.82,6.44,0,13.16-4.17,13.16-14.37V132.82Z" />
        <path d="M761.32,156.72V202.1h-21V160.88c0-7.65-3.35-11.82-11.14-11.82-6.45,0-13.16,4.17-13.16,14.37V202.1h-21V132.82h20.41v9.8a25.82,25.82,0,0,1,21.76-11.42C751,131.2,761.32,139.53,761.32,156.72Z" />
        <path d="M771.39,132.82h20.94V202.1H771.39Zm.53-25.65h20v17.72h-20Z" />
        <path d="M829.26,148.12v32.36c0,5.11,2.55,6.05,7.92,6.05h5.37V202.1a77.82,77.82,0,0,1-9.67.54c-17.32,0-24.57-5.64-24.57-19.87V148.12H798v-15.3h10.34V112.27h21v20.55h13.29v15.3Z" />
        <path d="M917.21,132.82l-25.11,72c-6,17.45-14,23.77-34.51,23.77-4,0-6-.14-8.19-.27V212.17h6.71c7.79,0,10.48-4.43,10.48-9.66,0-3.9-1.62-8.87-3.23-13.43l-19.87-56.26h22.56l3.09,9.8c4,13.56,8.05,27.79,11.81,43.64,3.76-16,7.66-30.08,11.55-43.51l3.09-9.93Z" />
        <path d="M6,253.66H27V323H6ZM6.58,228h20v17.73h-20Z" />
        <path d="M33,300.93H53.44C54.25,308.45,58,311,66.73,311c8.46,0,11.69-2.55,11.69-6.72s-2.55-6.17-12-7.65l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21,0-13.83,10.88-21.89,31.16-21.89,23.36,0,31.55,10.21,32.22,23.77H78c-.27-7.52-5.23-9.81-12.75-9.81s-10.61,2.56-10.61,6.58c0,4.17,3.36,5.91,10.07,7l10.34,1.47c16.38,2.56,24.3,9.13,24.3,21.49,0,14.37-12.22,22.42-33,22.42C45.12,325,34.24,318.11,33,300.93Z" />
        <path d="M202.08,253.66l-25.11,72c-6,17.46-14,23.77-34.51,23.77-4,0-6-.13-8.19-.27V333H141c7.79,0,10.48-4.43,10.48-9.67,0-3.89-1.62-8.86-3.23-13.43l-19.87-56.26h22.56l3.09,9.8c4,13.57,8.05,27.8,11.81,43.64,3.76-16,7.66-30.07,11.55-43.5l3.09-9.94Z" />
        <path d="M200.33,288.3c0-22,14.23-36.25,36.39-36.25S273,266.28,273,288.3s-14.23,36.26-36.25,36.26S200.33,310.33,200.33,288.3Zm51.43,0c0-13.15-5.78-19.47-15-19.47s-15.17,6.32-15.17,19.47,5.77,19.48,15.17,19.48S251.76,301.46,251.76,288.3Z" />
        <path d="M344.94,253.66V323H324.53v-9.8c-4.56,6.44-11.54,11.41-21.75,11.41-13.7,0-24-8.33-24-25.51V253.66h20.94v41.22c0,7.66,3.36,11.82,11.15,11.82,6.44,0,13.16-4.16,13.16-14.37V253.66Z" />
        <path d="M399.73,252.86v19.06h-5.38c-11.68,0-18.39,5.78-18.39,18.8V323H355V253.66h20.41v12.89c3-8.59,9.4-14,19.74-14C396.77,252.59,398.25,252.72,399.73,252.86Z" />
        <path d="M401.33,300.93h20.41c.81,7.52,4.57,10.07,13.3,10.07,8.46,0,11.68-2.55,11.68-6.72s-2.55-6.17-12-7.65l-9-1.48c-14.63-2.28-23.36-8.86-23.36-21.21,0-13.83,10.88-21.89,31.15-21.89,23.36,0,31.56,10.21,32.23,23.77H446.32c-.27-7.52-5.24-9.81-12.76-9.81S423,268.57,423,272.59c0,4.17,3.36,5.91,10.07,7l10.34,1.47c16.38,2.56,24.31,9.13,24.31,21.49,0,14.37-12.22,22.42-33,22.42C413.42,325,402.54,318.11,401.33,300.93Z" />
        <path d="M471.83,299.85h23.63V323H471.83Z" />
      </g>
    </g>
  </svg>
);

FooterCommunity.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterCommunity;
