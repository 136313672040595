import nanostyled from 'nanostyled';
import Img from 'gatsby-image';

export const Margin = nanostyled('div', {
  base: '',
  margin: '',
});

export const Container = nanostyled('section', {
  base: 'w-full typography',
  align: 'mx-auto',
  width: 'max-w-5xl',
  margin: '',
});

export const Flex = nanostyled('div', {
  base: 'flex w-full relative',
  order: '',
  flex: '',
  height: '',
});

export const StyledImage = nanostyled(Img, {
  base: 'w-full h-full max-h-screen border-solid border-white border',
  height: '',
  width: '',
});

export const Number = nanostyled('div', {
  base:
    'absolute top-0 left-0 font-micro text-xxs text-white font-extrabold pt-2 pb-6 pl-2 pr-6 corner',
});

export const Relative = nanostyled('div', {
  base: 'relative w-full',
  width: '',
  height: '',
});
