import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/blocks/button';
import PreviousIcon from 'images/large-prev.svg';
import NextIcon from 'images/large-next.svg';

import {
  Container,
  StyledLink,
  Flex,
  Title,
  Type,
} from './journal-post-nav.css';

const JournalPostNav = ({ pageColor, previous, next }) => (
  <Container>
    <div className="text-center my-10 sm:my-20">
      <Button
        text="Work with us"
        link="/work-with-us"
        isInternal={true}
        isInline
      />
      <Button text="Support NDC" link="/donate" isInternal={true} isInline />
    </div>

    <Flex
      width="max-w-7xl w-full xl:mb-32"
      justify="flex-col sm:flex-row justify-center"
    >
      {previous && (
        <StyledLink
          to={`journal/${previous.slug}`}
          color={`border-brand-${pageColor} hover:bg-brand-${pageColor}`}
        >
          {previous && (
            <>
              <PreviousIcon
                className={`stroke-current w-12 mr-3 md:mr-6 text-brand-${pageColor} group-hover:text-white`}
              />
              <div className={`w-full typography ${pageColor}`}>
                <Type color={`text-brand-${pageColor} group-hover:text-white`}>
                  previous journal post
                </Type>
                <Title>{previous && previous.title}</Title>
              </div>
            </>
          )}
        </StyledLink>
      )}
      {next && (
        <StyledLink
          to={`journal/${next.slug}`}
          color={`border-brand-${pageColor} hover:bg-brand-${pageColor}`}
        >
          {next && (
            <>
              <div className={`w-full typography ${pageColor}`}>
                <Type color={`text-brand-${pageColor} group-hover:text-white`}>
                  next journal post
                </Type>
                <Title>{next && next.title}</Title>
              </div>
              <NextIcon
                className={`stroke-current w-12 ml-3 md:ml-6 text-brand-${pageColor} group-hover:text-white`}
              />
            </>
          )}
        </StyledLink>
      )}
    </Flex>
  </Container>
);

JournalPostNav.propTypes = {
  pageColor: PropTypes.string.isRequired,
  previous: PropTypes.object,
  next: PropTypes.object,
};

export default JournalPostNav;
