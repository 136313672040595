import nanostyled from 'nanostyled';

export const Margin = nanostyled('div', {
  base: 'mx-4 xs:mx-10 sm:mx-20',
});

export const Center = nanostyled('div', {
  base: 'mx-auto max-w-4xl',
});

export const Container = nanostyled('section', {
  base: 'max-w-xl w-full typography contact-ndc',
  align: '1',
  margin: 'my-16 sm:my-32',
});

export const Flex = nanostyled('div', {
  base: 'flex flex-col sm:flex-row justify-between typography',
  color: '',
});

export const Location = nanostyled('div', {
  base: 'max-w-sm p-no-space text-lg mb-10',
});
