import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import useForm from 'react-hook-form';

import {
  Container,
  Submit,
  IconWrapper,
  StyledIconLink,
  Margin,
  Flex,
} from './subscribe-and-social.css';

const SubscribeAndSocial = ({
  pageColor,
  newsletterTitle,
  socialLinksTitle,
  facebookLink,
  instagramLink,
  twitterLink,
  linkedinLink,
  prev,
  next,
}) => {
  const { register, errors } = useForm();
  // const onSubmit = data => {
  //   console.log(data);
  //   console.log(errors);
  //   console.log('submit');
  // };
  return (
    <Margin>
      <Container
        margin={`${next ? 'mb-16 sm:mb-32' : ''} ${
          prev ? 'mt-16 sm:mt-32' : ''
        } `}
        color={pageColor}
      >
        <span className="scroll-offset" id="subscribe-ndc" />
        {newsletterTitle && <h3>{newsletterTitle}</h3>}

        <form
          action="https://ndc-md.us5.list-manage.com/subscribe/post?u=ed84a297cb5eaa321abed5b19&amp;id=17d977758a"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="w-full max-w-3xl validate"
          target="_blank"
          noValidate
        >
          <div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div>
          <Flex flex="flex-col sm:flex-row">
            <input
              className="sm:mr-2 inline-block p-4 border-solid border-brand-gray focus:border-black w-full border-2 mt-6 text-lg focus:outline-none rounded-none"
              type="text"
              placeholder="First name"
              name="FNAME"
              id="mce-FNAME"
              aria-invalid={errors.FNAME ? 'true' : 'false'}
              aria-describedby="error-FNAME-maxLength"
              ref={register({ maxLength: 80 })}
            />
            <input
              className="sm:ml-2 inline-block p-4 border-solid border-brand-gray focus:border-black w-full border-2 mt-6 text-lg focus:outline-none rounded-none"
              type="text"
              placeholder="Last name"
              name="LNAME"
              id="mce-LNAME"
              aria-invalid={errors.LNAME ? 'true' : 'false'}
              aria-describedby="error-LNAME-maxLength"
              ref={register({ maxLength: 100 })}
            />
          </Flex>
          <Flex>
            <input
              className="md:border-r-0 inline-block p-4 border-solid border-brand-gray focus:border-black w-full border-2 mt-6 text-lg focus:outline-none rounded-none"
              type="email"
              placeholder="Email *"
              name="EMAIL"
              id="mce-EMAIL"
              aria-invalid={errors.EMAIL ? 'true' : 'false'}
              aria-describedby="error-EMAIL-required error-EMAIL-pattern"
              ref={register({ required: true, pattern: /^\S+@\S+$/i })}
            />

            <Submit
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              value="Submit"
            />
          </Flex>

          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            />
          </div>
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_ed84a297cb5eaa321abed5b19_17d977758a"
              tabIndex="-1"
              value=""
            />
          </div>

          {errors.FNAME && errors.FNAME.type === 'maxLength' && (
            <div
              className="my-2 font-extrabold"
              role="alert"
              id="error-FNAME-maxLength"
            >
              First Name maximum length exceeded
            </div>
          )}
          {errors.LNAME && errors.LNAME.type === 'maxLength' && (
            <div
              className="my-2 font-extrabold"
              role="alert"
              id="error-LNAME-maxLength"
            >
              Last Name maximum length exceeded
            </div>
          )}
          {errors.EMAIL && errors.EMAIL.type === 'required' && (
            <div
              className="my-2 font-extrabold"
              role="alert"
              id="error-EMAIL-required"
            >
              Email is required
            </div>
          )}
          {errors.EMAIL && errors.EMAIL.type === 'pattern' && (
            <div
              className="my-2 font-extrabold"
              role="alert"
              id="error-EMAIL-pattern"
            >
              This is not a valid email address
            </div>
          )}
        </form>
        {socialLinksTitle && (
          <div className="mt-16">
            <h3>{socialLinksTitle}</h3>
          </div>
        )}
        <IconWrapper margin={!socialLinksTitle && 'mt-6'}>
          <StyledIconLink
            href={facebookLink || null}
            target="_blank"
            size={!socialLinksTitle && 'text-2xl'}
            color={
              !socialLinksTitle &&
              `text-brand-${pageColor} hover:text-black hover:social-hover`
            }
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </StyledIconLink>
          <StyledIconLink
            href={instagramLink || null}
            target="_blank"
            size={!socialLinksTitle && 'text-2xl'}
            color={
              !socialLinksTitle &&
              `text-brand-${pageColor} hover:text-black hover:social-hover`
            }
          >
            <FontAwesomeIcon icon={faInstagram} />
          </StyledIconLink>
          <StyledIconLink
            href={twitterLink || null}
            target="_blank"
            size={!socialLinksTitle && 'text-2xl'}
            color={
              !socialLinksTitle &&
              `text-brand-${pageColor} hover:text-black hover:social-hover`
            }
          >
            <FontAwesomeIcon icon={faTwitter} />
          </StyledIconLink>
          <StyledIconLink
            href={linkedinLink || null}
            target="_blank"
            size={!socialLinksTitle && 'text-2xl'}
            color={
              !socialLinksTitle &&
              `text-brand-${pageColor} hover:text-black hover:social-hover`
            }
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </StyledIconLink>
        </IconWrapper>
      </Container>
    </Margin>
  );
};

SubscribeAndSocial.propTypes = {
  pageColor: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
  instagramLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  newsletterTitle: PropTypes.string,
  socialLinksTitle: PropTypes.string,
  prev: PropTypes.string,
  next: PropTypes.string,
};

export default SubscribeAndSocial;
