import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern from 'images/footer/footer-corner-drawing.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 1200 }) => ({
      duration: dur,
    }),
  },
});

// const G = posed.g({
//   from: { delay: 250 },
//   to: {
//     delay: 250,
//     staggerChildren: ({ dur = 600 }) => dur,
//   },
// });

const FooterCorner = ({ style, footerOpen }) => (
  <svg viewBox="0 0 694.65 307.07" height="307.07" style={style}>
    <defs>
      <style>
        {
          '.eb7b1365-6060-4b7d-83a7-d70607e9c05e{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;letter-spacing:-0.02em;}.a1237998-64c8-4509-b9b0-0c1d9fdb33c7{letter-spacing:-0.01em;}.ec0f662e-035a-42d7-b508-e1504dd818b2{letter-spacing:-0.01em;}.b89f7c24-0470-46ac-8e9c-f5a97c6a44ab{letter-spacing:-0.02em;}.aa0bddb1-9cf8-48f0-9a5f-452c6763a291{letter-spacing:-0.04em;}.f5dcce69-fbae-4504-bd1d-12bf454b4e65{letter-spacing:-0.03em;}.e3a529fb-67dc-4c45-9bb1-0d669d626bde{letter-spacing:-0.02em;}.aec4f501-3407-4552-bc35-7b2492d3daba{fill:none;stroke:#231f20;stroke-miterlimit:10;}'
        }
      </style>
      <pattern
        id="footer-corner"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 990 375"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern}
          width="990"
          height="375"
        />
      </pattern>
      <clipPath id="corner-clip-1">
        <path d="M198.81,1.17c171,9.25,333.05,10.09,482.68-.64L700.92,201s-31.24-3.13-59.22-3.3c-131.31-16.65-291-10.09-439.77-2l-1.74-86.72L189.8,22.29Z" />
      </clipPath>
    </defs>
    <g>
      <text
        className="font-display font-extrabold eb7b1365-6060-4b7d-83a7-d70607e9c05e"
        transform="translate(0 140.3)"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the{' '}
        <tspan className="a1237998-64c8-4509-b9b0-0c1d9fdb33c7" x="228.8" y="0">
          c
        </tspan>
        <tspan
          className="ec0f662e-035a-42d7-b508-e1504dd818b2"
          x="302.24"
          y="0"
        >
          o
        </tspan>
        <tspan
          className="b89f7c24-0470-46ac-8e9c-f5a97c6a44ab"
          x="379.31"
          y="0"
        >
          r
        </tspan>
        <tspan
          className="ec0f662e-035a-42d7-b508-e1504dd818b2"
          x="430.47"
          y="0"
        >
          ner
        </tspan>
        <tspan className="ec0f662e-035a-42d7-b508-e1504dd818b2">
          <tspan x="0" y="120.85">
            is{' '}
          </tspan>
          <tspan
            className="aa0bddb1-9cf8-48f0-9a5f-452c6763a291"
            x="128.23"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="f5dcce69-fbae-4504-bd1d-12bf454b4e65"
            x="197.11"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="272.7" y="120.85">
            u
          </tspan>
          <tspan
            className="e3a529fb-67dc-4c45-9bb1-0d669d626bde"
            x="348.97"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="399.45" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <Path
        pose={footerOpen ? 'to' : 'from'}
        fill="transparent"
        stroke="url(#footer-corner)"
        strokeWidth="39px"
        clipPath="url(#corner-clip-1)"
        d="M214.73,160c-10.38-46.16-17-96.95-20.11-152l.93,21.52C311.81,2.77,478.87,5.77,658.11,15.89l21.52-15c2.16,45.75,8.11,116.65,14.5,190.35-10.34,8.23-17.4,6.33-21-6.08-131.44-13-290.94-11.46-464.9-2.33"
      />

      <g data-name="outlined-text">
        <path d="M32.76,86.32v32.37c0,5.1,2.55,6,7.93,6h5.37V140.3a77.93,77.93,0,0,1-9.67.54c-17.32,0-24.57-5.64-24.57-19.87V86.32H1.48V71H11.82V50.47H32.76V71h13.3v15.3Z" />
        <path d="M119.1,94.92V140.3H98.15V99.08c0-7.65-3.35-11.82-11.14-11.82-6.45,0-13.16,4.17-13.16,14.37V140.3H52.9V44.7H73.85V80.15A25.52,25.52,0,0,1,95.07,69.41C108.76,69.41,119.1,77.73,119.1,94.92Z" />
        <path d="M197.79,111.84h-51.3c1.48,9.93,7.65,14.63,15.85,14.63,7,0,11.54-2.82,14-7.78h19.87c-3.76,14.63-17.18,23.23-33.83,23.23-21.49,0-36-14.24-36-36.26s14.1-36.25,35.72-36.25,35.72,14.9,35.72,38.27Zm-51.17-13.3h30.62c-1.48-8.59-7-13.69-15.44-13.69C154.14,84.85,148.24,88.88,146.62,98.54Z" />
        <path d="M232,105.66c0-22,13.83-36.25,35.58-36.25,19.07,0,32,10.6,33.84,27.66H280.63c-1.21-7.39-6.31-10.61-13-10.61-8.86,0-14.36,5.91-14.36,19.2s5.64,19.07,14.36,19.07c7,0,12.49-3.49,13.43-11.68h20.81c-1.47,17.86-15,28.87-34.24,28.87C246,141.92,232,127.68,232,105.66Z" />
        <path d="M305.47,105.66c0-22,14.23-36.25,36.39-36.25s36.25,14.23,36.25,36.25-14.23,36.26-36.25,36.26S305.47,127.68,305.47,105.66Zm51.42,0c0-13.16-5.77-19.47-15-19.47s-15.18,6.31-15.18,19.47,5.78,19.47,15.18,19.47S356.89,118.82,356.89,105.66Z" />
        <path d="M430.07,70.21V89.28H424.7c-11.68,0-18.4,5.77-18.4,18.8V140.3H385.36V71h20.41V83.91c3-8.6,9.4-14,19.74-14C427.12,69.94,428.59,70.08,430.07,70.21Z" />
        <path d="M502.71,94.92V140.3H481.77V99.08c0-7.65-3.36-11.82-11.15-11.82-6.44,0-13.16,4.17-13.16,14.37V140.3H436.51V71h20.41v9.8a25.82,25.82,0,0,1,21.76-11.41C492.37,69.41,502.71,77.73,502.71,94.92Z" />
        <path d="M581.4,111.84H530.1c1.48,9.93,7.66,14.63,15.85,14.63,7,0,11.54-2.82,14-7.78h19.87C576,133.32,562.6,141.92,546,141.92c-21.49,0-36-14.24-36-36.26s14.1-36.25,35.72-36.25,35.72,14.9,35.72,38.27Zm-51.16-13.3h30.61c-1.48-8.59-7-13.69-15.44-13.69C537.76,84.85,531.85,88.88,530.24,98.54Z" />
        <path d="M633.89,70.21V89.28h-5.37c-11.68,0-18.39,5.77-18.39,18.8V140.3h-21V71h20.41V83.91c2.95-8.6,9.4-14,19.74-14C630.94,69.94,632.42,70.08,633.89,70.21Z" />
        <path d="M6,191.86H27v69.29H6Zm.54-25.64h20v17.72h-20Z" />
        <path d="M33,239.13H53.44c.81,7.52,4.57,10.07,13.29,10.07,8.46,0,11.69-2.55,11.69-6.71s-2.55-6.18-12-7.66l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21,0-13.83,10.88-21.89,31.16-21.89,23.36,0,31.55,10.21,32.22,23.77H78c-.27-7.52-5.23-9.8-12.75-9.8s-10.61,2.55-10.61,6.58,3.36,5.9,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.42-33,22.42C45.12,263.16,34.24,256.32,33,239.13Z" />
        <path d="M202.08,191.86l-25.11,72c-6,17.45-14,23.76-34.51,23.76-4,0-6-.13-8.19-.27V271.22H141c7.79,0,10.48-4.43,10.48-9.67,0-3.89-1.62-8.86-3.23-13.43l-19.87-56.26h22.56l3.09,9.8c4,13.57,8.05,27.8,11.81,43.64,3.76-16,7.66-30.07,11.55-43.5l3.09-9.94Z" />
        <path d="M200.33,226.51c0-22,14.23-36.26,36.39-36.26S273,204.48,273,226.51s-14.23,36.25-36.25,36.25S200.33,248.53,200.33,226.51Zm51.43,0c0-13.16-5.78-19.47-15-19.47s-15.17,6.31-15.17,19.47S227.32,246,236.72,246,251.76,239.67,251.76,226.51Z" />
        <path d="M344.94,191.86v69.29H324.53v-9.8c-4.56,6.44-11.54,11.41-21.75,11.41-13.7,0-24-8.32-24-25.51V191.86h20.94v41.23c0,7.65,3.36,11.81,11.15,11.81,6.44,0,13.16-4.16,13.16-14.37V191.86Z" />
        <path d="M399.73,191.06v19.06h-5.38c-11.68,0-18.39,5.78-18.39,18.8v32.23H355V191.86h20.41v12.89c3-8.59,9.4-14,19.74-14C396.77,190.79,398.25,190.92,399.73,191.06Z" />
        <path d="M401.33,239.13h20.41c.81,7.52,4.57,10.07,13.3,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-12-7.66l-9-1.48c-14.63-2.28-23.36-8.86-23.36-21.21,0-13.83,10.88-21.89,31.15-21.89,23.36,0,31.56,10.21,32.23,23.77H446.32c-.27-7.52-5.24-9.8-12.76-9.8S423,206.77,423,210.8s3.36,5.9,10.07,7l10.34,1.48c16.38,2.55,24.31,9.13,24.31,21.48,0,14.37-12.22,22.42-33,22.42C413.42,263.16,402.54,256.32,401.33,239.13Z" />
        <path d="M471.83,238.05h23.63v23.1H471.83Z" />
      </g>
    </g>
  </svg>
);

FooterCorner.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterCorner;
