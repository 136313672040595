import React from 'react';
import Transition from 'components/transition';
import PropTypes from 'prop-types';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false;

import Menu from 'containers/menu';

const wrapPageElement = ({ element, props }) => {
  return (
    <div>
      <Menu />
      <Transition {...props}>{element}</Transition>
    </div>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.any,
  props: PropTypes.any,
};

export default wrapPageElement;
