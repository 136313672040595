import parse from 'url-parse';

const urlParse = input => {
  const url = parse(input, true);
  if (
    url.host === 'www.ndc-md.org' ||
    url.host === 'ndc-md.org' ||
    url.host === 'www.ndc-md.netlify.com' ||
    url.host === 'ndc-md.netlify.com'
  ) {
    return { isInternal: true, url: url.pathname };
  }
  return { isInternal: false, url: input };
};

export default urlParse;
