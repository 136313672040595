import React from 'react';
import PropTypes from 'prop-types';
import { reduce, includes } from 'lodash';

import { Center, Container, StyledButton } from './ourwork-filter.css';

const OurworkFilter = ({
  items,
  filterCaseStudies,
  changeFilterCaseStudies,
}) => (
  <Center>
    <Container>
      <StyledButton
        key={'all'}
        onClick={() => changeFilterCaseStudies(null)}
        active={
          filterCaseStudies == null
            ? 'text-brand-blue border-b-2 border-solid border-brand-blue'
            : 'border-b-2 border-solid border-transparent'
        }
      >
        All
      </StyledButton>
      {reduce(
        items,
        function(carry, item) {
          item.categories &&
            item.categories.map(
              category => !includes(carry, category) && carry.push(category)
            );
          return carry;
        },
        []
      ).map(category => (
        <StyledButton
          key={category}
          onClick={() => changeFilterCaseStudies(category)}
          active={
            filterCaseStudies == category
              ? 'text-brand-blue border-b-2 border-solid border-brand-blue'
              : 'border-b-2 border-solid border-transparent'
          }
        >
          {category}
        </StyledButton>
      ))}
    </Container>
  </Center>
);

OurworkFilter.propTypes = {
  items: PropTypes.array.isRequired,
  filterCaseStudies: PropTypes.string,
  changeFilterCaseStudies: PropTypes.func,
};

export default OurworkFilter;
