import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderClouds1 from 'images/header/header-clouds1.png';
import patternHeaderClouds2 from 'images/header/header-clouds2.png';
import patternHeaderClouds3 from 'images/header/header-clouds3.png';
import patternHeaderClouds4 from 'images/header/header-clouds4.png';
import patternHeaderClouds5 from 'images/header/header-clouds5.png';
import patternHeaderClouds6 from 'images/header/header-clouds6.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderClouds = ({ style, isVisible }) => (
  <>
    <svg
      className="header-clouds hidden lg:block"
      viewBox="0 0 970.5 510.6"
      style={style}
    >
      <defs>
        <pattern
          id="headerclouds-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 733 194"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds1}
            width="733"
            height="194"
          />
        </pattern>
        <pattern
          id="headerclouds-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 718 41"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds2}
            width="718"
            height="41"
          />
        </pattern>
        <pattern
          id="headerclouds-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 729 48"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds3}
            width="729"
            height="48"
          />
        </pattern>
        <pattern
          id="headerclouds-4"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 711 56"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds4}
            width="711"
            height="56"
          />
        </pattern>
        <pattern
          id="headerclouds-5"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 890 509"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds5}
            width="890"
            height="509"
          />
        </pattern>
        <pattern
          id="headerclouds-6"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 155 115"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds6}
            width="155"
            height="115"
          />
        </pattern>
        <clipPath id="headerclouds-clip-1">
          <rect
            fill="none"
            stroke="red"
            strokeWidth="2px"
            transform="scale(1.05) translate(-10, -5)"
            x="18"
            y="8"
            width="336"
            height="100"
          />
        </clipPath>
        <clipPath id="headerclouds-clip-2">
          <polygon
            points="965,430 880,430 870,500 950,500"
            fill="none"
            stroke="red"
            strokeWidth="2px"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headerclouds-2)"
          strokeWidth="10px"
          d="M108.5,319.5c109.8-15.2,221.2-16,335,6"
        />
        <Path
          fill="none"
          stroke="url(#headerclouds-3)"
          strokeWidth="10px"
          d="M444.5,327.5c-122.4-18.1-237.4-18.9-341,7"
        />
        <Path
          fill="none"
          stroke="url(#headerclouds-4)"
          strokeWidth="10px"
          d="M103.5,336.5c100.9-14.7,211.8-9.2,332,15"
        />
        <Path
          fill="none"
          stroke="url(#headerclouds-5)"
          strokeWidth="40px"
          d="M502.5,281.5c21.4-43.2,71.1-64.4,150-63c125.9,20.5,232,109,281,257"
        />
        <Path
          fill="none"
          stroke="url(#headerclouds-6)"
          clipPath="url(#headerclouds-clip-2)"
          strokeWidth="30px"
          d="M963.5,437.5l-24,61l-54-42"
        />

        <Path
          fill="none"
          stroke="url(#headerclouds-1)"
          strokeWidth="20px"
          clipPath="url(#headerclouds-clip-1)"
          d="M11,90C0.5,65.5,15.7,48.5,30.5,47.5c6-0.4,12.5,3.5,17,12c-21-55,65-77,72.5-12.5c5.6-8.9,12.5-12.5,20.5-11.5 s16,5,11.5,29.5c11.1-8.6,21.5-10.6,30,2c2.8-30.7,17.5-45,42.5-49.5c19.4-3.5,41.3,7,44,41c12-14.2,20.1-12.7,30-9.3 c9.3,3.2,13.9,11.4,13.5,23.8c13.7-13.4,26.6-16.5,38.5-8.5c11.1,7.5,13,17.9,5.5,34.5"
        />
      </G>
    </svg>
    <svg
      className="header-clouds lg:hidden"
      viewBox="0 0 430 380"
      style={style}
    >
      <defs>
        <pattern
          id="m-headerclouds-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 733 194"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds1}
            width="733"
            height="194"
          />
        </pattern>
        <pattern
          id="m-headerclouds-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 718 41"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds2}
            width="718"
            height="41"
          />
        </pattern>
        <pattern
          id="m-headerclouds-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 729 48"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds3}
            width="729"
            height="48"
          />
        </pattern>
        <pattern
          id="m-headerclouds-4"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 711 56"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderClouds4}
            width="711"
            height="56"
          />
        </pattern>
        <clipPath id="m-headerclouds-clip-1">
          <rect
            fill="none"
            stroke="red"
            strokeWidth="2px"
            transform="scale(1.05) translate(-10, -5)"
            x="18"
            y="8"
            width="336"
            height="100"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headerclouds-2)"
          strokeWidth="10px"
          d="M108.5,319.5c109.8-15.2,221.2-16,335,6"
        />
        <Path
          fill="none"
          stroke="url(#m-headerclouds-3)"
          strokeWidth="10px"
          d="M444.5,327.5c-122.4-18.1-237.4-18.9-341,7"
        />
        <Path
          fill="none"
          stroke="url(#m-headerclouds-4)"
          strokeWidth="10px"
          d="M103.5,336.5c100.9-14.7,211.8-9.2,332,15"
        />
        <Path
          fill="none"
          stroke="url(#m-headerclouds-1)"
          strokeWidth="20px"
          clipPath="url(#m-headerclouds-clip-1)"
          d="M11,90C0.5,65.5,15.7,48.5,30.5,47.5c6-0.4,12.5,3.5,17,12c-21-55,65-77,72.5-12.5c5.6-8.9,12.5-12.5,20.5-11.5 s16,5,11.5,29.5c11.1-8.6,21.5-10.6,30,2c2.8-30.7,17.5-45,42.5-49.5c19.4-3.5,41.3,7,44,41c12-14.2,20.1-12.7,30-9.3 c9.3,3.2,13.9,11.4,13.5,23.8c13.7-13.4,26.6-16.5,38.5-8.5c11.1,7.5,13,17.9,5.5,34.5"
        />
      </G>
    </svg>
  </>
);

HeaderClouds.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderClouds;
