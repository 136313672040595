import React from 'react';
import PropTypes from 'prop-types';

import IO from './io';
import { Container } from './io-footer.css';

class IOFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showFooter } = this.props;

    return (
      <IO
        id="io-footer"
        footer="io-footer"
        rootMargin="-50% 0% 0% 0%"
        funcHasBeenVisible={showFooter}
      >
        {() => <Container />}
      </IO>
    );
  }
}

IOFooter.propTypes = {
  showFooter: PropTypes.func,
};

export default IOFooter;
