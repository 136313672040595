import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern1 from 'images/footer/footer-block-drawing-1.png';
import pattern2 from 'images/footer/footer-block-drawing-2.png';
import pattern3 from 'images/footer/footer-block-drawing-3.png';
import pattern4 from 'images/footer/footer-block-drawing-4.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 400 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 400 }) => dur,
  },
});

const FooterBlock = ({ style, footerOpen }) => (
  <svg viewBox="0 0 716.42 325.3" width="716.42" height="325.3" style={style}>
    <defs>
      <style>
        {
          '.acc0d43e-5c8d-40b6-b7b1-005d808e10d1{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;letter-spacing:-0.02em;}.e190a887-1479-46fe-879d-56b5eee712c3{letter-spacing:-0.01em;}.a79166e3-914c-4a8a-9f6a-f84e84238fd2{letter-spacing:-0.04em;}.b7215092-c425-4222-8343-a8b18fad89c8{letter-spacing:-0.03em;}.e300c102-0b18-4c1b-9598-e0698cc94f4e{letter-spacing:-0.02em;}.b1840c51-0733-4ce6-ba12-fbe54be17b40{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:1px;}'
        }
      </style>
      <pattern
        id="footer-block-1"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 195 192"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern1}
          width="195"
          height="192"
        />
      </pattern>
      <pattern
        id="footer-block-2"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 177 205"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern2}
          width="177"
          height="205"
        />
      </pattern>
      <pattern
        id="footer-block-3"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 143 214"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern3}
          width="143"
          height="214"
        />
      </pattern>
      <pattern
        id="footer-block-4"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 180 195"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern4}
          width="180"
          height="195"
        />
      </pattern>

      <clipPath id="block-clip">
        <rect x="0" y="-1" width="712" height="337" />
      </clipPath>
    </defs>
    <g>
      <text
        className="font-display font-extrabold acc0d43e-5c8d-40b6-b7b1-005d808e10d1"
        transform="translate(67.13 153.63)"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the block
        <tspan className="e190a887-1479-46fe-879d-56b5eee712c3">
          <tspan x="0" y="120.85">
            is{' '}
          </tspan>
          <tspan
            className="a79166e3-914c-4a8a-9f6a-f84e84238fd2"
            x="128.23"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="b7215092-c425-4222-8343-a8b18fad89c8"
            x="197.11"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="272.7" y="120.85">
            u
          </tspan>
          <tspan
            className="e300c102-0b18-4c1b-9598-e0698cc94f4e"
            x="348.97"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="399.45" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <G pose={footerOpen ? 'to' : 'from'}>
        <Path
          fill="transparent"
          stroke="url(#footer-block-1)"
          // stroke="red"
          strokeWidth="50px"
          transform="scale(1.01) translate(-6 0)"
          d="M101.15,321.77l-94,3L.5,224.13"
          clipPath="url(#block-clip)"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-block-2)"
          // stroke="red"
          strokeWidth="50px"
          transform="scale(1.01) translate(-5 -3)"
          d="M.5,105.4,4.11.54l88.6,6.63"
          clipPath="url(#block-clip)"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-block-3)"
          // stroke="red"
          strokeWidth="50px"
          transform="scale(1.01) translate(3 -3)"
          d="M636.34.54l70.51,1.8,3.62,105.48"
          clipPath="url(#block-clip)"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-block-4)"
          // stroke="red"
          strokeWidth="50px"
          transform="scale(1.01) translate(0 3)"
          d="M710.47,221.72l5.42,93.42-94,4.22"
          clipPath="url(#block-clip)"
        />
      </G>

      <g data-name="outlined-text">
        <path d="M99.89,99.66V132c0,5.1,2.56,6,7.93,6h5.37v15.57a77.93,77.93,0,0,1-9.67.54C86.2,154.17,79,148.53,79,134.3V99.66H68.61V84.35H79V63.8H99.89V84.35h13.3V99.66Z" />
        <path d="M186.23,108.25v45.38H165.28V112.41c0-7.65-3.35-11.81-11.14-11.81C147.7,100.6,141,104.76,141,115v38.67H120V58H141V93.48A25.52,25.52,0,0,1,162.2,82.74C175.89,82.74,186.23,91.06,186.23,108.25Z" />
        <path d="M264.92,125.17h-51.3c1.48,9.93,7.66,14.63,15.85,14.63,7,0,11.54-2.82,14-7.78H263.3c-3.76,14.63-17.18,23.23-33.83,23.23-21.49,0-36-14.24-36-36.26s14.1-36.25,35.72-36.25,35.72,14.9,35.72,38.27Zm-51.16-13.3h30.61c-1.48-8.59-7-13.69-15.44-13.69C221.28,98.18,215.37,102.21,213.76,111.87Z" />
        <path d="M373.54,119c0,22-11.41,36.26-30.21,36.26-9.8,0-16.52-4.17-20.95-10.88v9.26H302V58h20.95V92.81c4.43-6.31,11-10.07,20.41-10.07C362.13,82.74,373.54,97,373.54,119Zm-21.22-.54c0-13.16-5.5-18.93-14.77-18.93s-14.9,6.31-14.9,19.47,5.5,19.47,14.9,19.47S352.32,131.61,352.32,118.45Z" />
        <path d="M380.79,58h20.94v95.6H380.79Z" />
        <path d="M409.12,119c0-22,14.23-36.25,36.39-36.25S481.76,97,481.76,119s-14.23,36.26-36.25,36.26S409.12,141,409.12,119Zm51.42,0c0-13.16-5.77-19.47-15-19.47s-15.18,6.31-15.18,19.47,5.78,19.47,15.18,19.47S460.54,132.15,460.54,119Z" />
        <path d="M486.19,119c0-22,13.83-36.25,35.58-36.25,19.07,0,32,10.6,33.84,27.66H534.8c-1.21-7.39-6.31-10.61-13-10.61-8.86,0-14.37,5.91-14.37,19.2s5.64,19.07,14.37,19.07c7,0,12.49-3.49,13.43-11.68H556c-1.48,17.86-15,28.87-34.24,28.87C500.15,155.25,486.19,141,486.19,119Z" />
        <path d="M591.86,125l-9.4,9.67v18.93H561.92V58h20.54v53.71l25.65-27.39h24l-26,26.85,27.53,42.43h-23.9Z" />
        <path d="M73.17,205.19h21v69.29h-21Zm.54-25.64h20v17.72h-20Z" />
        <path d="M100.16,252.46h20.41c.81,7.52,4.57,10.07,13.29,10.07,8.46,0,11.69-2.55,11.69-6.71s-2.55-6.18-12-7.66l-9-1.48c-14.64-2.28-23.36-8.86-23.36-21.21,0-13.83,10.87-21.89,31.15-21.89,23.36,0,31.55,10.21,32.22,23.77H145.14c-.27-7.52-5.23-9.8-12.75-9.8s-10.61,2.55-10.61,6.58,3.36,5.9,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.21,22.42-33,22.42C112.25,276.49,101.37,269.65,100.16,252.46Z" />
        <path d="M269.21,205.19l-25.11,72c-6,17.45-14,23.76-34.51,23.76-4,0-6-.13-8.19-.27V284.55h6.71c7.79,0,10.48-4.43,10.48-9.67,0-3.89-1.62-8.86-3.23-13.42l-19.87-56.27h22.56l3.09,9.81c4,13.56,8.05,27.79,11.81,43.64,3.76-16,7.66-30.08,11.55-43.51l3.09-9.94Z" />
        <path d="M267.46,239.84c0-22,14.23-36.26,36.39-36.26s36.26,14.23,36.26,36.26-14.24,36.25-36.26,36.25S267.46,261.86,267.46,239.84Zm51.43,0c0-13.16-5.78-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S318.89,253,318.89,239.84Z" />
        <path d="M412.07,205.19v69.29H391.66v-9.8c-4.56,6.44-11.54,11.41-21.75,11.41-13.69,0-24-8.32-24-25.51V205.19h20.94v41.23c0,7.65,3.36,11.81,11.15,11.81,6.44,0,13.16-4.16,13.16-14.37V205.19Z" />
        <path d="M466.86,204.39v19.06h-5.37c-11.69,0-18.4,5.78-18.4,18.8v32.23H422.14V205.19h20.41v12.89c3-8.59,9.4-14,19.74-14C463.9,204.12,465.38,204.25,466.86,204.39Z" />
        <path d="M468.46,252.46h20.41c.81,7.52,4.57,10.07,13.3,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-12-7.66l-9-1.48c-14.63-2.28-23.36-8.86-23.36-21.21,0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.21,32.23,23.77H513.45c-.27-7.52-5.24-9.8-12.76-9.8s-10.61,2.55-10.61,6.58,3.36,5.9,10.07,7l10.34,1.48c16.38,2.55,24.31,9.13,24.31,21.48,0,14.37-12.22,22.42-33,22.42C480.55,276.49,469.67,269.65,468.46,252.46Z" />
        <path d="M539,251.38h23.63v23.1H539Z" />
      </g>
    </g>
  </svg>
);

FooterBlock.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterBlock;
