import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderUnderline1 from 'images/header/header-underline1.png';
import patternHeaderUnderline2 from 'images/header/header-underline2.png';
import patternHeaderUnderline3 from 'images/header/header-underline3.png';
import patternHeaderUnderline4 from 'images/header/header-underline4.png';
import patternHeaderUnderline5 from 'images/header/header-underline5.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderUnderline = ({ style, isVisible }) => (
  <>
    <svg
      className="header-underline md:hidden"
      viewBox="0 0 352 41.13"
      style={style}
    >
      <defs>
        <pattern
          id="m-headerunderline-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 691 53"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline1}
            width="691"
            height="53"
          />
        </pattern>
        <pattern
          id="m-headerunderline-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 624 44"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline2}
            width="624"
            height="44"
          />
        </pattern>
        <pattern
          id="m-headerunderline-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 603 38"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline3}
            width="603"
            height="38"
          />
        </pattern>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headerunderline-1)"
          strokeWidth="20px"
          d="M1,25.23C127.75,4.42,245.45-.9,350,16.23"
        />
        <Path
          fill="none"
          stroke="url(#m-headerunderline-2)"
          strokeWidth="20px"
          d="M25.8,31.75C136.7,10.8,241.27,8.07,340,22.23"
        />
        <Path
          fill="none"
          stroke="url(#m-headerunderline-3)"
          strokeWidth="20px"
          d="M49,35.23c99.34-18.37,199.79-22.77,302-5"
        />
      </G>
    </svg>
    <svg
      className="header-underline hidden md:block"
      viewBox="0 0 955.46 293.68"
      style={style}
    >
      <defs>
        <pattern
          id="headerunderline-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 691 53"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline1}
            width="691"
            height="53"
          />
        </pattern>
        <pattern
          id="headerunderline-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 624 44"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline2}
            width="624"
            height="44"
          />
        </pattern>
        <pattern
          id="headerunderline-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 603 38"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline3}
            width="603"
            height="38"
          />
        </pattern>
        <pattern
          id="headerunderline-4"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 590 399"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline4}
            width="590"
            height="399"
          />
        </pattern>
        <pattern
          id="headerunderline-5"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 115 95"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderUnderline5}
            width="115"
            height="95"
          />
        </pattern>
        {/* <clipPath id="headerarrows-clip-3">
          <path
            transform="scale(1.03) translate(-22 -0)"
            d="M775.63,11c9.29.79,14.59,7,15.71,16.65-1.5,8.24-6.27,13-16.28,14.93-8.41-1.86-15.49-5.69-16.71-13.67C759.92,19.8,765.55,14.41,775.63,11Z"
          />
        </clipPath> */}
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headerunderline-1)"
          strokeWidth="20px"
          d="M295.44,124.68c126.78-20.81,244.48-26.13,349-9"
        />
        <Path
          fill="none"
          stroke="url(#headerunderline-2)"
          strokeWidth="20px"
          d="M320.27,131.2c110.9-20.95,215.47-23.68,314.17-9.52"
        />
        <Path
          fill="none"
          stroke="url(#headerunderline-3)"
          strokeWidth="20px"
          d="M343.44,134.68c99.34-18.37,199.79-22.77,302-5"
        />
        <Path
          fill="none"
          stroke="url(#headerunderline-4)"
          strokeWidth="60px"
          d="M660.44,69.68c14.71-21.93,41.53-29.44,75-29C836.6,53.06,900.9,126.48,932.07,254.75"
        />
        <Path
          fill="none"
          stroke="url(#headerunderline-5)"
          strokeWidth="15px"
          transform="translate(-5, -22)"
          d="M954.44,240.68c-13.8,2.38-19.53,16.35-22,35-10.59-17.92-21.3-34.5-33-40"
        />
      </G>
    </svg>
  </>
);

HeaderUnderline.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderUnderline;
