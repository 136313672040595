import nanostyled from 'nanostyled';

export const Container = nanostyled('section', {
  base: 'max-w-4xl mx-auto typography a-no-border',
  color: '',
  margin: 'my-16 sm:my-32',
});

export const Margin = nanostyled('div', {
  base: 'mx-4 xs:mx-10 sm:mx-20',
});

export const StyledForm = nanostyled('form', {
  base: 'w-full max-w-3xl',
});

export const StyledInput = nanostyled('input', {
  base:
    'inline-block p-4 border-solid border-brand-gray focus:border-black w-full border-2 mt-6 text-lg focus:outline-none rounded-none',
  width: '',
  border: '',
});

export const Submit = nanostyled('input', {
  base:
    'inline-block bg-transparent p-4 border-solid border-black border-2 mt-6 font-extrabold max-w-sm leading-tight text-lg md:text-2xl hover:bg-black hover:text-white',
  width: '',
});

export const StyledIconLink = nanostyled('a', {
  base: 'text-xl hover:text-black flex-shrink-0 mr-4 inline-block',
  size: '',
  color: '',
});

export const IconWrapper = nanostyled('div', {
  base: 'mr-6 flex-shrink-0 mb-6 xl:mb-2 relative',
  margin: '',
});

export const Flex = nanostyled('div', {
  base: 'flex flex-col md:flex-row',
  flex: '',
});
