import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import Button from 'components/blocks/button';
import IOMenu from 'containers/io-menu';

import { Container, Background, Type, Margin } from './about-our-process.css';

export const AboutOurProcess = ({ pageColor }) => {
  const { fiveSteps, road } = useStaticQuery(graphql`
    query aboutProcessImages {
      fiveSteps: file(relativePath: { eq: "about/5-steps.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      road: file(relativePath: { eq: "about/road.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <IOMenu withImage={true}>
      <Background bg={`bg-brand-${pageColor}`}>
        <Margin>
          <Container>
            <Type>
              <h2>Our Process</h2>
              <h3>
                {
                  // eslint-disable-next-line quotes
                  'At NDC, our community partners are our experts. Since 1968, we’ve practiced co-design, a process built on participatory decision making at all levels.'
                }
              </h3>
              {fiveSteps && <Image fluid={fiveSteps.childImageSharp.fluid} />}{' '}
              <h2>Collaborative Design</h2>
              <h3>
                {
                  'We bring everyone to the table. The more inclusive the process, the better the design, the stronger the buy-in, and the longer-lasting the project.'
                }
              </h3>
              {road && <Image fluid={road.childImageSharp.fluid} />}{' '}
              <h3>{'Is your organization ready to get started?'}</h3>
            </Type>

            <div className="text-center mt-8 sm:mt-16">
              <Button
                text="Our Services"
                link="/our-services"
                isInternal={true}
                isInline
              />
              <Button
                text="Work With Us"
                link="/work-with-us"
                isInternal={true}
                isInline
              />
            </div>
          </Container>
        </Margin>
      </Background>
    </IOMenu>
  );
};

AboutOurProcess.propTypes = {
  pageColor: PropTypes.string,
};

export default AboutOurProcess;
