import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import IOMenu from 'components/io-menu';

const IOMenuContainer = ({ children, withImage }) => {
  return (
    <Consumer>
      {({ setMenuColor }) => (
        <IOMenu setMenuColor={setMenuColor} withImage={withImage}>
          {children}
        </IOMenu>
      )}
    </Consumer>
  );
};

IOMenuContainer.propTypes = {
  children: PropTypes.node.isRequired,
  withImage: PropTypes.bool,
};

export default IOMenuContainer;
