import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';

import { Container, StyledImage, Margin } from './slideshow.css';
import { CaptionMargin, Caption } from 'components/blocks/image/image.css';

import PreviousIcon from 'images/large-prev.svg';
import NextIcon from 'images/large-next.svg';

const getWidth = width => {
  switch (width) {
    case 'Text Width':
      return 'max-w-4xl';
    case 'Extra Wide':
      return 'max-w-6xl';
    case 'Full Bleed':
      return 'max-w-screen';
    default:
      return 'max-w-4xl';
  }
};

const getMargin = width => {
  switch (width) {
    case 'Text Width':
      return 'mx-4 xs:mx-10 sm:mx-20';
    case 'Extra Wide':
      return 'xs:mx-4 sm:mx-10';
    case 'Full Bleed':
      return 'sm:mx-20';
    default:
      return 'mx-4 xs:mx-10 sm:mx-20';
  }
};

const Slideshow = ({ list, blockWidth, pageColor, prev, next }) => {
  const [index, changeIndex] = useState(0);
  return (
    <Margin margin={getMargin(blockWidth)}>
      <Container
        width={getWidth(blockWidth)}
        color={pageColor}
        margin={`${next ? 'mb-10 sm:mb-20' : ''} ${
          prev ? 'mt-10 sm:mt-20' : ''
        }`}
      >
        <Carousel
          heightMode="first"
          dragging={false}
          wrapAround
          afterSlide={slideIndex => {
            changeIndex(slideIndex);
          }}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide}>
              <PreviousIcon
                className={`stroke-current w-12 hidden sm:block text-brand-${pageColor}`}
              />
            </button>
          )}
          renderBottomLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide}>
              <PreviousIcon
                className={`stroke-current ml-2 w-8 sm:hidden text-brand-${pageColor}`}
              />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide}>
              <NextIcon
                className={`stroke-current w-12 hidden sm:block text-brand-${pageColor}`}
              />
            </button>
          )}
          renderBottomRightControls={({ nextSlide }) => (
            <button onClick={nextSlide}>
              <NextIcon
                className={`stroke-current mr-2 w-8 sm:hidden text-brand-${pageColor}`}
              />
            </button>
          )}
          renderCenterCenterControls={({ nextSlide }) => (
            <button className="w-full h-full" onClick={nextSlide} />
          )}
        >
          {list &&
            list.map(({ image, id }) => (
              <StyledImage key={id} fluid={image.fluid} />
            ))}
        </Carousel>
        {list[index].description && (
          <CaptionMargin
            margin={blockWidth === 'Full Bleed' && 'mx-4 xs:mx-10 sm:mx-20'}
          >
            <Caption>{list[index].description}</Caption>
          </CaptionMargin>
        )}
      </Container>
    </Margin>
  );
};

Slideshow.propTypes = {
  list: PropTypes.array.isRequired,
  blockWidth: PropTypes.string,
  pageColor: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
};

export default Slideshow;
