import React from 'react';
import PropTypes from 'prop-types';

import IO from './io';
import { Container } from './io-menu.css';

class IOMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { setMenuColor, children, withImage } = this.props;

    return (
      <IO
        id="io-menu"
        key="io-menu"
        rootMargin="-5% 0px -95% 0px"
        funcIsVisible={() => {
          setMenuColor(withImage ? 'white' : 'black');
        }}
        funcIsNotVisible={() => {
          setMenuColor('black');
        }}
      >
        {() => <Container>{children}</Container>}
      </IO>
    );
  }
}

IOMenu.propTypes = {
  children: PropTypes.node,
  setMenuColor: PropTypes.func,
  withImage: PropTypes.bool,
};

export default IOMenu;
