import nanostyled from 'nanostyled';

export const Container = nanostyled('div', {
  base: 'w-full max-w-5xl',
  margin: 'mx-auto',
});

export const Flex = nanostyled('div', {
  base: 'flex',
  flex: '',
});

export const Margin = nanostyled('div', {
  base: 'mx-4 xs:mx-10 sm:mx-20',
});

export const VolunteerItem = nanostyled('div', {
  base:
    'border-solid border-2 w-full sm:w-1/2 px-4 xs:px-10 py-8 sm:py-16 -m-px typography',
  color: '',
});

export const Title = nanostyled('h3', {
  base: '!text-black',
});

export const Submit = nanostyled('input', {
  base:
    'inline-block bg-transparent p-4 border-solid border-black border-2 mt-6 font-extrabold max-w-sm leading-tight text-lg md:text-2xl hover:bg-black hover:text-white',
  width: '',
});
