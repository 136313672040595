import nanostyled from 'nanostyled';
import Img from 'gatsby-image';

export const Container = nanostyled('section', {
  base: 'w-full typography',
  align: 'mx-auto',
  width: 'max-w-5xl',
  margin: '',
});

export const Flex = nanostyled('div', {
  base: 'flex',
  order: '',
});

export const StyledImage = nanostyled(Img, {
  base: '',
});

export const Margin = nanostyled('div', {
  base: '',
  margin: '',
});
