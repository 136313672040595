import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { filter, includes } from 'lodash';

import {
  Center,
  Container,
  ImageLink,
  ImageLinkWrapper,
  StyledImg,
  Overlay,
  TextContainer,
  Text,
  Icon,
} from './ourwork-list.css';

const OurworkList = ({ items, filterCaseStudies }) => (
  <Center>
    <Container>
      {filter(items, item => {
        if (filterCaseStudies == null) {
          return true;
        }
        return includes(item.categories, filterCaseStudies);
      }).map(
        ({ id, title, slug, thumbnailImage, projectIcon, pageColor }, i) => (
          <ImageLinkWrapper key={id + i}>
            <ImageLink to={`case-study/${slug}`}>
              {thumbnailImage && (
                <StyledImg
                  fluid={thumbnailImage.fluid}
                  style={{ position: 'absolute' }}
                />
              )}
              <Overlay
                color={`bg-brand-${slugify(pageColor, { lower: true })}`}
              />
              <TextContainer>
                {projectIcon && <Icon fluid={projectIcon.fluid} />}
                <Text>{title}</Text>
              </TextContainer>
            </ImageLink>
          </ImageLinkWrapper>
        )
      )}
    </Container>
  </Center>
);

OurworkList.propTypes = {
  items: PropTypes.array.isRequired,
  filterCaseStudies: PropTypes.string,
};

export default OurworkList;
