import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { castArray } from 'lodash';
import { Helmet } from 'react-helmet';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import Layout from 'components/layout';
import JournalPostHeader from 'components/journal-post-header';
import JournalPostNav from 'components/journal-post-nav';
import PageContent from 'components/page-content';

const JournalPostTemplate = ({ data, pageContext }) => {
  const {
    title,
    type,
    excerpt,
    date,
    thumbnailImage,
    content,
  } = data.contentfulJournalPost;
  const { previous, next } = pageContext;

  const pageTitleFull = title
    ? `${title} | The Neighborhood Design Center`
    : 'The Neighborhood Design Center';
  const fullDescription =
    (excerpt && excerpt.excerpt.substring(0, 155)) ||
    (content &&
      content.json &&
      documentToPlainTextString(content.json).substring(0, 155)) ||
    null;
  const imageUrl =
    (thumbnailImage && `https:${thumbnailImage.fixed.src}`) || null;

  return (
    <Layout>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>
      <div className="bg-white boundary">
        <JournalPostHeader
          title={title}
          type={castArray(type)}
          date={date}
          pageColor="blue"
          previous={previous}
          next={next}
        />
        <PageContent margin="my-0 sm:my-0" content={content} pageColor="blue" />
        <JournalPostNav pageColor="blue" previous={previous} next={next} />
      </div>
    </Layout>
  );
};

JournalPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default JournalPostTemplate;

export const query = graphql`
  query JournalPostBySlug($slug: String!) {
    contentfulJournalPost(slug: { eq: $slug }) {
      id
      title
      published
      type
      date
      thumbnailImage {
        fixed(width: 1024, height: 512) {
          src
        }
      }
      content {
        id
        json
      }
    }
  }
`;
