import nanostyled from 'nanostyled';
import { Link } from 'gatsby';

export const Container = nanostyled('footer', {
  base:
    'fixed bg-brand-yellow bottom-0 w-full h-screen sm:h-3/4vh md:h-2/3 z-0',
  flex: 'flex justify-center items-end sm:items-center',
});

export const Wrapper = nanostyled('div', {
  base: 'w-full max-w-6xl flex flex-col h-full max-h-2xl sm:max-h-full',
});

export const Slogan = nanostyled(Link, {
  base: 'm-12 flex justify-center items-center h-full relative',
});

export const Links = nanostyled('div', {
  base: 'flex px-12 mx-auto mb-10 max-w-2xl lg:max-w-7xl flex-col xl:flex-row',
  flex:
    'flex justify-between items-center md:items-stretch xl:items-end flex-shrink-0',
});

export const StyledLink = nanostyled(Link, {
  base:
    'text-xl uppercase font-extrabold hover:text-white flex-shrink-0 md:mr-10 mb-6 md:mb-0 block',
  border: 'border-solid border-white border-b-3 pb-1',
});

export const StyledA = nanostyled('a', {
  base:
    'text-xl uppercase font-extrabold hover:text-white flex-shrink-0 md:mr-10 mb-6 md:mb-0 block',
  border: 'border-solid border-white border-b-3 pb-1',
});

export const StyledIconLink = nanostyled('a', {
  base: 'text-xl hover:text-white flex-shrink-0 inline-block mr-4',
});

export const IconWrapper = nanostyled('div', {
  base: 'ml-4 md:ml-0 md:mr-6 flex-shrink-0 mb-6 xl:mb-2',
});

export const LinksWrapper = nanostyled('div', {
  base:
    'flex w-auto flex-shrink-0 flex-col md:flex-row items-center md:items-between lg:items-end mb-6 lg:mb-0',
});

export const TextWrapper = nanostyled('div', {
  base: 'flex w-full flex-col lg:flex-row',
});

export const Search = nanostyled('div', {
  base: 'flex-grow relative',
  border: 'border-solid border-black border-b-2',
});

export const SearchInput = nanostyled('input', {
  base:
    'bg-transparent text-xl w-full pt-2 pb-1 pl-2 pr-8 search-footer border-t-2 border-l-2 border-r-2 border-transparent outline-none focus:border-black',
  border: '',
});

export const Submit = nanostyled('button', {
  base:
    'bg-transparent text-xl hover:text-white absolute right-0 top-0 mr-2 h-full flex items-center',
});

export const Credit = nanostyled(Link, {
  base:
    'text-xs uppercase font-micro font-bold fixed tracking-wide opacity-35 transition duration-200 ease-in-out hover:opacity-100 rotate bottom-0 right-0 mr-4 mb-8',
});
