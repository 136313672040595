import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternWorkwithus1 from 'images/homepage/workwithus-1.png';
import patternWorkwithus2 from 'images/homepage/workwithus-2.png';
import patternWorkwithus3 from 'images/homepage/workwithus-3.png';
import patternWorkwithus4 from 'images/homepage/workwithus-4.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    delay: 300,
    pathLength: 100,
    transition: ({ dur = 300 }) => ({
      duration: dur,
    }),
  },
});

const Rect1 = posed.rect({
  from: { width: 0 },
  to: {
    width: 374,
    transition: ({ dur = 300 }) => ({
      duration: dur,
    }),
  },
});

const Rect2 = posed.rect({
  from: { width: 0 },
  to: {
    delay: 600,
    width: 176,
    transition: ({ dur = 300 }) => ({
      duration: dur,
    }),
  },
});

const Rect3 = posed.rect({
  from: {},
  to: {
    transition: ({ dur = 300 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 300 }) => dur,
  },
});

const HomepageWorkwithus = ({ style, isVisible }) => (
  <svg viewBox="0 0 488.06 148.53" style={style}>
    <defs>
      <pattern
        id="workwithus-1"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 1087 39"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternWorkwithus1}
          width="1087"
          height="39"
        />
      </pattern>
      <pattern
        id="workwithus-2"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 140 177"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternWorkwithus2}
          width="140"
          height="177"
        />
      </pattern>
      <pattern
        id="workwithus-3"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 512 39"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternWorkwithus3}
          width="512"
          height="39"
        />
      </pattern>
      <pattern
        id="workwithus-4"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 153 206"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternWorkwithus4}
          width="153"
          height="206"
        />
      </pattern>
      <clipPath id="workwithus-arrow1">
        <path
          transform="scale(1.06) translate(-22 -3)"
          d="M339.06,0l53.45,31.22-1.89,7.57-52,42.1-4.73-11.83Z"
        />
      </clipPath>
      <clipPath id="workwithus-arrow2">
        <path
          transform="scale(1.06) translate(-26 -6)"
          d="M437.92,70.48l49.67,42.1.47,11.35-46.35,24.6L435.08,140l-3.31-61Z"
        />
      </clipPath>
      <clipPath id="workwithus-rect1-animate">
        <Rect1
          pose={isVisible ? 'to' : 'from'}
          width="374"
          height="20"
          x="0"
          y="27.35"
        />
      </clipPath>
      <clipPath id="workwithus-rect2-animate">
        <Rect2
          pose={isVisible ? 'to' : 'from'}
          width="176"
          height="20"
          x="297.98"
          y="104.45"
        />
      </clipPath>
    </defs>
    <G pose={isVisible ? 'to' : 'from'}>
      <Rect3
        fill="url(#workwithus-1)"
        clipPath="url(#workwithus-rect1-animate)"
        width="374"
        height="20"
        x="0"
        y="27.35"
      />
      <Path
        fill="transparent"
        stroke="url(#workwithus-2)"
        strokeWidth="36px"
        clipPath="url(#workwithus-arrow1)"
        d="M339.06,0l53.45,31.22-1.89,7.57-52,42.1-4.73-11.83Z"
      />

      <Rect3
        fill="url(#workwithus-3)"
        clipPath="url(#workwithus-rect2-animate)"
        width="176"
        height="20"
        x="297.98"
        y="104.45"
      />
      <Path
        fill="transparent"
        stroke="url(#workwithus-4)"
        strokeWidth="36px"
        clipPath="url(#workwithus-arrow2)"
        d="M437.92,70.48l49.67,42.1.47,11.35-46.35,24.6L435.08,140l-3.31-61Z"
      />
    </G>
  </svg>
);

HomepageWorkwithus.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HomepageWorkwithus;
