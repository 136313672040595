import React from 'react';
import PropTypes from 'prop-types';
import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import { Container, Flex, Location, Margin, Center } from './contact-ndc.css';

class ContactNdc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      office1Html: '',
      office2Html: '',
    };

    this.handleMdToHtml = this.handleMdToHtml.bind(this);
  }

  handleMdToHtml(md, state) {
    const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, next) =>
          `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
      },
    };
    richTextFromMarkdown(md)
      .then(results => {
        return results;
      })
      .then(results => documentToHtmlString(results, options))
      .then(results => {
        this.setState({ [state]: results });
      });
  }

  componentDidMount() {
    this.handleMdToHtml(this.props.office1, 'office1Html');
    this.handleMdToHtml(this.props.office2, 'office2Html');
  }

  render() {
    return (
      <Margin>
        <Center>
          <span className="scroll-offset" id="contact-ndc" />
          <Container
            margin={`${this.props.next ? 'mb-16 sm:mb-32' : ''} ${
              this.props.prev ? 'mt-16 sm:mt-32' : ''
            }`}
          >
            <h2>{this.props.title}</h2>
            <Flex color={this.props.pageColor}>
              <Location
                dangerouslySetInnerHTML={{
                  __html: this.state.office1Html,
                }}
              />
              <Location
                dangerouslySetInnerHTML={{
                  __html: this.state.office2Html,
                }}
              />
            </Flex>
          </Container>
        </Center>
      </Margin>
    );
  }
}

ContactNdc.propTypes = {
  title: PropTypes.string.isRequired,
  office1: PropTypes.object.isRequired,
  office2: PropTypes.object.isRequired,
  pageColor: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
};

export default ContactNdc;
