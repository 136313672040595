import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import urlParse from 'helpers/urlParse';
import IOWorkwithus from 'components/io-workwithus';
import HomepageWorkwithus from 'components/draw-in-svg/homepage-workwithus.js';

import {
  Container,
  Left,
  Right,
  ImageLink,
  ImageA,
  StyledImg,
  Overlay,
  Text,
} from './index-links-section.css';

const opt = {
  renderText: text => {
    if (text.slice(0, 13) === 'Work with us!') {
      return [text.slice(0, 13), <br key="split" />, text.slice(13)];
    } else {
      return text;
    }
  },
};

const IndexLinksSection = ({ text, links }) => (
  <IOWorkwithus>
    {({ hasBeenVisible }) => (
      <Container>
        <Left className="typography large">
          <div className="max-w-xl relative">
            <div className="hidden sm:block">
              <HomepageWorkwithus
                isVisible={hasBeenVisible}
                style={{
                  maxWidth: '31.25rem',
                  width: '100%',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  zIndex: '0',
                  transform: 'translate(25%, -50%)',
                }}
              />
            </div>
            <div className="relative">
              {text && documentToReactComponents(text.json, opt)}
            </div>
          </div>
        </Left>

        <Right>
          {links.map(({ id, text, link, backgroundImage }, i) =>
            urlParse(link).isInternal ? (
              <ImageLink
                key={id}
                to={urlParse(link).url}
                border={
                  i % 2 == 0
                    ? i < links.length - 2
                      ? 'border-b-2 xxl:border-r-2'
                      : 'border-b-2 xxl:border-b-0 xxl:border-r-2'
                    : i < links.length - 1 && 'border-b-2'
                }
              >
                <StyledImg
                  fluid={backgroundImage.fluid}
                  style={{ position: 'absolute' }}
                />
                <Overlay />
                <Text>{text}</Text>
              </ImageLink>
            ) : (
              <ImageA
                key={id}
                href={urlParse(link).url}
                border={
                  i % 2 == 0
                    ? i < links.length - 2
                      ? 'border-b-2 xxl:border-r-2'
                      : 'border-b-2 xxl:border-b-0 xxl:border-r-2'
                    : i < links.length - 1 && 'border-b-2'
                }
              >
                <StyledImg
                  fluid={backgroundImage.fluid}
                  style={{ position: 'absolute' }}
                />
                <Overlay />
                <Text>{text}</Text>
              </ImageA>
            )
          )}
        </Right>
      </Container>
    )}
  </IOWorkwithus>
);

IndexLinksSection.propTypes = {
  text: PropTypes.object,
  links: PropTypes.array,
};

export default IndexLinksSection;
