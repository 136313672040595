import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern from 'images/footer/footer-city-drawing.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 1000 }) => ({
      duration: dur,
    }),
  },
});

const FooterCity = ({ style, footerOpen }) => (
  <svg viewBox="0 0 570.02 330.21" height="330.21" style={style}>
    <defs>
      <style>
        {
          '.fde1d7f0-5cde-44e4-bce2-601685895b69{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;letter-spacing:-0.02em;}.abcd503c-721e-46c4-97be-ad777e7fcf85{letter-spacing:-0.01em;}.ff5e3ad1-aabe-4c89-9b76-d408d65bc6ad{letter-spacing:-0.04em;}.aa40b392-f435-4c51-8afa-310200fc5456{letter-spacing:-0.03em;}.af27948d-9815-45ae-aa25-3a875407480b{letter-spacing:-0.02em;}.aa3b9991-d965-4045-9f86-ba95a4d4c3c3,.aba7e32a-72f3-469a-83dd-62b5fd6f8875{fill:none;stroke:#fff;stroke-miterlimit:10;}.aba7e32a-72f3-469a-83dd-62b5fd6f8875{stroke-width:1.04px;}'
        }
      </style>
      <pattern
        id="footer-city"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 478.377 207.647"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern}
          width="478.377"
          height="207.647"
        />
      </pattern>
      <clipPath id="city-cut-off">
        <path d="M87.1,109.39c12.05-35.17,47.46-62.23,106.65-81C256,6.23,325.3-1.57,401.78,1c90.78,10.53,149.49,33.68,163.69,82.43C578.15,158,514,185.1,434.41,200.76,276.49,212.74,185,192.62,172.7,140,174,92.73,199.31,52.13,297.23,28.74,356.74,12.94,409.4,17.59,454,36.06" />
      </clipPath>
    </defs>
    <g>
      <Path
        pose={footerOpen ? 'to' : 'from'}
        fill="none"
        stroke="url(#footer-city)"
        strokeWidth="38px"
        clipPath="url(#city-cut-off)"
        d="M89.43,110.35c11.93-34.83,47-61.62,105.61-80.24C256.68,8.19,325.32.47,401.06,3c89.9,10.42,148,33.35,162.1,81.62,12.55,73.84-50.95,100.71-129.79,116.22-156.39,11.86-247-8.06-259.18-60.18,1.32-46.8,26.36-87,123.33-110.17,58.93-15.64,111.08-11,155.28,7.25"
      />
      <g data-name="outlined-text">
        <path d="M32.76,109.47v32.36c0,5.1,2.55,6,7.93,6h5.37v15.58a79.87,79.87,0,0,1-9.67.53c-17.32,0-24.57-5.64-24.57-19.87V109.47H1.48V94.16H11.82V73.61H32.76V94.16h13.3v15.31Z" />
        <path d="M119.1,118.06v45.39H98.15V122.22c0-7.65-3.35-11.81-11.14-11.81-6.45,0-13.16,4.16-13.16,14.36v38.68H52.9V67.84H73.85v35.45A25.52,25.52,0,0,1,95.07,92.55C108.76,92.55,119.1,100.87,119.1,118.06Z" />
        <path d="M197.79,135h-51.3c1.48,9.94,7.65,14.64,15.85,14.64,7,0,11.54-2.82,14-7.79h19.87c-3.76,14.63-17.18,23.23-33.83,23.23-21.49,0-36-14.24-36-36.26s14.1-36.25,35.72-36.25,35.72,14.9,35.72,38.27Zm-51.17-13.29h30.62c-1.48-8.6-7-13.7-15.44-13.7C154.14,108,148.24,112,146.62,121.69Z" />
        <path d="M232,128.8c0-22,13.83-36.25,35.58-36.25,19.07,0,32,10.61,33.84,27.66H280.63c-1.21-7.39-6.31-10.61-13-10.61-8.86,0-14.36,5.91-14.36,19.2s5.64,19.07,14.36,19.07c7,0,12.49-3.49,13.43-11.68h20.81c-1.47,17.86-15,28.87-34.24,28.87C246,165.06,232,150.82,232,128.8Z" />
        <path d="M307.75,94.16H328.7v69.29H307.75Zm.54-25.65h20V86.24h-20Z" />
        <path d="M365.62,109.47v32.36c0,5.1,2.55,6,7.92,6h5.37v15.58a79.6,79.6,0,0,1-9.66.53c-17.32,0-24.58-5.64-24.58-19.87V109.47H334.33V94.16h10.34V73.61h20.95V94.16h13.29v15.31Z" />
        <path d="M453.57,94.16l-25.11,72c-6,17.46-14,23.77-34.51,23.77-4,0-6-.14-8.19-.27V173.52h6.71c7.79,0,10.48-4.43,10.48-9.67,0-3.9-1.61-8.86-3.23-13.43L379.85,94.16h22.56l3.09,9.8c4,13.56,8.06,27.8,11.82,43.64,3.76-16,7.65-30.08,11.54-43.5L432,94.16Z" />
        <path d="M6,215H27v69.29H6Zm.54-25.64h20v17.72h-20Z" />
        <path d="M33,262.27H53.44c.81,7.52,4.57,10.07,13.29,10.07,8.46,0,11.69-2.55,11.69-6.71s-2.55-6.18-12-7.66l-9-1.47c-14.64-2.29-23.36-8.87-23.36-21.22,0-13.83,10.87-21.89,31.15-21.89,23.36,0,31.55,10.21,32.22,23.77H78c-.27-7.52-5.23-9.8-12.75-9.8s-10.61,2.55-10.61,6.58,3.36,5.91,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.43-33,22.43C45.12,286.31,34.24,279.46,33,262.27Z" />
        <path d="M202.08,215,177,287c-6,17.45-14,23.76-34.51,23.76-4,0-6-.13-8.19-.26V294.36H141c7.79,0,10.48-4.43,10.48-9.67,0-3.89-1.62-8.86-3.23-13.42L128.36,215h22.56l3.09,9.81c4,13.56,8.05,27.79,11.81,43.64,3.76-16,7.66-30.08,11.55-43.51l3.09-9.94Z" />
        <path d="M200.33,249.65c0-22,14.23-36.26,36.39-36.26S273,227.63,273,249.65s-14.23,36.25-36.25,36.25S200.33,271.67,200.33,249.65Zm51.43,0c0-13.16-5.78-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S251.76,262.81,251.76,249.65Z" />
        <path d="M345,215v69.29H324.54v-9.8C320,280.93,313,285.9,302.78,285.9c-13.69,0-24-8.32-24-25.51V215h20.94v41.23c0,7.65,3.36,11.81,11.15,11.81,6.44,0,13.16-4.16,13.16-14.36V215Z" />
        <path d="M399.73,214.2v19.07h-5.37c-11.69,0-18.4,5.77-18.4,18.79v32.23H355V215h20.41v12.89c3-8.59,9.4-14,19.74-14C396.77,213.93,398.25,214.06,399.73,214.2Z" />
        <path d="M401.33,262.27h20.41c.81,7.52,4.57,10.07,13.3,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-12-7.66l-9-1.47c-14.63-2.29-23.36-8.87-23.36-21.22,0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.21,32.23,23.77H446.32c-.27-7.52-5.24-9.8-12.76-9.8S423,229.91,423,233.94s3.36,5.91,10.07,7l10.34,1.48c16.38,2.55,24.31,9.13,24.31,21.48,0,14.37-12.22,22.43-33,22.43C413.42,286.31,402.54,279.46,401.33,262.27Z" />
        <path d="M471.83,261.2h23.63v23.09H471.83Z" />
      </g>
      <text
        id="e95b0f94-4d4c-483d-a239-58fc6bfa3021"
        className="font-display font-extrabold fde1d7f0-5cde-44e4-bce2-601685895b69"
        data-name="text-text"
        transform="translate(0 163.45)"
        fill="rgba(0,0,0,0.01)"
      >
        <tspan>the city </tspan>
        <tspan className="abcd503c-721e-46c4-97be-ad777e7fcf85">
          <tspan x="0" y="120.85">
            is{' '}
          </tspan>
          <tspan
            x="128.23"
            y="120.85"
            className="ff5e3ad1-aabe-4c89-9b76-d408d65bc6ad"
          >
            y
          </tspan>
          <tspan
            x="197.11"
            y="120.85"
            className="aa40b392-f435-4c51-8afa-310200fc5456"
          >
            o
          </tspan>
          <tspan x="272.7" y="120.85">
            u
          </tspan>
          <tspan
            x="348.97"
            y="120.85"
            className="af27948d-9815-45ae-aa25-3a875407480b"
          >
            r
          </tspan>
          <tspan x="399.45" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>
    </g>
  </svg>
);

FooterCity.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterCity;
