import nanostyled from 'nanostyled';

export const Root = nanostyled('div', {
  base: 'relative grid',
});

export const HitsWrapper = nanostyled('div', {
  base:
    'fixed right-0 top-0 bottom-0 z-51 p-4 mb-24 overflow-y-auto bg-brand-yellow',
  extra:
    'max-w-full md:max-w-2xl w-screen h-screen pt-8 pb-8 sm-short:pb-4 px-4 xs:px-10 sm:px-20 text-base xs:text-xl sm:text-2xl sm-short:text-xl overflow-y-auto',
  show: 'grid',
});

export const SearchWrapper = nanostyled('div', {
  base: 'flex-grow relative',
  border: 'border-solid border-black border-b-2',
});

export const SearchInput = nanostyled('input', {
  base:
    'bg-transparent text-xl w-full pt-2 pb-1 pl-2 pr-8 search-footer border-t-2 border-l-2 border-r-2 border-transparent outline-none focus:border-black',
  border: '',
});

export const Submit = nanostyled('button', {
  base:
    'bg-transparent text-xl hover:text-white absolute right-0 top-0 mr-2 h-full flex items-center',
});
