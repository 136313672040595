import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import Sticky from 'react-sticky-el';

import { isNumber } from 'lodash';

import homepage11 from 'images/homepage/homepage-1-1.png';
import homepage12 from 'images/homepage/homepage-1-2.png';
import homepage13 from 'images/homepage/homepage-1-3.png';
import homepage21 from 'images/homepage/homepage-2-1.png';
import homepage22 from 'images/homepage/homepage-2-2.png';
import homepage23 from 'images/homepage/homepage-2-3.png';
import homepage24 from 'images/homepage/homepage-2-4.png';
import homepage25 from 'images/homepage/homepage-2-5.png';
import homepage26 from 'images/homepage/homepage-2-6.png';
import homepage31 from 'images/homepage/homepage-3-1.png';
import homepage32 from 'images/homepage/homepage-3-2.png';
import homepage33 from 'images/homepage/homepage-3-3.png';
import homepage34 from 'images/homepage/homepage-3-4.png';
import homepage35 from 'images/homepage/homepage-3-5.png';
import homepage36 from 'images/homepage/homepage-3-6.png';
import homepage37 from 'images/homepage/homepage-3-7.png';
import homepage38 from 'images/homepage/homepage-3-8.png';
import homepage39 from 'images/homepage/homepage-3-9.png';
import homepage310 from 'images/homepage/homepage-3-10.png';
import homepage311 from 'images/homepage/homepage-3-11.png';
import homepage312 from 'images/homepage/homepage-3-12.png';
import homepage313 from 'images/homepage/homepage-3-13.png';
import homepage314 from 'images/homepage/homepage-3-14.png';

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const HomepageDrawInWrapper = ({ slideDrawing, active }) => (
  <Sticky
    topOffset={-1}
    bottomOffset={2000}
    boundaryElement=".slide-boundary"
    hideOnBoundaryHit={false}
    className="disable-transform relative homepage-draw"
    stickyStyle={{
      zIndex: active + 1,
      // opacity: 1,
    }}
    style={{
      zIndex: active + 1,
      position: 'fixed',
      background: 'rgba(255,0,0,0.0)',
      // opacity: 0,
    }}
  >
    {active == 0 && (
      <>
        <svg
          className="svg1 h-full w-full hidden lg:block"
          preserveAspectRatio="xMinYMax meet"
          viewBox="0 0 1069.2 304.5"
        >
          <defs>
            <pattern
              id="homepage11"
              patternUnits="objectBoundingBox"
              preserveAspectRatio="none"
              width="1"
              height="1"
              viewBox="0 0 566.8 302.6"
            >
              <image xlinkHref={homepage11} height="302.6" width="566.8" />
            </pattern>
            <pattern
              id="homepage12"
              patternUnits="objectBoundingBox"
              preserveAspectRatio="none"
              width="1"
              height="1"
              viewBox="0 0 203.4 85.8"
            >
              <image xlinkHref={homepage12} height="85.8" width="203.4" />
            </pattern>
            <pattern
              id="homepage13"
              patternUnits="objectBoundingBox"
              preserveAspectRatio="none"
              width="1"
              height="1"
              viewBox="0 0 137.2 108.6"
            >
              <image xlinkHref={homepage13} height="108.6" width="137.2" />
            </pattern>
            <clipPath id="cut-off-11">
              <path d="M134.8,42.2C243.6,7.6,354.4-5.1,473.7,71.6c39,68.7,12.2,107.8-31.8,139.2c-116.8,59.7-237,75.4-367.5,53.3 c-86.7-25.2-85.9-103.5-12.7-159.9c48.7-38.7,100.5-53.3,202-62.8" />
            </clipPath>
          </defs>
          <G
            dur={400}
            initialPose="from"
            pose={
              isNumber(slideDrawing) && slideDrawing == active ? 'to' : 'from'
            }
          >
            <Path
              dur={400}
              className="animate"
              stroke="url(#homepage11)"
              strokeWidth="30px"
              fill="transparent"
              clipPath="url(#cut-off-11)"
              d="M138.2,45.1c105.7-33.5,213.2-45.9,329,28.6c37.9,66.7,11.8,104.7-30.9,135.1 c-113.4,58-230.1,73.2-356.8,51.7C-4.7,236.2-3.9,160.1,67.1,105.4c47.3-37.6,97.6-51.7,196.2-61"
            />
            <Path
              dur={400}
              className="animate"
              stroke="url(#homepage12)"
              strokeWidth="30px"
              fill="transparent"
              d="M423.9,99.8C515,35.5,622.9,5,760.2,17.9C889.4,36.7,981.7,119.4,1016.6,275"
            />
            <Path
              dur={400}
              className="animate"
              stroke="url(#homepage13)"
              strokeWidth="20px"
              fill="transparent"
              d="M1055.4,189.5l-37.7,90.8l-77.6-62.6"
            />
          </G>
        </svg>
        <svg
          className="svg1 h-full w-full lg:hidden"
          preserveAspectRatio="xMinYMax meet"
          viewBox="0 0 566.8 304.5"
        >
          <defs>
            <pattern
              id="m-homepage11"
              patternUnits="objectBoundingBox"
              preserveAspectRatio="none"
              width="1"
              height="1"
              viewBox="0 0 566.8 302.6"
            >
              <image xlinkHref={homepage11} height="302.6" width="566.8" />
            </pattern>
            <clipPath id="m-cut-off-11">
              <path d="M134.8,42.2C243.6,7.6,354.4-5.1,473.7,71.6c39,68.7,12.2,107.8-31.8,139.2c-116.8,59.7-237,75.4-367.5,53.3 c-86.7-25.2-85.9-103.5-12.7-159.9c48.7-38.7,100.5-53.3,202-62.8" />
            </clipPath>
          </defs>
          <G
            dur={400}
            initialPose="from"
            pose={
              isNumber(slideDrawing) && slideDrawing == active ? 'to' : 'from'
            }
          >
            <Path
              dur={400}
              className="animate"
              stroke="url(#m-homepage11)"
              strokeWidth="30px"
              fill="transparent"
              clipPath="url(#m-cut-off-11)"
              d="M138.2,45.1c105.7-33.5,213.2-45.9,329,28.6c37.9,66.7,11.8,104.7-30.9,135.1 c-113.4,58-230.1,73.2-356.8,51.7C-4.7,236.2-3.9,160.1,67.1,105.4c47.3-37.6,97.6-51.7,196.2-61"
            />
          </G>
        </svg>
      </>
    )}

    {active == 1 && (
      <>
        <svg
          className="svg2 h-full w-full hidden lg:block"
          preserveAspectRatio="xMinYMax meet"
          viewBox="0 0 973.28 275.18"
        >
          <defs>
            <pattern
              id="homepage21"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 120.8 94.2"
            >
              <image xlinkHref={homepage21} height="94.2" width="120.8" />
            </pattern>
            <pattern
              id="homepage22"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 99 86"
            >
              <image xlinkHref={homepage22} height="86" width="99" />
            </pattern>
            <pattern
              id="homepage23"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 84.6 91.8"
            >
              <image xlinkHref={homepage23} height="91.8" width="84.6" />
            </pattern>
            <pattern
              id="homepage24"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 112.2 91.4"
            >
              <image xlinkHref={homepage24} height="91.4" width="112.2" />
            </pattern>
            <pattern
              id="homepage25"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 253.8 31.8"
            >
              <image xlinkHref={homepage25} height="31.8" width="253.8" />
            </pattern>
            <pattern
              id="homepage26"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 57.2 132.6"
            >
              <image xlinkHref={homepage26} height="132.6" width="57.2" />
            </pattern>
            <clipPath id="cut-off-21">
              <path d="M115.35 15.52 15.53 17.52 15.53 95.75" />
            </clipPath>
            <clipPath id="cut-off-22">
              <path d="M17.01 187.07 20.35 259.65 101.08 259.65" />
            </clipPath>
            <clipPath id="cut-off-23">
              <path d="M448.46 179.66 449.2 251.88 378.46 258.17" />
            </clipPath>
            <clipPath id="cut-off-24">
              <path d="M351.06 17.82 444.53 19.56 446.6 95.22" />
            </clipPath>
          </defs>
          <G
            dur={200}
            initialPose="from"
            pose={
              isNumber(slideDrawing) && slideDrawing == active ? 'to' : 'from'
            }
          >
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#homepage21)"
              clipPath="url(#cut-off-21)"
              d="M115.35 15.52 15.53 17.52 15.53 95.75"
            />
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#homepage22)"
              clipPath="url(#cut-off-22)"
              d="M17.01 187.07 20.35 259.65 101.08 259.65"
            />
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#homepage23)"
              clipPath="url(#cut-off-23)"
              d="M448.46 179.66 449.2 251.88 378.46 258.17"
            />
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#homepage24)"
              clipPath="url(#cut-off-24)"
              d="M351.06 17.82 444.53 19.56 446.6 95.22"
            />
            <Path
              dur={200}
              className="animate"
              stroke="url(#homepage25)"
              strokeWidth="30px"
              fill="transparent"
              d="M422,145c88.2-57.3,286.47-70.17,530.56-58.58"
            />
            <Path
              dur={200}
              className="animate"
              stroke="url(#homepage26)"
              strokeWidth="15px"
              fill="transparent"
              d="M905.6 30.1 944.13 89.95 901.5 142.43"
            />
          </G>
        </svg>
        <svg
          className="svg2 h-full w-full block lg:hidden"
          preserveAspectRatio="xMinYMax meet"
          viewBox="0 0 450 275.18"
        >
          <defs>
            <pattern
              id="m-homepage21"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 120.8 94.2"
            >
              <image xlinkHref={homepage21} height="94.2" width="120.8" />
            </pattern>
            <pattern
              id="m-homepage22"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 99 86"
            >
              <image xlinkHref={homepage22} height="86" width="99" />
            </pattern>
            <pattern
              id="m-homepage23"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 84.6 91.8"
            >
              <image xlinkHref={homepage23} height="91.8" width="84.6" />
            </pattern>
            <pattern
              id="m-homepage24"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 112.2 91.4"
            >
              <image xlinkHref={homepage24} height="91.4" width="112.2" />
            </pattern>
            <clipPath id="m-cut-off-21">
              <path d="M115.35 15.52 15.53 17.52 15.53 95.75" />
            </clipPath>
            <clipPath id="m-cut-off-22">
              <path d="M17.01 187.07 20.35 259.65 101.08 259.65" />
            </clipPath>
            <clipPath id="m-cut-off-23">
              <path d="M448.46 179.66 449.2 251.88 378.46 258.17" />
            </clipPath>
            <clipPath id="m-cut-off-24">
              <path d="M351.06 17.82 444.53 19.56 446.6 95.22" />
            </clipPath>
          </defs>
          <G
            dur={200}
            initialPose="from"
            pose={
              isNumber(slideDrawing) && slideDrawing == active ? 'to' : 'from'
            }
          >
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#m-homepage21)"
              clipPath="url(#m-cut-off-21)"
              d="M115.35 15.52 15.53 17.52 15.53 95.75"
            />
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#m-homepage22)"
              clipPath="url(#m-cut-off-22)"
              d="M17.01 187.07 20.35 259.65 101.08 259.65"
            />
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#m-homepage23)"
              clipPath="url(#m-cut-off-23)"
              d="M448.46 179.66 449.2 251.88 378.46 258.17"
            />
            <Path
              dur={200}
              className="animate"
              fill="transparent"
              strokeWidth="10px"
              stroke="url(#m-homepage24)"
              clipPath="url(#m-cut-off-24)"
              d="M351.06 17.82 444.53 19.56 446.6 95.22"
            />
          </G>
        </svg>
      </>
    )}

    {active == 2 && (
      <>
        <svg
          className="svg3 h-full w-full hidden lg:block"
          preserveAspectRatio="xMinYMax meet"
          viewBox="0 0 973.28 275.18"
        >
          <defs>
            <pattern
              id="homepage31"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 63 195"
            >
              <image xlinkHref={homepage31} width="63" height="195" />
            </pattern>
            <pattern
              id="homepage32"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 244 512"
            >
              <image xlinkHref={homepage32} width="244" height="512" />
            </pattern>
            <pattern
              id="homepage33"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 230 173"
            >
              <image xlinkHref={homepage33} width="230" height="173" />
            </pattern>
            <pattern
              id="homepage34"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 367 72"
            >
              <image xlinkHref={homepage34} width="367" height="72" />
            </pattern>
            <pattern
              id="homepage35"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 228 74"
            >
              <image xlinkHref={homepage35} width="228" height="74" />
            </pattern>
            <pattern
              id="homepage36"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 365 204"
            >
              <image xlinkHref={homepage36} width="365" height="204" />
            </pattern>
            <pattern
              id="homepage37"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 154 150"
            >
              <image xlinkHref={homepage37} width="154" height="150" />
            </pattern>
            <pattern
              id="homepage38"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 248 189"
            >
              <image xlinkHref={homepage38} width="248" height="189" />
            </pattern>
            <pattern
              id="homepage39"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 310 80"
            >
              <image xlinkHref={homepage39} width="310" height="80" />
            </pattern>
            <pattern
              id="homepage310"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 659 96"
            >
              <image xlinkHref={homepage310} width="659" height="96" />
            </pattern>
            <pattern
              id="homepage311"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 589 271"
            >
              <image xlinkHref={homepage311} width="589" height="271" />
            </pattern>
            <pattern
              id="homepage312"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 223 271"
            >
              <image xlinkHref={homepage312} width="223" height="271" />
            </pattern>
            <pattern
              id="homepage313"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 3375 660"
            >
              <image xlinkHref={homepage313} width="3375" height="660" />
            </pattern>
            <pattern
              id="homepage314"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 737 718"
            >
              <image xlinkHref={homepage314} width="737" height="718" />
            </pattern>
            <clipPath id="cut-off-314">
              <path
                transform="scale(1.10) translate (-82 -19)"
                d="M941.39,51.55c-12.13,25.27-.27,63.79,20,126.84-51.18-18.75-88.86-9.69-130.41,12"
              />
            </clipPath>
          </defs>
          <G
            dur={100}
            initialPose="from"
            pose={
              isNumber(slideDrawing) && slideDrawing == active ? 'to' : 'from'
            }
          >
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage31)"
              strokeWidth="10px"
              fill="transparent"
              d="M138.35,76.35,130.8,44.16"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage32)"
              strokeWidth="10px"
              fill="transparent"
              d="M55.9,6.66,96.43,92"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage33)"
              strokeWidth="10px"
              fill="transparent"
              d="M49.5,101.59,11.11,72.79"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage34)"
              strokeWidth="10px"
              fill="transparent"
              d="M2.57,148.51l60.26,10.14"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage35)"
              strokeWidth="10px"
              fill="transparent"
              d="M30.3,192.78l37.33-8"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage36)"
              strokeWidth="10px"
              fill="transparent"
              d="M23.37,258.9l60.26-33.59"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage37)"
              strokeWidth="10px"
              fill="transparent"
              d="M97.5,264.77,121,241.3"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage38)"
              strokeWidth="10px"
              fill="transparent"
              d="M405.31,267.6l-39.64-29.87"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage39)"
              strokeWidth="10px"
              fill="transparent"
              d="M468.85,225.78l-52.13-9.23"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage310)"
              strokeWidth="10px"
              fill="transparent"
              d="M502.53,177.45,395.54,165"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage311)"
              strokeWidth="10px"
              fill="transparent"
              d="M463.42,73.18l-96.66,42.36"
            />
            <Path
              dur={300}
              className="animate"
              stroke="url(#homepage312)"
              strokeWidth="10px"
              fill="transparent"
              d="M372.73,47.11,336.89,90.56"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage313)"
              strokeWidth="30px"
              fill="transparent"
              d="M411.83,129.11c227.1-93.87,416.77-94,555,34.76"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#homepage314)"
              strokeWidth="20px"
              fill="transparent"
              clipPath="url(#cut-off-314)"
              d="M941.39,51.55c-12.13,25.27-.27,63.79,20,126.84-51.18-18.75-88.86-9.69-130.41,12"
            />
          </G>
        </svg>
        <svg
          className="svg3 h-full w-full lg:hidden"
          preserveAspectRatio="xMinYMax meet"
          viewBox="0 0 523.28 275.18"
        >
          <defs>
            <pattern
              id="m-homepage31"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 63 195"
            >
              <image xlinkHref={homepage31} width="63" height="195" />
            </pattern>
            <pattern
              id="m-homepage32"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 244 512"
            >
              <image xlinkHref={homepage32} width="244" height="512" />
            </pattern>
            <pattern
              id="m-homepage33"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 230 173"
            >
              <image xlinkHref={homepage33} width="230" height="173" />
            </pattern>
            <pattern
              id="m-homepage34"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 367 72"
            >
              <image xlinkHref={homepage34} width="367" height="72" />
            </pattern>
            <pattern
              id="m-homepage35"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 228 74"
            >
              <image xlinkHref={homepage35} width="228" height="74" />
            </pattern>
            <pattern
              id="m-homepage36"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 365 204"
            >
              <image xlinkHref={homepage36} width="365" height="204" />
            </pattern>
            <pattern
              id="m-homepage37"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 154 150"
            >
              <image xlinkHref={homepage37} width="154" height="150" />
            </pattern>
            <pattern
              id="m-homepage38"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 248 189"
            >
              <image xlinkHref={homepage38} width="248" height="189" />
            </pattern>
            <pattern
              id="m-homepage39"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 310 80"
            >
              <image xlinkHref={homepage39} width="310" height="80" />
            </pattern>
            <pattern
              id="m-homepage310"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 659 96"
            >
              <image xlinkHref={homepage310} width="659" height="96" />
            </pattern>
            <pattern
              id="m-homepage311"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 589 271"
            >
              <image xlinkHref={homepage311} width="589" height="271" />
            </pattern>
            <pattern
              id="m-homepage312"
              patternUnits="objectBoundingBox"
              width="1"
              height="1"
              viewBox="0 0 223 271"
            >
              <image xlinkHref={homepage312} width="223" height="271" />
            </pattern>
          </defs>
          <G
            dur={100}
            initialPose="from"
            pose={
              isNumber(slideDrawing) && slideDrawing == active ? 'to' : 'from'
            }
          >
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage31)"
              strokeWidth="10px"
              fill="transparent"
              d="M138.35,76.35,130.8,44.16"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage32)"
              strokeWidth="10px"
              fill="transparent"
              d="M55.9,6.66,96.43,92"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage33)"
              strokeWidth="10px"
              fill="transparent"
              d="M49.5,101.59,11.11,72.79"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage34)"
              strokeWidth="10px"
              fill="transparent"
              d="M2.57,148.51l60.26,10.14"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage35)"
              strokeWidth="10px"
              fill="transparent"
              d="M30.3,192.78l37.33-8"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage36)"
              strokeWidth="10px"
              fill="transparent"
              d="M23.37,258.9l60.26-33.59"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage37)"
              strokeWidth="10px"
              fill="transparent"
              d="M97.5,264.77,121,241.3"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage38)"
              strokeWidth="10px"
              fill="transparent"
              d="M405.31,267.6l-39.64-29.87"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage39)"
              strokeWidth="10px"
              fill="transparent"
              d="M468.85,225.78l-52.13-9.23"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage310)"
              strokeWidth="10px"
              fill="transparent"
              d="M502.53,177.45,395.54,165"
            />
            <Path
              dur={100}
              className="animate"
              stroke="url(#m-homepage311)"
              strokeWidth="10px"
              fill="transparent"
              d="M463.42,73.18l-96.66,42.36"
            />
            <Path
              dur={300}
              className="animate"
              stroke="url(#m-homepage312)"
              strokeWidth="10px"
              fill="transparent"
              d="M372.73,47.11,336.89,90.56"
            />
          </G>
        </svg>
      </>
    )}
  </Sticky>
);

HomepageDrawInWrapper.propTypes = {
  slideDrawing: PropTypes.numbber,
  active: PropTypes.number,
};

export default HomepageDrawInWrapper;
