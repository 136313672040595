import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternParks1 from 'images/footer/footer-parks-drawing-1.png';
import patternParks2 from 'images/footer/footer-parks-drawing-2.png';
import patternParks3 from 'images/footer/footer-parks-drawing-3.png';
import patternParks4 from 'images/footer/footer-parks-drawing-4.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const FooterParks = ({ style, footerOpen }) => (
  <svg viewBox="0 0 799.88 368.48" height="368.48" style={style}>
    <defs>
      <style>
        {
          '.b4d1ace2-0420-4499-81b2-6027618bbd66{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;}.a08226f8-34e1-4058-b58c-3b9223bafa5d,.b4d1ace2-0420-4499-81b2-6027618bbd66{letter-spacing:-0.02em;}.fb4562a2-cfee-405a-8e86-c74c777b1727{letter-spacing:-0.02em;}.a6fac534-686e-48b7-a713-44a3d11aab32{letter-spacing:-0.01em;}.a826fe41-78eb-4da4-83da-4fc53b28fbea{letter-spacing:-0.02em;}.a612c838-370d-449b-8c23-14a0f53f73f7{letter-spacing:-0.04em;}.bad4d390-959d-4aa4-af23-31010576ea05{letter-spacing:-0.04em;}.b92e92e8-4b75-4648-8779-71bd5ec504a6{letter-spacing:-0.04em;}.b17e0a4f-c9ad-4127-a5d6-ba732476eb37{letter-spacing:-0.03em;}.ad4afc40-1def-4757-830f-c9dff8ad74d2{letter-spacing:-0.02em;}'
        }
      </style>
      <pattern
        id="footer-parks-1"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 262 408"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternParks1}
          width="262"
          height="408"
        />
      </pattern>
      <pattern
        id="footer-parks-2"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 114 319"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternParks2}
          width="114"
          height="319"
        />
      </pattern>
      <pattern
        id="footer-parks-3"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 293 282"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternParks3}
          width="293"
          height="282"
        />
      </pattern>
      <pattern
        id="footer-parks-4"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 183 174"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternParks4}
          width="183"
          height="174"
        />
      </pattern>
      <clipPath id="parks-clip-1">
        <path
          transform="scale(1.05) translate (-34 -2)"
          d="M717.91,13.78c-37.11,7.7-72.62,37.09-99.34,75.65-12.45,62.24.63,93.5,44.66,121.75,35.15-14.11,58.93-40.33,75.81-89.26C754.33,73.22,751,29,729.06.64"
        />
      </clipPath>
      <clipPath id="parks-clip-3">
        <path
          transform="scale(1.05) translate (-34 -12)"
          d="M651.52,326.67c-4.56-23.39,4.55-48.15,23.7-79.74,44.89-32.25,81-41.81,123.47-52.79-3.63,36.51-18.14,71.77-44.4,113.61-26.11,23.56-58.54,37-92.52,32.1"
        />
      </clipPath>
    </defs>
    <g>
      <G pose={footerOpen ? 'to' : 'from'}>
        <Path
          fill="transparent"
          stroke="url(#footer-parks-1)"
          strokeWidth="50px"
          clipPath="url(#parks-clip-1)"
          d="M717.91,13.78c-37.11,7.7-72.62,37.09-99.34,75.65-12.45,62.24.63,93.5,44.66,121.75,35.15-14.11,58.93-40.33,75.81-89.26C754.33,73.22,751,29,729.06.64"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-parks-2)"
          strokeWidth="35px"
          d="M678,84.11s-20.8,31.35-32,80.34-17.17,80.94-17.17,80.94"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-parks-3)"
          strokeWidth="40px"
          d="M651.52,326.67c-4.56-23.39,4.55-48.15,23.7-79.74,44.89-32.25,81-41.81,123.47-52.79-3.63,36.51-18.14,71.77-44.4,113.61-26.11,23.56-58.54,37-92.52,32.1"
          clipPath="url(#parks-clip-3)"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-parks-4)"
          strokeWidth="50px"
          d="M716.68,260.77q-59.29,38.81-92.25,86.4"
        />
      </G>
      <text
        id="faea6b59-e2a5-4562-9073-f0bbcdc7d07b"
        className="font-display font-extrabold b4d1ace2-0420-4499-81b2-6027618bbd66"
        data-name="text"
        fill="rgba(0,0,0,0.01)"
        transform="translate(0 201.71)"
      >
        <tspan>the </tspan>
        <tspan x="228.8" y="0" className="fb4562a2-cfee-405a-8e86-c74c777b1727">
          p
        </tspan>
        <tspan
          x="307.21"
          y="0"
          className="a6fac534-686e-48b7-a713-44a3d11aab32"
        >
          a
        </tspan>
        <tspan
          x="380.25"
          y="0"
          className="a826fe41-78eb-4da4-83da-4fc53b28fbea"
        >
          r
        </tspan>
        <tspan
          x="431.41"
          y="0"
          className="a612c838-370d-449b-8c23-14a0f53f73f7"
        >
          k
        </tspan>
        <tspan x="503.92" y="0">
          s
        </tspan>
        <tspan className="a6fac534-686e-48b7-a713-44a3d11aab32">
          <tspan x="0" y="120.85">
            a
          </tspan>
          <tspan
            x="73.04"
            y="120.85"
            className="bad4d390-959d-4aa4-af23-31010576ea05"
          >
            r
          </tspan>
          <tspan
            x="121.65"
            y="120.85"
            className="a08226f8-34e1-4058-b58c-3b9223bafa5d"
          >
            e{' '}
          </tspan>
          <tspan
            x="227.32"
            y="120.85"
            className="b92e92e8-4b75-4648-8779-71bd5ec504a6"
          >
            y
          </tspan>
          <tspan
            x="296.2"
            y="120.85"
            className="b17e0a4f-c9ad-4127-a5d6-ba732476eb37"
          >
            o
          </tspan>
          <tspan x="371.8" y="120.85">
            u
          </tspan>
          <tspan
            x="448.06"
            y="120.85"
            className="ad4afc40-1def-4757-830f-c9dff8ad74d2"
          >
            r
          </tspan>
          <tspan x="498.55" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <g id="a58fbe67-2a6f-4189-8602-94506bc0e78b" data-name="text-outlines">
        <path d="M32.76,147.73V180.1c0,5.1,2.56,6,7.93,6h5.37v15.57a77.93,77.93,0,0,1-9.67.54c-17.32,0-24.57-5.64-24.57-19.87V147.73H1.48v-15.3H11.82V111.88H32.76v20.55h13.3v15.3Z" />
        <path d="M119.1,156.33v45.38H98.15V160.49c0-7.65-3.35-11.82-11.14-11.82-6.45,0-13.16,4.17-13.16,14.37v38.67H52.9v-95.6H73.85v35.45a25.52,25.52,0,0,1,21.22-10.74C108.76,130.82,119.1,139.14,119.1,156.33Z" />
        <path d="M197.79,173.25h-51.3c1.48,9.93,7.66,14.63,15.85,14.63,7,0,11.54-2.82,14-7.78h19.87c-3.76,14.63-17.18,23.23-33.83,23.23-21.49,0-36-14.24-36-36.26s14.1-36.25,35.72-36.25,35.72,14.9,35.72,38.26ZM146.63,160h30.61c-1.48-8.59-7-13.69-15.44-13.69C154.15,146.26,148.24,150.29,146.63,160Z" />
        <path d="M306.41,167.07c0,22-11.41,36.26-30.21,36.26-9.4,0-16-3.76-20.41-10.08V228H234.84v-95.6h20.41v9.26c4.43-6.71,11.14-10.87,20.95-10.87C295,130.82,306.41,145.05,306.41,167.07Zm-21.22-.54c0-13.16-5.5-18.93-14.77-18.93s-14.9,6.31-14.9,19.47,5.5,19.47,14.9,19.47S285.19,179.69,285.19,166.53Z" />
        <path d="M358.91,201.71a19.71,19.71,0,0,1-1.75-7.78c-4.43,5.9-12,9.4-22.15,9.4-14.51,0-25.25-7.39-25.25-21.22,0-12,7.12-19.87,27.13-21.62l10.87-.94c5.91-.67,9-2.42,9-6.85,0-4.16-2.28-7-10.61-7s-11.55,2-12.22,9.13H313.12c1.07-15.31,10.88-24,33.17-24,21.75,0,30.88,8.19,30.88,21.35V189c0,5.1.94,10.07,2.82,12.75Zm-2.15-27.25v-5.11c-1.48,1.61-3.76,2.29-7,2.69l-8.6.94c-7.11.8-10.07,3.49-10.07,8.32s3.63,7.93,10.21,7.93C349.38,189.23,356.76,185.33,356.76,174.46Z" />
        <path d="M431,131.62v19.07h-5.37c-11.68,0-18.39,5.77-18.39,18.8v32.22H386.3V132.43h20.41v12.89c3-8.6,9.4-14,19.74-14C428.06,131.35,429.53,131.49,431,131.62Z" />
        <path d="M467.4,173.11l-9.4,9.67v18.93H437.45v-95.6H458v53.71l25.65-27.39h24l-26.05,26.85,27.53,42.43h-23.9Z" />
        <path d="M505.8,179.69h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-11.95-7.65l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.22,0-13.83,10.88-21.88,31.15-21.88,23.37,0,31.56,10.2,32.23,23.76H550.78c-.27-7.52-5.24-9.8-12.76-9.8s-10.6,2.55-10.6,6.58,3.35,5.91,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.43-33,22.43C517.88,203.73,507,196.88,505.8,179.69Z" />
        <path d="M51.7,322.56A19.59,19.59,0,0,1,50,314.77c-4.43,5.91-12,9.4-22.15,9.4-14.51,0-25.25-7.38-25.25-21.22,0-11.95,7.12-19.87,27.12-21.61l10.88-.94c5.91-.68,9-2.42,9-6.85,0-4.16-2.28-7-10.61-7s-11.55,2-12.22,9.13H5.91c1.07-15.31,10.87-24,33.17-24C60.83,251.66,70,259.85,70,273V309.8c0,5.11.94,10.07,2.82,12.76ZM49.55,295.3v-5.1c-1.48,1.61-3.76,2.28-7,2.68l-8.6.94c-7.11.81-10.07,3.5-10.07,8.33s3.63,7.92,10.21,7.92C42.16,310.07,49.55,306.18,49.55,295.3Z" />
        <path d="M123.8,252.47v19.06h-5.37c-11.68,0-18.4,5.78-18.4,18.8v32.23h-21V253.27H99.5v12.89c3-8.59,9.4-14,19.73-14C120.85,252.2,122.32,252.33,123.8,252.47Z" />
        <path d="M196.31,294.09H145c1.48,9.94,7.66,14.64,15.85,14.64,7,0,11.55-2.82,14-7.79H194.7c-3.76,14.64-17.19,23.23-33.84,23.23-21.49,0-36-14.23-36-36.25s14.1-36.26,35.72-36.26,35.72,14.91,35.72,38.27ZM145.15,280.8h30.61c-1.47-8.59-7-13.7-15.44-13.7C152.67,267.1,146.76,271.13,145.15,280.8Z" />
        <path d="M301.17,253.27l-25.11,72c-6,17.46-14,23.77-34.51,23.77-4,0-6-.13-8.19-.27V332.63h6.72c7.78,0,10.47-4.43,10.47-9.67,0-3.89-1.61-8.86-3.22-13.43l-19.88-56.26H250l3.09,9.8c4,13.57,8.06,27.8,11.82,43.64,3.76-16,7.65-30.07,11.54-43.5l3.09-9.94Z" />
        <path d="M299.42,287.92c0-22,14.24-36.26,36.39-36.26s36.26,14.23,36.26,36.26-14.24,36.25-36.26,36.25S299.42,309.94,299.42,287.92Zm51.43,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S350.85,301.07,350.85,287.92Z" />
        <path d="M444,253.27v69.29H423.63v-9.8c-4.57,6.44-11.55,11.41-21.75,11.41-13.7,0-24-8.32-24-25.51V253.27h21V294.5c0,7.65,3.35,11.81,11.14,11.81,6.45,0,13.16-4.16,13.16-14.37V253.27Z" />
        <path d="M498.82,252.47v19.06h-5.37c-11.68,0-18.4,5.78-18.4,18.8v32.23H454.1V253.27h20.41v12.89c3-8.59,9.41-14,19.74-14C495.87,252.2,497.34,252.33,498.82,252.47Z" />
        <path d="M500.43,300.54h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.72s-2.55-6.17-11.95-7.65l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21,0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.21,32.23,23.77H545.41c-.27-7.52-5.24-9.8-12.76-9.8s-10.6,2.55-10.6,6.58,3.35,5.9,10.07,7l10.34,1.47c16.38,2.56,24.3,9.14,24.3,21.49,0,14.37-12.22,22.42-33,22.42C512.51,324.57,501.64,317.73,500.43,300.54Z" />
        <path d="M570.92,299.46h23.63v23.1H570.92Z" />
      </g>
    </g>
  </svg>
);

FooterParks.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterParks;
