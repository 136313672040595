import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import IframeResizer from 'iframe-resizer-react';

import Button from 'components/blocks/button';
import { Container } from './form-donation.css';

const FormDonation = ({ margin, pageColor }) => {
  const iframeRef = useRef(null);

  return (
    <Container
      margin={margin}
      className={pageColor ? `typography ${pageColor}` : 'typography'}
    >
      <div className="relative">
        {/* <IframeResizer
          forwardRef={iframeRef}
          log
          src="https://ndcmd.z2systems.com/np/clients/ndcmd/survey.jsp?surveyId=3"
          // src="https://ndcmd.z2systems.com/np/clients/ndcmd/donation.jsp?campaign=39"
          style={{ width: '1px', minWidth: '100%' }}
          frameBorder="0"
          title="NDC Donations"
        /> */}
        {/* {iframeRef.current === null && ( */}
          <Button
            // link="https://ndcmd.z2systems.com/np/clients/ndcmd/donation.jsp"
            link="https://ndcmd.z2systems.com/np/clients/ndcmd/donation.jsp?campaign=39&"
            align="center"
            text="Donate"
            opensNewTab={true}
            caption="Redirects to Neon"
          />
        {/* )} */}
      </div>
    </Container>
  );
};

FormDonation.propTypes = {
  pageColor: PropTypes.string,
  margin: PropTypes.string,
};

export default FormDonation;
