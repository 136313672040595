/* eslint-disable react/display-name */

import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import slugify from 'slugify';

import Box from 'components/box';
import List from 'components/blocks/list';

import { Container, Flex, StyledImage, Margin } from './image-text.css';

const options = pageColor => {
  return {
    renderText: text =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderMark: {
      [MARKS.BOLD]: text => {
        return (
          <b className="font-bold" key={`${text}-key`}>
            {text}
          </b>
        );
      },
      [MARKS.ITALIC]: text => {
        return (
          <i className="italic" key={`${text}-key`}>
            {text}
          </i>
        );
      },
    },
    renderNode: {
      [BLOCKS.HR]: () => {
        return (
          <Box pageColor={pageColor}>
            <hr />
          </Box>
        );
      },
      [BLOCKS.UL_LIST]: node => {
        return (
          <List
            pageColor={pageColor}
            columnCount={false}
            includeIcon={false}
            textColor={true}
            list={node}
            addUl
            isInline
          />
        );
      },
    },
  };
};

const imageStyle = type => {
  if (type === 'large-rectangle') {
    return 'w-full md800:w-1/2 lg:w-2/3 rounded-none';
  }
  if (type === 'large-circle') {
    return 'self-center md800:self-auto w-full sm:w-1/2 md800:w-1/3 lg:w-1/2 h-0 aspect-ratio-square sm:aspect-ratio-2/1 md800:aspect-ratio-3/1 lg:aspect-ratio-2/1 rounded-full';
  }
  if (type === 'small-circle') {
    return 'w-48 xs:w-64 md:w-48 lg:w-64 h-48 xs:h-64 md:h-48 lg:h-64 rounded-full';
  }
};
const ImageText = ({
  pageColor,
  image,
  imageSide,
  imageType,
  text,
  prev,
  next,
}) => (
  <Margin
    margin={
      slugify(imageType, { lower: true }) == 'large-rectangle'
        ? '-mx-3px xs:-mx-3px sm:-mx-3px'
        : 'mx-4 xs:mx-10 sm:mx-20'
    }
  >
    <Container
      color={pageColor}
      maxW={
        slugify(imageType, { lower: true }) !== 'large-rectangle'
          ? 'max-w-5xl'
          : 'max-w-7xl'
      }
      border={
        slugify(imageType, { lower: true }) === 'large-rectangle'
          ? `border-2 border-solid border-brand-${pageColor}`
          : ''
      }
      margin={`${prev && prev !== 'hr' && 'mt-20 sm:mt-32'} ${next &&
        next !== 'hr' &&
        'mb-20 sm:mb-32'}`}
    >
      <Flex
        flex={
          slugify(imageType, { lower: true }) == 'small-circle'
            ? 'flex-col md:flex-row'
            : 'flex-col mdlg:flex-row'
        }
      >
        {image && (
          <StyledImage
            fluid={image.fluid}
            type={imageStyle(slugify(imageType, { lower: true }))}
            margin={
              slugify(imageType, { lower: true }) !== 'large-rectangle'
                ? imageSide
                  ? slugify(imageType, { lower: true }) == 'small-circle'
                    ? 'md:mr-8 lg:mr-16'
                    : 'mdlg:mt-12 lg:mt-5 mdlg:mr-8 lg:mr-16'
                  : slugify(imageType, { lower: true }) == 'small-circle'
                  ? 'md:ml-8 lg:ml-16 md:order-last'
                  : 'mdlg:mt-12 lg:mt-5 mdlg:ml-8 lg:ml-16 mdlg:order-last'
                : imageSide
                ? ''
                : 'order-last'
            }
          />
        )}
        <div
          className={
            slugify(imageType, { lower: true }) !== 'large-rectangle'
              ? 'self-center'
              : `flex flex-col px-4 xs:px-10 sm:px-20 mdlg:px-8 py-4 mdlg:py-16 ${
                  imageSide ? 'mdlg:border-l-2' : 'mdlg:border-r-2'
                } mdlg:border-solid mdlg:border-brand-${pageColor} typography small ${pageColor}`
          }
        >
          {text && documentToReactComponents(text, options(pageColor))}
        </div>
      </Flex>
    </Container>
  </Margin>
);

ImageText.propTypes = {
  text: PropTypes.object.isRequired,
  pageColor: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageSide: PropTypes.bool,
  imageType: PropTypes.string.isRequired,
  options: PropTypes.object,
  prev: PropTypes.string,
  next: PropTypes.string,
};

export default ImageText;
