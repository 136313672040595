/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import Box from 'components/box';
import List from 'components/blocks/list';

import {
  Container,
  Flex,
  Item,
  StyledImage,
  Margin,
  Text,
} from './list-of-people.css';

const options = pageColor => {
  return {
    renderText: text =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderMark: {
      [MARKS.BOLD]: text => {
        return (
          <b className="font-bold" key={`${text}-key`}>
            {text}
          </b>
        );
      },
      [MARKS.ITALIC]: text => {
        return (
          <i className="italic" key={`${text}-key`}>
            {text}
          </i>
        );
      },
    },
    renderNode: {
      [BLOCKS.HR]: () => {
        return (
          <Box pageColor={pageColor}>
            <hr />
          </Box>
        );
      },
      [BLOCKS.UL_LIST]: node => {
        return (
          <List
            pageColor={pageColor}
            columnCount={false}
            includeIcon={false}
            textColor={true}
            list={node}
            addUl
            isInline
          />
        );
      },
    },
  };
};

const ListOfPeople = ({ list, pageColor, prev, next }) => (
  <Margin>
    <Container
      margin={`${next ? 'mb-24 xs:mb-32 sm:mb-56' : ''} ${
        prev ? 'mt-4 xs:mt-10 sm:mt-20' : ''
      }`}
    >
      <Flex>
        {list &&
          list.map(({ id, text, image }) => (
            <Item key={id} color={`border-brand-${pageColor}`}>
              {image && <StyledImage fluid={image.fluid} />}
              <Text>
                {text && documentToReactComponents(text, options(pageColor))}
              </Text>
            </Item>
          ))}
      </Flex>
    </Container>
  </Margin>
);

ListOfPeople.propTypes = {
  link: PropTypes.string.isRequired,
  columnCount: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
  pageColor: PropTypes.string,
  prev: PropTypes.string,
  next: PropTypes.string,
};

export default ListOfPeople;
