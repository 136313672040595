import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern from 'images/footer/footer-neighborhood-drawing.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 1000 }) => ({
      duration: dur,
    }),
  },
});

const FooterNeighborhood = ({ style, footerOpen }) => (
  <svg viewBox="0 0 1083.7 281.7" height="281.7" style={style}>
    <defs>
      <style>
        {
          '.b01dc851-a81f-4fb7-ba06-1f20261ff86b{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;letter-spacing:-0.02em;}.ff3c1ab2-a518-4499-be91-c8667706238c{letter-spacing:-0.02em;}.a6de1bd2-57ee-4b4b-bc28-03ced51d7135{letter-spacing:-0.01em;}.ae76b5cc-aeda-4c09-aeeb-be034b98bd04{letter-spacing:-0.04em;}.aa21e000-01a3-47f3-bac7-f239db678db5{letter-spacing:-0.03em;}.b3d01989-94af-4baf-9256-6e04d95c83bd{letter-spacing:-0.02em;}.fce4be04-4fa7-4766-861b-013069c21c9f{fill:none;stroke:#000;stroke-miterlimit:10;}'
        }
      </style>
      <pattern
        id="footer-neighborhood"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 1014 118"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern}
          width="1014"
          height="118"
        />
      </pattern>
    </defs>
    <g>
      <text
        className="font-display font-extrabold b01dc851-a81f-4fb7-ba06-1f20261ff86b"
        transform="translate(0 114.94)"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the neighbo
        <tspan
          className="ff3c1ab2-a518-4499-be91-c8667706238c"
          x="723.45"
          y="0"
        >
          r
        </tspan>
        <tspan
          className="a6de1bd2-57ee-4b4b-bc28-03ced51d7135"
          x="774.61"
          y="0"
        >
          hood
        </tspan>
        <tspan className="a6de1bd2-57ee-4b4b-bc28-03ced51d7135">
          <tspan x="0" y="120.85">
            is{' '}
          </tspan>
          <tspan
            className="ae76b5cc-aeda-4c09-aeeb-be034b98bd04"
            x="128.23"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="aa21e000-01a3-47f3-bac7-f239db678db5"
            x="197.11"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="272.7" y="120.85">
            u
          </tspan>
          <tspan
            className="b3d01989-94af-4baf-9256-6e04d95c83bd"
            x="348.97"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="399.45" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <Path
        pose={footerOpen ? 'to' : 'from'}
        fill="transparent"
        stroke="url(#footer-neighborhood)"
        // stroke="red"
        strokeWidth="40px"
        // clipPath="url(#parks-clip-1)"
        transform="scale(1.4) translate(-220 -35)"
        d="M501,147.88c169.57-36.49,352.4-29.12,512,19.54"
      />

      <g data-name="outlined-text">
        <path d="M32.76,61V93.32c0,5.1,2.55,6,7.93,6h5.37v15.58a79.87,79.87,0,0,1-9.67.53c-17.32,0-24.57-5.64-24.57-19.87V61H1.48V45.65H11.82V25.11H32.76V45.65h13.3V61Z" />
        <path d="M119.1,69.55v45.39H98.15V73.71C98.15,66.06,94.8,61.9,87,61.9c-6.45,0-13.16,4.16-13.16,14.37v38.67H52.9V19.33H73.85V54.78A25.52,25.52,0,0,1,95.07,44C108.76,44,119.1,52.36,119.1,69.55Z" />
        <path d="M197.79,86.47h-51.3c1.48,9.94,7.65,14.64,15.85,14.64,7,0,11.54-2.82,14-7.79h19.87C192.41,108,179,116.55,162.34,116.55c-21.49,0-36-14.23-36-36.26S140.45,44,162.07,44s35.72,14.9,35.72,38.27ZM146.62,73.18h30.62c-1.48-8.6-7-13.7-15.44-13.7C154.14,59.48,148.24,63.51,146.62,73.18Z" />
        <path d="M301,69.55v45.39h-21V73.71c0-7.65-3.36-11.81-11.14-11.81-6.45,0-13.16,4.16-13.16,14.37v38.67H234.84V45.65h20.41v9.8A25.81,25.81,0,0,1,277,44C290.7,44,301,52.36,301,69.55Z" />
        <path d="M379.72,86.47H328.43c1.47,9.94,7.65,14.64,15.84,14.64,7,0,11.55-2.82,14-7.79h19.87c-3.76,14.64-17.19,23.23-33.84,23.23-21.48,0-36-14.23-36-36.26S322.38,44,344,44s35.72,14.9,35.72,38.27ZM328.56,73.18h30.62c-1.48-8.6-7-13.7-15.45-13.7C336.08,59.48,330.17,63.51,328.56,73.18Z" />
        <path d="M387.51,45.65h20.94v69.29H387.51ZM388,20h20V37.73H388Z" />
        <path d="M487.27,45.65v67.14c0,18.66-12.62,30.08-35.45,30.08-21.61,0-32.76-10.48-33.57-25.38h20.68q.81,9.66,13.3,9.67c9.39,0,14.1-5.11,14.1-13.57v-9.93c-4.17,6-10.48,9.8-19.88,9.8-18.39,0-30.61-14.37-30.61-34.78S427.65,44,446.05,44c9.94,0,16.65,4.16,20.81,10.74V45.65Zm-20.54,33c0-11.68-5.51-17.86-14.91-17.86S437.05,67.54,437.05,79s5.51,17.73,14.77,17.73S466.73,90.36,466.73,78.68Z" />
        <path d="M563.54,69.55v45.39H542.59V73.71c0-7.65-3.36-11.81-11.14-11.81-6.45,0-13.16,4.16-13.16,14.37v38.67H497.34V19.33h20.95V54.78A25.49,25.49,0,0,1,539.5,44C553.2,44,563.54,52.36,563.54,69.55Z" />
        <path d="M645.18,80.29c0,22-11.42,36.26-30.22,36.26-9.8,0-16.51-4.16-20.94-10.88v9.27H573.61V19.33h20.94V54.11C599,47.8,605.56,44,615,44,633.76,44,645.18,58.27,645.18,80.29ZM624,79.76c0-13.16-5.51-18.94-14.77-18.94s-14.91,6.31-14.91,19.47,5.51,19.47,14.91,19.47S624,92.92,624,79.76Z" />
        <path d="M649.6,80.29C649.6,58.27,663.84,44,686,44s36.26,14.23,36.26,36.25S708,116.55,686,116.55,649.6,102.32,649.6,80.29Zm51.43,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47S676.59,99.76,686,99.76,701,93.45,701,80.29Z" />
        <path d="M774.21,44.84V63.91h-5.37c-11.69,0-18.4,5.78-18.4,18.8v32.23h-21V45.65H749.9V58.54c3-8.59,9.4-14,19.74-14C771.25,44.58,772.73,44.71,774.21,44.84Z" />
        <path d="M846.85,69.55v45.39h-21V73.71c0-7.65-3.36-11.81-11.14-11.81-6.45,0-13.16,4.16-13.16,14.37v38.67h-21V19.33h21V54.78A25.49,25.49,0,0,1,822.81,44C836.51,44,846.85,52.36,846.85,69.55Z" />
        <path d="M854.1,80.29c0-22,14.23-36.25,36.38-36.25s36.26,14.23,36.26,36.25-14.23,36.26-36.26,36.26S854.1,102.32,854.1,80.29Zm51.42,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.78,19.47,15.17,19.47S905.52,93.45,905.52,80.29Z" />
        <path d="M931.17,80.29c0-22,14.23-36.25,36.39-36.25s36.25,14.23,36.25,36.25-14.23,36.26-36.25,36.26S931.17,102.32,931.17,80.29Zm51.43,0c0-13.16-5.78-19.47-15-19.47s-15.18,6.31-15.18,19.47,5.78,19.47,15.18,19.47S982.6,93.45,982.6,80.29Z" />
        <path d="M1079.67,19.33v95.61h-20.41v-9c-4.43,6.58-11.14,10.61-20.81,10.61-18.8,0-30.21-14.23-30.21-36.26S1019.65,44,1038.45,44c9.26,0,15.84,3.76,20.28,9.94V19.33Zm-20.54,61c0-13.16-5.51-19.47-14.91-19.47s-14.77,6.85-14.77,20,5.51,18.93,14.77,18.93S1059.13,93.45,1059.13,80.29Z" />
        <path d="M6,166.5H27v69.28H6Zm.54-25.65h20v17.72h-20Z" />
        <path d="M33,213.76H53.44c.81,7.52,4.57,10.07,13.29,10.07,8.46,0,11.69-2.55,11.69-6.71s-2.55-6.18-12-7.66l-9-1.47c-14.64-2.29-23.36-8.87-23.36-21.22,0-13.83,10.87-21.89,31.15-21.89,23.36,0,31.55,10.21,32.22,23.77H78c-.27-7.52-5.23-9.8-12.75-9.8s-10.61,2.55-10.61,6.58,3.36,5.91,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.43-33,22.43C45.12,237.8,34.24,231,33,213.76Z" />
        <path d="M202.08,166.5l-25.11,72c-6,17.45-14,23.77-34.51,23.77-4,0-6-.14-8.19-.27V245.85H141c7.79,0,10.48-4.43,10.48-9.66,0-3.9-1.62-8.87-3.23-13.43L128.36,166.5h22.56l3.09,9.8c4,13.56,8.05,27.79,11.81,43.64,3.76-16,7.66-30.08,11.55-43.51l3.09-9.93Z" />
        <path d="M200.33,201.14c0-22,14.23-36.26,36.39-36.26S273,179.12,273,201.14s-14.23,36.25-36.25,36.25S200.33,223.16,200.33,201.14Zm51.43,0c0-13.16-5.78-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S251.76,214.3,251.76,201.14Z" />
        <path d="M345,166.5v69.28H324.54V226c-4.57,6.45-11.55,11.41-21.76,11.41-13.69,0-24-8.32-24-25.51V166.5h20.94v41.22c0,7.65,3.36,11.82,11.15,11.82,6.44,0,13.16-4.17,13.16-14.37V166.5Z" />
        <path d="M399.73,165.69v19.07h-5.37c-11.69,0-18.4,5.77-18.4,18.8v32.22H355V166.5h20.41v12.89c3-8.6,9.4-14,19.74-14C396.77,165.42,398.25,165.56,399.73,165.69Z" />
        <path d="M401.33,213.76h20.41c.81,7.52,4.57,10.07,13.3,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-12-7.66l-9-1.47c-14.63-2.29-23.36-8.87-23.36-21.22,0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.21,32.23,23.77H446.32c-.27-7.52-5.24-9.8-12.76-9.8S423,181.4,423,185.43s3.36,5.91,10.07,7l10.34,1.48c16.38,2.55,24.31,9.13,24.31,21.48,0,14.37-12.22,22.43-33,22.43C413.42,237.8,402.54,231,401.33,213.76Z" />
        <path d="M471.83,212.69h23.63v23.09H471.83Z" />
      </g>
    </g>
  </svg>
);

FooterNeighborhood.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterNeighborhood;
