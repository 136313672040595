import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import moment from 'moment';

import {
  Container,
  Title,
  StyledLink,
  Flex,
  Description,
  Item,
  Color,
  Type,
  Date,
  Overlay,
  StyledImg,
} from './index-section-info.css';
import slugify from 'slugify';

const createUrl = (type, slug) => {
  if (type === 'journal') {
    return `/journal/${slug}`;
  }
  if (type === 'case-study') {
    return `/case-study/${slug}`;
  }
  if (type === 'volunteer') {
    return `/volunteer#${slug}`;
  }
};

const IndexSectionInfo = ({ title, description, items, color, all, type }) => (
  <Container>
    <Flex margin="mx-8 xxs:mx-10">
      <Title>{title && title}</Title>
      <StyledLink
        to={all}
        color={`border-brand-${color} hover:text-brand-${color}`}
      >
        View All
      </StyledLink>
    </Flex>
    <Description margin="mx-8 xxs:mx-10">
      {description && description}
    </Description>
    <Flex flex="flex-wrap mt-16 xs:mt-0">
      {console.log(items)}
      {items.map(
        (
          {
            node,
            title,
            projectIcon,
            location,
            slug,
            thumbnailImage,
            headerImage,
          },
          i
        ) => (
          <Item
            key={i}
            to={createUrl(
              type,
              slug ||
                (node && node.slug) ||
                (title && slugify(title, { lower: true })) ||
                (node && node.title && slugify(node.title, { lower: true }))
            )}
            color={`border-brand-${color}`}
            width={
              items.length > 1 ? 'w-full lg:w-1/2 -my-px xs:-mx-px' : 'w-full'
            }
            mv={items.length == 3 && i == 1 && 'lg:homepage-bump'}
          >
            {console.log(node)}
            <Overlay color={`bg-brand-${color}`}>
              {(thumbnailImage ||
                headerImage ||
                (node && node.thumbnailImage)) && (
                <StyledImg
                  fluid={
                    (thumbnailImage && thumbnailImage.fluid) ||
                    (headerImage && headerImage.fluid) ||
                    (node && node.thumbnailImage.fluid)
                  }
                  style={{ position: 'absolute' }}
                />
              )}
            </Overlay>
            <div
              className={
                color === 'pink'
                  ? 'typography large m-0 relative'
                  : 'typography m-0 relative'
              }
            >
              <span className="flex flex-wrap items-baseline">
                {node && node.type && (
                  <Type color={`text-brand-${color}`}>
                    {Array.isArray(node.type) ? node.type.join(', ') : type}
                  </Type>
                )}
                {node && node.date && (
                  <Date>{moment(node.date).format('LL')}</Date>
                )}
              </span>

              {projectIcon && (
                <div className="h-40 relative">
                  <Image
                    loading="eager"
                    className="h-auto w-40 filter-invert transition duration-200 ease-in-out opacity-0 group-hover:opacity-100"
                    style={{ position: 'absolute' }}
                    fluid={projectIcon.fluid}
                  />
                  <Image
                    loading="eager"
                    className="h-auto w-40 -z-1"
                    style={{ position: 'absolute' }}
                    fluid={projectIcon.fluid}
                  />
                  <Color bg={`h-40 bg-brand-${color}`} />
                </div>
              )}
              {(title || node.title) && (
                <h3
                  style={{ marginBottom: 0 }}
                  className={
                    color === 'pink'
                      ? 'transition duration-200 ease-in-out text-brand-pink group-hover:text-white'
                      : ' group-hover:text-white transition duration-200 ease-in-out'
                  }
                >
                  {title || node.title}
                </h3>
              )}
              {(location || (node && node.location)) && (
                <h4 className="text-brand-orange mt-4 group-hover:text-white transition duration-200 ease-in-out">
                  {location || node.location}
                </h4>
              )}
            </div>
          </Item>
        )
      )}
    </Flex>
  </Container>
);

IndexSectionInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
  color: PropTypes.string,
  all: PropTypes.string,
  type: PropTypes.string,
};

export default IndexSectionInfo;
