import nanostyled from 'nanostyled';

export const Container = nanostyled('section', {
  base: 'w-full',
  width: '',
  height: '',
  margin: 'my-16 sm:my-32',
});

export const Background = nanostyled('section', {
  base: 'w-full flex relative z-1  bg-white',
  width: '',
  height: '',
});
