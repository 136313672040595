import React from 'react';
import Layout from 'components/layout';
import Box from 'components/box';

import FormCommunity from 'components/form-community';

const Test = () => (
  <Layout>
    <Box>
      <FormCommunity />
    </Box>
  </Layout>
);

export default Test;
