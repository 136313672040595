import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter, includes, isEqual, sortBy } from 'lodash';
import Fuse from 'fuse.js';
import moment from 'moment';

import {
  Center,
  Container,
  ImageLink,
  ImageLinkWrapper,
  TextContainer,
  Text,
  TextBold,
  Type,
  Date,
  StyledImg,
  Overlay,
  BgImg,
} from './journal-list.css';

const gridWidth = i => {
  if ((i % 18) - 1 === 0) {
    return 'w-fullpx xl:w-2/3px -m-px';
  }
  if ((i % 18) - 4 === 0) {
    return 'w-fullpx xl:w-2/3px -m-px';
  }
  if ((i % 18) - 11 === 0) {
    return 'w-fullpx lg:w-2/3px -m-px md:mx-1/6 md:my-32';
  }
  if (i % 18 === 0) {
    return 'w-fullpx lg:w-2/3px -m-px md:mx-1/6 md:my-32';
  }
  return 'w-fullpx sm:w-1/2px xl:w-1/3px -m-px';
};

const showImage = i => {
  if ((i % 18) - 1 === 0) {
    return false;
  }
  if ((i % 18) - 4 === 0) {
    return false;
  }
  if ((i % 18) - 11 === 0) {
    return false;
  }
  if (i % 18 === 0) {
    return false;
  }
  return true;
};

const showExcerpt = i => {
  if ((i % 18) - 11 === 0) {
    return true;
  }
  if (i % 18 === 0) {
    return true;
  }
  return false;
};

const JournalList = ({ items, filterJournal, searchJournal }) => {
  var options = {
    keys: ['node.title'],
  };
  var fuse = new Fuse(items, options);

  const filteredItems = filter(
    searchJournal === '' ? items : fuse.search(searchJournal),
    ({ node }) => {
      if (filterJournal == null) {
        return true;
      }
      return includes(node.type, filterJournal);
    }
  );

  const [hasMore, setMore] = useState(filteredItems.length > 10);
  const [currentList, addToList] = useState([...filteredItems.slice(0, 10)]);

  const loadItems = () => {
    const currentLength = currentList.length;
    const more = currentLength < filteredItems.length;
    const nextItems = more
      ? filteredItems.slice(currentLength, currentLength + 20)
      : [];
    setMore(more);
    addToList([...currentList, ...nextItems]);
  };

  const handleScroll = () => {
    if (!hasMore) return;
    if (
      window &&
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - window.innerHeight
    ) {
      loadItems();
    }
  };

  // const handleTouchEnd = e => {
  //   e.preventDefault();
  //   handleScroll();
  // };

  useEffect(() => {
    if (
      !isEqual(
        sortBy(currentList),
        sortBy(filteredItems.slice(0, currentList.length))
      ) ||
      currentList.length == 0
    ) {
      addToList([...filteredItems.slice(0, 10)]);
      const more = currentList.length < filteredItems.length;
      setMore(more);
    }
  }, [filterJournal, searchJournal]);

  useEffect(() => {
    window && window.addEventListener('scroll', handleScroll);
    window && window.addEventListener('resize', handleScroll);
    // window && window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window && window.removeEventListener('scroll', handleScroll);
      window && window.removeEventListener('resize', handleScroll);
      // window && window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [hasMore, currentList]);

  return (
    <Center>
      <Container>
        {currentList.map(
          (
            {
              node: {
                id,
                type,
                title,
                thumbnailImage,
                featured_media,
                excerpt,
                slug,
                date,
              },
            },
            i
          ) => (
            <ImageLinkWrapper key={id + i} width={gridWidth(i + 1)}>
              <ImageLink to={`/journal/${slug}`}>
                {/* <StyledImg
              fluid={thumbnailImage.fluid}
              style={{ position: 'absolute' }}
            />
            <Overlay
              color={`bg-brand-${slugify(pageColor, { lower: true })}`}
            /> */}
                {thumbnailImage &&
                  (showImage(i + 1) ? (
                    <StyledImg fluid={thumbnailImage.fluid} />
                  ) : (
                    <BgImg
                      fluid={thumbnailImage.fluid}
                      style={{ position: 'absolute' }}
                    />
                  ))}
                {featured_media &&
                  featured_media.localFile &&
                  (showImage(i + 1) ? (
                    <StyledImg
                      fluid={featured_media.localFile.childImageSharp.fluid}
                    />
                  ) : (
                    <BgImg
                      fluid={featured_media.localFile.childImageSharp.fluid}
                      style={{ position: 'absolute' }}
                    />
                  ))}
                <Overlay color="bg-brand-blue" />
                <TextContainer padding={showImage(i + 1) ? '' : 'pt-10 pb-8'}>
                  <span className="flex flex-wrap items-baseline">
                    {type && (
                      <Type>
                        {Array.isArray(type) ? type.join(', ') : type}
                      </Type>
                    )}
                    {date && <Date>{moment(date).format('LL')}</Date>}
                  </span>
                  {showImage(i + 1) ? (
                    <div className="typography black">
                      <TextBold>{title}</TextBold>
                    </div>
                  ) : (
                    <Text>{title}</Text>
                  )}
                  {showExcerpt(i + 1) &&
                  excerpt &&
                  excerpt.childMarkdownRemark ? (
                    <div
                      className="text-xl xs:text-2xl font-light font-micro leading-normal tracking-tight transition duration-200 ease-in-out group-hover:text-white"
                      dangerouslySetInnerHTML={{
                        __html: excerpt.childMarkdownRemark.html,
                      }}
                    />
                  ) : (
                    showExcerpt(i + 1) &&
                    excerpt && (
                      <div
                        className="text-xl xs:text-2xl font-light font-micro leading-normal tracking-tight transition duration-200 ease-in-out group-hover:text-white"
                        dangerouslySetInnerHTML={{
                          __html: excerpt,
                        }}
                      />
                    )
                  )}
                </TextContainer>
              </ImageLink>
            </ImageLinkWrapper>
          )
        )}
      </Container>
    </Center>
  );
};

JournalList.propTypes = {
  items: PropTypes.array.isRequired,
  filterJournal: PropTypes.string,
  searchJournal: PropTypes.string,
};

export default JournalList;
