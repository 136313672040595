import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern1 from 'images/footer/footer-power-drawing-1.png';
import pattern2 from 'images/footer/footer-power-drawing-2.png';
import pattern3 from 'images/footer/footer-power-drawing-3.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const FooterPower = ({ style, footerOpen }) => (
  <svg viewBox="0 0 619.66 341.69" height="341.69" style={style}>
    <defs>
      <style>
        {
          '.a76e0824-4fc0-489e-a99e-177f57771c7b{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;letter-spacing:-0.02em;}.a0f706e8-2a21-4ac5-a458-f75a38f3e31a{letter-spacing:-0.04em;}.a3fe2e69-9081-4065-9633-6aa07c56715e{letter-spacing:-0.01em;}.f0d093fb-312d-4c0f-bb84-a2f4a27333f9{letter-spacing:-0.03em;}.b996bffb-4e04-4137-ac48-c82e54079424{letter-spacing:-0.02em;}.e22a240e-1152-45ce-add7-1c9d97fc62b1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:1px;}'
        }
      </style>
      <pattern
        id="footer-power-1"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 842 112"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern1}
          width="842"
          height="112"
        />
      </pattern>
      <pattern
        id="footer-power-2"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 848 136"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern2}
          width="848"
          height="136"
        />
      </pattern>
      <pattern
        id="footer-power-3"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 898 117"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern3}
          width="898"
          height="117"
        />
      </pattern>
    </defs>
    <g>
      <text
        className="font-display font-extrabold a76e0824-4fc0-489e-a99e-177f57771c7b"
        transform="translate(0 114.94)"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the p
        <tspan
          className="a0f706e8-2a21-4ac5-a458-f75a38f3e31a"
          x="307.61"
          y="0"
        >
          ow
        </tspan>
        <tspan x="491.7" y="0">
          er
        </tspan>
        <tspan className="a3fe2e69-9081-4065-9633-6aa07c56715e">
          <tspan x="0" y="120.85">
            is{' '}
          </tspan>
          <tspan
            className="a0f706e8-2a21-4ac5-a458-f75a38f3e31a"
            x="128.23"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="f0d093fb-312d-4c0f-bb84-a2f4a27333f9"
            x="197.11"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="272.7" y="120.85">
            u
          </tspan>
          <tspan
            className="b996bffb-4e04-4137-ac48-c82e54079424"
            x="348.97"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="399.45" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <G pose={footerOpen ? 'to' : 'from'}>
        <Path
          fill="transparent"
          stroke="url(#footer-power-1)"
          // stroke="red"
          strokeWidth="35px"
          // clipPath="url(#parks-clip-1)"
          transform="scale(1.3) translate(-82 -73)"
          d="M111.54,305.69c95-43.42,235.74-53.17,425.35-35.89"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-power-2)"
          // stroke="red"
          strokeWidth="35px"
          // clipPath="url(#parks-clip-1)"
          transform="scale(1.2) translate(-64 -60)"
          d="M537.24,277.54c-179.6-8.61-324.21,8.21-426.77,55.59"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-power-3)"
          // stroke="red"
          strokeWidth="35px"
          // clipPath="url(#parks-clip-1)"
          transform="scale(1.2) translate(-60 -60)"
          d="M111.88,341.22c129.05-50.56,305-64,450-41.16"
        />
      </G>

      <g data-name="outlined-text">
        <path d="M32.76,61V93.32c0,5.1,2.55,6,7.93,6h5.37v15.58a79.87,79.87,0,0,1-9.67.53c-17.32,0-24.57-5.64-24.57-19.87V61H1.48V45.65H11.82V25.11H32.76V45.65h13.3V61Z" />
        <path d="M119.1,69.55v45.39H98.15V73.71C98.15,66.06,94.8,61.9,87,61.9c-6.45,0-13.16,4.16-13.16,14.37v38.67H52.9V19.33H73.85V54.78A25.52,25.52,0,0,1,95.07,44C108.76,44,119.1,52.36,119.1,69.55Z" />
        <path d="M197.79,86.47h-51.3c1.48,9.94,7.65,14.64,15.85,14.64,7,0,11.54-2.82,14-7.79h19.87C192.41,108,179,116.55,162.34,116.55c-21.49,0-36-14.23-36-36.26S140.45,44,162.07,44s35.72,14.9,35.72,38.27ZM146.62,73.18h30.62c-1.48-8.6-7-13.7-15.44-13.7C154.14,59.48,148.24,63.51,146.62,73.18Z" />
        <path d="M306.41,80.29c0,22-11.41,36.26-30.21,36.26-9.4,0-16-3.76-20.41-10.07v34.78H234.84V45.65h20.41v9.27C259.68,48.2,266.39,44,276.2,44,295,44,306.41,58.27,306.41,80.29Zm-21.22-.53c0-13.16-5.5-18.94-14.77-18.94s-14.9,6.31-14.9,19.47,5.5,19.47,14.9,19.47S285.19,92.92,285.19,79.76Z" />
        <path d="M310.84,80.29c0-22,14.23-36.25,36.38-36.25s36.26,14.23,36.26,36.25-14.23,36.26-36.26,36.26S310.84,102.32,310.84,80.29Zm51.42,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.78,19.47,15.17,19.47S362.26,93.45,362.26,80.29Z" />
        <path d="M496.54,45.65,474,114.94H452l-7.66-26c-1.88-6.18-3.62-12.49-5.23-18.67-1.48,6.18-3.23,12.49-5,18.67l-7.65,26.05h-22L381.73,45.65h22.56l6.44,22.29q3.43,11.69,6.45,23.36c1.88-7.78,3.89-15.57,6-23.49l6.31-22.16h19.34l6.31,22.16c2.15,7.92,4.16,15.71,6,23.49q3-11.67,6.45-23.36l6.44-22.29Z" />
        <path d="M566.36,86.47h-51.3c1.48,9.94,7.66,14.64,15.85,14.64,7,0,11.55-2.82,14-7.79h19.88C561,108,547.56,116.55,530.91,116.55c-21.49,0-36-14.23-36-36.26S509,44,530.64,44s35.72,14.9,35.72,38.27ZM515.2,73.18h30.61c-1.47-8.6-7-13.7-15.44-13.7C522.72,59.48,516.81,63.51,515.2,73.18Z" />
        <path d="M618.86,44.84V63.91h-5.37c-11.69,0-18.4,5.78-18.4,18.8v32.23h-21V45.65h20.41V58.54c3-8.59,9.4-14,19.74-14C615.9,44.58,617.38,44.71,618.86,44.84Z" />
        <path d="M6,166.5H27v69.28H6Zm.54-25.65h20v17.72h-20Z" />
        <path d="M33,213.76H53.44c.81,7.52,4.57,10.07,13.29,10.07,8.46,0,11.69-2.55,11.69-6.71s-2.55-6.18-12-7.66l-9-1.47c-14.64-2.29-23.37-8.87-23.37-21.22,0-13.83,10.88-21.89,31.16-21.89,23.36,0,31.55,10.21,32.22,23.77H78c-.27-7.52-5.23-9.8-12.75-9.8s-10.61,2.55-10.61,6.58,3.36,5.91,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.43-33,22.43C45.12,237.8,34.24,231,33,213.76Z" />
        <path d="M202.08,166.5l-25.11,72c-6,17.45-14,23.77-34.51,23.77-4,0-6-.14-8.19-.27V245.85H141c7.79,0,10.48-4.43,10.48-9.66,0-3.9-1.62-8.87-3.23-13.43L128.36,166.5h22.56l3.09,9.8c4,13.56,8.05,27.79,11.81,43.64,3.76-16,7.66-30.08,11.55-43.51l3.09-9.93Z" />
        <path d="M200.33,201.14c0-22,14.23-36.26,36.39-36.26S273,179.12,273,201.14s-14.23,36.25-36.25,36.25S200.33,223.16,200.33,201.14Zm51.43,0c0-13.16-5.78-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S251.76,214.3,251.76,201.14Z" />
        <path d="M344.94,166.5v69.28H324.53V226c-4.56,6.45-11.54,11.41-21.75,11.41-13.7,0-24-8.32-24-25.51V166.5h20.94v41.22c0,7.65,3.36,11.82,11.15,11.82,6.44,0,13.16-4.17,13.16-14.37V166.5Z" />
        <path d="M399.73,165.69v19.07h-5.38c-11.68,0-18.39,5.77-18.39,18.8v32.22H355V166.5h20.41v12.89c3-8.6,9.4-14,19.74-14C396.77,165.42,398.25,165.56,399.73,165.69Z" />
        <path d="M401.33,213.76h20.41c.81,7.52,4.57,10.07,13.3,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-12-7.66l-9-1.47c-14.63-2.29-23.36-8.87-23.36-21.22,0-13.83,10.88-21.89,31.15-21.89,23.36,0,31.56,10.21,32.23,23.77H446.32c-.27-7.52-5.24-9.8-12.76-9.8S423,181.4,423,185.43s3.36,5.91,10.07,7l10.34,1.48c16.38,2.55,24.31,9.13,24.31,21.48,0,14.37-12.22,22.43-33,22.43C413.42,237.8,402.54,231,401.33,213.76Z" />
        <path d="M471.83,212.69h23.63v23.09H471.83Z" />
      </g>
    </g>
  </svg>
);

FooterPower.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterPower;
