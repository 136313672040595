import React from 'react';
import PropTypes from 'prop-types';
import { Container, Center } from './box.css';

const Box = ({ children, margin, pageColor }) => (
  <Center margin={margin}>
    <Container
      margin={margin}
      className={pageColor ? `typography ${pageColor}` : 'typography'}
    >
      {children}
    </Container>
  </Center>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  pageColor: PropTypes.string,
  margin: PropTypes.string,
};

export default Box;
