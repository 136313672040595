import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Element, scroller as scroll } from 'react-scroll';

import Button from 'components/blocks/button';

import {
  Container,
  Flex,
  Margin,
  VolunteerItem,
  Title,
} from './form-volunteer.css';

// import Form from './form';
import slugify from 'slugify';

class FormVolunteer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormOpen: false,
    };
    this.openForm = this.openForm.bind(this);
    this.openFormAndScroll = this.openFormAndScroll.bind(this);
    this.closeForm = this.closeForm.bind(this);
  }

  openForm() {
    this.setState({
      isFormOpen: true,
    });
  }

  openFormAndScroll() {
    this.setState({
      isFormOpen: true,
    });
    scroll.scrollTo('volunteerForm', {
      smooth: true,
      duration: 250,
      offset: -50,
    });
  }

  closeForm() {
    this.setState({
      isFormOpen: false,
    });
  }

  render() {
    const { margin, pageColor, options, data } = this.props;
    const { isFormOpen } = this.state;
    const { allContentfulVolunteerOpportunity } = data;
    return (
      <Margin>
        <Container
          margin={margin}
          className={pageColor ? `typography ${pageColor}` : 'typography'}
        >
          <div>
            <Flex flex="flex-col justify-between sm:flex-row">
              <div className="sm:mr-12">
                <h3>Put your skills to work!</h3>
                <Button
                  onClick={this.openFormAndScroll}
                  align="center"
                  text="Apply to be a volunteer"
                />
              </div>
              <div className="sm:ml-12">
                <h3>Already a volunteer?</h3>
                <Button
                  link="https://www.volgistics.com/ex2/vicnet.dll?FROM=96083"
                  align="center"
                  text="Login to volunteer portal"
                  opensNewTab={true}
                  caption="Redirects to Volgistics portal"
                />
              </div>
            </Flex>
          </div>
          <div id="opportunities" className="my-16 sm:my-32">
            <h2>Volunteer Opportunities</h2>
            <Flex flex="flex-wrap -mx-4 sm:-mx-10 mt-8 sm:mt-16">
              {allContentfulVolunteerOpportunity &&
                allContentfulVolunteerOpportunity.edges.map(({ node }) => (
                  <VolunteerItem
                    key={node.id}
                    id={slugify(node.title, { lower: true })}
                    color={`border-brand-${pageColor}`}
                  >
                    <Title>{node.title}</Title>
                    <h4>{node.location}</h4>
                    <div>
                      {node.description &&
                        documentToReactComponents(
                          node.description.json,
                          options
                        )}
                    </div>
                  </VolunteerItem>
                ))}
            </Flex>
          </div>
        </Container>

        <div className="my-16 sm:my-32">
          <Element name="volunteerForm">
            {isFormOpen ? (
              <div className="relative">
                <Button
                  onClick={this.closeForm}
                  align="center"
                  text="Close Form"
                />
                <div
                  className={`py-16 bg-brand-${pageColor} -mx-4 xs:-mx-10 sm:-mx-20 flex`}
                >
                  <iframe
                    className="w-full h-screen overflow-y-scroll max-w-xl mx-auto"
                    src="https://docs.google.com/forms/d/e/1FAIpQLScamowOmiTCUKrXhppo2hN7fa9iZe5TwgxtbSJWW77A2hzEYg/viewform?embedded=false"
                    frameBorder="0"
                    title="NDC Community Intake Form"
                  >
                    Loading…
                  </iframe>
                </div>
                {/* <Form /> */}
              </div>
            ) : (
              <Button
                onClick={this.openForm}
                align="center"
                text="Apply to be a volunteer"
              />
            )}
          </Element>
        </div>
      </Margin>
    );
  }
}

const FormVolunteerWithQuery = props => (
  <StaticQuery
    query={graphql`
      query FormVolunteerQuery {
        allContentfulVolunteerOpportunity {
          edges {
            node {
              id
              title
              location
              description {
                id
                json
              }
            }
          }
        }
      }
    `}
    render={data => <FormVolunteer data={data} {...props} />}
  />
);

FormVolunteer.propTypes = {
  pageColor: PropTypes.string,
  margin: PropTypes.string,
  options: PropTypes.object,
  data: PropTypes.object,
};

export default FormVolunteerWithQuery;
