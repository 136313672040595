import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternStreets1 from 'images/footer/footer-streets-drawing-1.png';
import patternStreets2 from 'images/footer/footer-streets-drawing-2.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    delay: 600,
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const Rect = posed.rect({
  from: { width: 0 },
  to: {
    width: 422,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const FooterStreets = ({ style, footerOpen }) => (
  <svg viewBox="0 0 750.64 344.09" height="344.09" style={style}>
    <defs>
      <style>
        {
          '.b222e483-80e9-461b-b7e1-4499b9a385d8{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;}.b222e483-80e9-461b-b7e1-4499b9a385d8,.fb634043-449e-4dbb-90b6-126a6e2ad39c{letter-spacing:-0.02em;}.acf46a0c-326e-4326-a26d-31bce3044079{letter-spacing:-0.02em;}.af84780a-1a18-40e5-9aa2-d12153132e37{letter-spacing:-0.04em;}.a8e31d19-4d9b-4bbb-83b4-e3728d886829{letter-spacing:-0.01em;}.b6dcd581-305d-4b97-b704-b5c1efea4cc5{letter-spacing:-0.04em;}.f29d981c-f496-4f8f-a449-c9f06ca8cb86{letter-spacing:-0.01em;}.b01a9585-bf28-4b8b-9b00-288239e7caee{letter-spacing:-0.03em;}.fa530915-60e6-43cc-8cd7-817e8267a9bc{letter-spacing:-0.02em;}'
        }
      </style>
      <pattern
        id="footer-streets-1"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 58 71"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternStreets1}
          width="58"
          height="71"
        />
      </pattern>
      <pattern
        id="footer-streets-2"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 831 47"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={patternStreets2}
          width="831"
          height="47"
        />
      </pattern>
      <pattern
        id="streetpattern1"
        width="0.1"
        height="0.1"
        viewBox="0 0 20 20"
        patternUnits="objectBoundingBox"
      >
        <circle cx="10" cy="10" r="10" stroke="red" fill="#393" />
      </pattern>
      <clipPath id="streets-arrow">
        <path
          transform="scale(1.01) translate(2 18)"
          d="M609.79,327.37,601.46,170l34.63.62L752.2,265.23l-.49,4.85L638.35,327.61Z"
        />
      </clipPath>
      <clipPath id="streets-rect-animate">
        <Rect
          pose={footerOpen ? 'to' : 'from'}
          width="422"
          height="22"
          x="320"
          y="270"
        />
      </clipPath>
    </defs>
    <g>
      <G pose={footerOpen ? 'to' : 'from'}>
        <Path
          fill="transparent"
          stroke="url(#footer-streets-1)"
          strokeWidth="28px"
          clipPath="url(#streets-arrow)"
          d="M634.43,193.66l114.39,93L639.44,343.2"
        />
        <rect
          width="422"
          height="22"
          x="320"
          y="270"
          clipPath="url(#streets-rect-animate)"
          fill="url(#footer-streets-2)"
          className="f4e678f8-6e87-45e6-a2c6-d1308c5b3af4"
        />
      </G>
      <text
        id="eeb06ea0-8421-40f0-98aa-edd9933edff6"
        className="font-display font-extrabold b222e483-80e9-461b-b7e1-4499b9a385d8"
        data-name="text"
        transform="translate(0 114.94)"
        fill="rgba(0,0,0,0.01)"
      >
        <tspan>the </tspan>
        <tspan x="228.8" y="0" className="acf46a0c-326e-4326-a26d-31bce3044079">
          s
        </tspan>
        <tspan x="295.8" y="0">
          t
        </tspan>
        <tspan
          x="342.66"
          y="0"
          className="af84780a-1a18-40e5-9aa2-d12153132e37"
        >
          r
        </tspan>
        <tspan
          x="391.26"
          y="0"
          className="a8e31d19-4d9b-4bbb-83b4-e3728d886829"
        >
          e
        </tspan>
        <tspan
          x="467.67"
          y="0"
          className="b6dcd581-305d-4b97-b704-b5c1efea4cc5"
        >
          e
        </tspan>
        <tspan
          x="540.98"
          y="0"
          className="f29d981c-f496-4f8f-a449-c9f06ca8cb86"
        >
          t
        </tspan>
        <tspan
          x="588.11"
          y="0"
          className="a8e31d19-4d9b-4bbb-83b4-e3728d886829"
        >
          s{' '}
        </tspan>
        <tspan className="a8e31d19-4d9b-4bbb-83b4-e3728d886829">
          <tspan x="0" y="120.85">
            a
          </tspan>
          <tspan
            x="73.04"
            y="120.85"
            className="af84780a-1a18-40e5-9aa2-d12153132e37"
          >
            r
          </tspan>
          <tspan
            x="121.65"
            y="120.85"
            className="fb634043-449e-4dbb-90b6-126a6e2ad39c"
          >
            e{' '}
          </tspan>
          <tspan
            x="227.32"
            y="120.85"
            className="b6dcd581-305d-4b97-b704-b5c1efea4cc5"
          >
            y
          </tspan>
          <tspan
            x="296.2"
            y="120.85"
            className="b01a9585-bf28-4b8b-9b00-288239e7caee"
          >
            o
          </tspan>
          <tspan x="371.8" y="120.85">
            u
          </tspan>
          <tspan
            x="448.06"
            y="120.85"
            className="fa530915-60e6-43cc-8cd7-817e8267a9bc"
          >
            r
          </tspan>
          <tspan x="498.55" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>
      <g id="f820bfb9-36d6-4d1a-88c0-345071c7ee3b" data-name="outline-text">
        <path d="M32.76,61V93.32c0,5.1,2.56,6,7.93,6h5.37v15.58a79.87,79.87,0,0,1-9.67.53c-17.32,0-24.57-5.64-24.57-19.87V61H1.48V45.65H11.82V25.11H32.76V45.65h13.3V61Z" />
        <path d="M119.1,69.55v45.39H98.15V73.71C98.15,66.06,94.8,61.9,87,61.9c-6.45,0-13.16,4.16-13.16,14.37v38.67H52.9V19.33H73.85V54.78A25.52,25.52,0,0,1,95.07,44C108.76,44,119.1,52.36,119.1,69.55Z" />
        <path d="M197.79,86.47h-51.3c1.48,9.94,7.66,14.64,15.85,14.64,7,0,11.54-2.82,14-7.79h19.87C192.41,108,179,116.55,162.34,116.55c-21.49,0-36-14.23-36-36.26S140.45,44,162.07,44s35.72,14.9,35.72,38.27ZM146.63,73.18h30.61c-1.48-8.6-7-13.7-15.44-13.7C154.15,59.48,148.24,63.51,146.63,73.18Z" />
        <path d="M230.68,92.92h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.72s-2.55-6.17-11.95-7.65l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21C231.75,52.1,242.63,44,262.9,44c23.37,0,31.56,10.2,32.23,23.77H275.66c-.27-7.52-5.24-9.81-12.76-9.81s-10.6,2.56-10.6,6.58c0,4.17,3.35,5.91,10.07,7L272.71,73c16.38,2.55,24.3,9.13,24.3,21.49C297,108.9,284.79,117,264,117,242.76,117,231.89,110.1,230.68,92.92Z" />
        <path d="M328.56,61V93.32c0,5.1,2.55,6,7.92,6h5.37v15.58a79.6,79.6,0,0,1-9.66.53c-17.32,0-24.58-5.64-24.58-19.87V61H297.27V45.65h10.34V25.11h20.95V45.65h13.29V61Z" />
        <path d="M393.42,44.84V63.91H388c-11.68,0-18.39,5.78-18.39,18.8v32.23H348.7V45.65h20.41V58.54c3-8.59,9.4-14,19.74-14C390.46,44.58,391.94,44.71,393.42,44.84Z" />
        <path d="M465.92,86.47H414.63c1.48,9.94,7.65,14.64,15.84,14.64,7,0,11.55-2.82,14-7.79h19.87c-3.76,14.64-17.19,23.23-33.84,23.23-21.48,0-36-14.23-36-36.26S408.59,44,430.21,44s35.71,14.9,35.71,38.27ZM414.76,73.18h30.62c-1.48-8.6-7-13.7-15.44-13.7C422.28,59.48,416.37,63.51,414.76,73.18Z" />
        <path d="M542.32,86.47H491c1.48,9.94,7.65,14.64,15.85,14.64,7,0,11.54-2.82,14-7.79h19.87C537,108,523.53,116.55,506.88,116.55c-21.49,0-36-14.23-36-36.26S485,44,506.61,44s35.71,14.9,35.71,38.27ZM491.17,73.18h30.61c-1.48-8.6-7-13.7-15.44-13.7C498.68,59.48,492.78,63.51,491.17,73.18Z" />
        <path d="M573.74,61V93.32c0,5.1,2.55,6,7.93,6H587v15.58a79.87,79.87,0,0,1-9.67.53c-17.32,0-24.57-5.64-24.57-19.87V61H542.46V45.65H552.8V25.11h20.94V45.65H587V61Z" />
        <path d="M590,92.92H610.4c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.72s-2.55-6.17-12-7.65l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21C591.06,52.1,601.94,44,622.21,44c23.37,0,31.56,10.2,32.23,23.77H635c-.27-7.52-5.24-9.81-12.76-9.81s-10.6,2.56-10.6,6.58c0,4.17,3.35,5.91,10.07,7L632,73c16.38,2.55,24.3,9.13,24.3,21.49,0,14.37-12.22,22.42-33,22.42C602.07,117,591.2,110.1,590,92.92Z" />
        <path d="M51.7,235.78A19.55,19.55,0,0,1,50,228c-4.43,5.91-12,9.4-22.15,9.4-14.51,0-25.25-7.38-25.25-21.21,0-12,7.12-19.87,27.12-21.62l10.88-.94c5.91-.67,9-2.42,9-6.85,0-4.16-2.28-7-10.61-7s-11.55,2-12.22,9.13H5.91c1.07-15.31,10.87-24,33.17-24,21.75,0,30.88,8.2,30.88,21.35V223c0,5.1.94,10.07,2.82,12.75Zm-2.15-27.26v-5.1c-1.48,1.61-3.76,2.28-7,2.69l-8.6.94c-7.11.8-10.07,3.49-10.07,8.32s3.63,7.92,10.21,7.92C42.16,223.29,49.55,219.4,49.55,208.52Z" />
        <path d="M123.8,165.69v19.07h-5.37c-11.68,0-18.4,5.77-18.4,18.8v32.22h-21V166.5H99.5v12.89c3-8.6,9.4-14,19.73-14C120.85,165.42,122.32,165.56,123.8,165.69Z" />
        <path d="M196.31,207.32H145c1.48,9.93,7.66,14.63,15.85,14.63,7,0,11.55-2.82,14-7.79H194.7c-3.76,14.64-17.19,23.23-33.84,23.23-21.49,0-36-14.23-36-36.25s14.1-36.26,35.72-36.26,35.72,14.91,35.72,38.27ZM145.15,194h30.61c-1.47-8.59-7-13.69-15.44-13.69C152.67,180.33,146.76,184.35,145.15,194Z" />
        <path d="M301.17,166.5l-25.11,72c-6,17.45-14,23.77-34.51,23.77-4,0-6-.14-8.19-.27V245.85h6.72c7.78,0,10.47-4.43,10.47-9.66,0-3.9-1.61-8.87-3.22-13.43L227.45,166.5H250l3.09,9.8c4,13.56,8.06,27.79,11.82,43.64,3.76-16,7.65-30.08,11.54-43.51l3.09-9.93Z" />
        <path d="M299.42,201.14c0-22,14.24-36.26,36.39-36.26s36.26,14.24,36.26,36.26-14.24,36.25-36.26,36.25S299.42,223.16,299.42,201.14Zm51.43,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S350.85,214.3,350.85,201.14Z" />
        <path d="M444,166.5v69.28H423.63V226c-4.57,6.45-11.55,11.41-21.75,11.41-13.7,0-24-8.32-24-25.51V166.5h21v41.22c0,7.65,3.35,11.82,11.14,11.82,6.45,0,13.16-4.17,13.16-14.37V166.5Z" />
        <path d="M498.82,165.69v19.07h-5.37c-11.68,0-18.4,5.77-18.4,18.8v32.22H454.1V166.5h20.41v12.89c3-8.6,9.41-14,19.74-14C495.87,165.42,497.34,165.56,498.82,165.69Z" />
        <path d="M500.43,213.76h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-11.95-7.66l-9-1.47c-14.64-2.29-23.37-8.87-23.37-21.22,0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.21,32.23,23.77H545.41c-.27-7.52-5.24-9.8-12.76-9.8s-10.6,2.55-10.6,6.58,3.35,5.91,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.43-33,22.43C512.51,237.8,501.64,231,500.43,213.76Z" />
        <path d="M570.92,212.69h23.63v23.09H570.92Z" />
      </g>
    </g>
  </svg>
);

FooterStreets.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterStreets;
