/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { Element } from 'react-scroll';
import slugify from 'slugify';
import { find, get, isNumber, reject } from 'lodash';
import smartquotes from 'smartquotes';

import Box from 'components/box';
import Button from 'components/blocks/button';
import Video from 'components/blocks/video';
import Map from 'components/blocks/map';
import Image from 'components/blocks/image';
import Collage from 'components/blocks/collage';
import Slideshow from 'components/blocks/slideshow';
import ContactNdc from 'components/blocks/contact-ndc';
import ImageText from 'components/blocks/image-text';
import List from 'components/blocks/list';
import Pullquote from 'components/blocks/pullquote';
import SubscribeAndSocial from 'components/blocks/subscribe-and-social';
import ListOfPeople from 'components/blocks/list-of-people';
import PartnersMapSection from 'components/partners-map-section';
import AboutOurImpact from 'components/about-our-impact';
import AboutOurProcess from 'components/about-our-process';
import FormCommunity from 'components/form-community';
import FormDonation from 'components/form-donation';
import FormVolunteer from 'components/form-volunteer';
import urlParse from 'helpers/urlParse';

import { Background, Container } from './page-content.css';

function fixId(id) {
  if (id.length >= 22 && id.startsWith('c')) {
    return id.slice(1);
  }
  return id;
}

function checkEmpty(arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === '') return true;
  }
  return true;
}

function emptyP(type, c) {
  if (type !== 'paragraph') {
    return false;
  }
  if (c.content.length === 1 && c.content[0].value === '') {
    return true;
  }
  return false;
}

export const withIndexedContent = document => {
  let doc;
  let index = 0;
  const addedIndex =
    document.content &&
    document.content.map(c => {
      let type = c.nodeType;
      type === 'embedded-entry-block' &&
        c.data.target.sys.contentType &&
        (type = c.data.target.sys.contentType.sys.id);
      let item;
      if (type !== 'blockScrollAnchor' && !emptyP(type, c)) {
        item = {
          index,
          type,
          ...c,
        };
        index++;
      } else {
        item = { type, ...c };
      }
      return item;
    });
  doc = {
    ...document,
    content:
      document.content &&
      addedIndex.map(c => {
        let item;
        if (isNumber(c.index)) {
          item = {
            prev: get(find(addedIndex, { index: c.index - 1 }), 'type'),
            next: get(find(addedIndex, { index: c.index + 1 }), 'type'),
            ...c,
          };
        } else {
          item = { ...c };
        }

        return item;
      }),
  };
  return doc;
};

const options = pageColor => {
  const data = useStaticQuery(graphql`
    query allImages {
      allContentfulAsset(filter: { file: { url: { ne: null } } }) {
        edges {
          node {
            id
            contentful_id
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);
  // const data = null;
  return {
    renderText: text => {
      const quotes = smartquotes(text);
      return quotes.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderMark: {
      [MARKS.BOLD]: text => {
        return (
          <b className="font-bold" key={`${text}-key`}>
            {text}
          </b>
        );
      },
      [MARKS.ITALIC]: text => {
        return (
          <i className="italic" key={`${text}-key`}>
            {text}
          </i>
        );
      },
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const contentType =
          (node.data.target.sys.contentType &&
            node.data.target.sys.contentType.sys.id) ||
          null;

        if (contentType == 'blockContactNdc') {
          const { prev, next } = node;
          const { title, office1, office2 } = node.data.target.fields;
          return (
            <ContactNdc
              title={title['en-US']}
              office1={office1['en-US']}
              office2={office2['en-US']}
              pageColor={pageColor}
              prev={prev}
              next={next}
            />
          );
        }

        if (contentType == 'blockMap') {
          const { prev, next } = node;
          // const { title, office1, office2 } = node.data.target.fields;
          const { mapCenter, mapMarker, title } = node.data.target.fields;
          return (
            <Map
              mapCenter={mapCenter && mapCenter['en-US']}
              mapMarker={mapMarker && mapMarker['en-US']}
              title={title && title['en-US']}
              pageColor={pageColor}
              prev={prev}
              next={next}
            />
          );
        }

        if (contentType == 'blockCta') {
          const { text, link, opensNewTab } = node.data.target.fields;
          return (
            <Button
              text={text['en-US']}
              isInternal={urlParse(link['en-US']).isInternal}
              link={urlParse(link['en-US']).url}
              opensNewTab={opensNewTab['en-US']}
              align="center"
            />
          );
        }

        if (contentType == 'blockScrollAnchor') {
          // do something to k/eep track of scroll
          const { text, type } = node.data.target.fields;
          if (type['en-US'] === 'Scroll Anchor') {
            return <Element name={slugify(text['en-US'], { lower: true })} />;
          }
          if (type['en-US'] === 'Partners Map Section') {
            return <PartnersMapSection pageColor={pageColor} />;
          }
          if (type['en-US'] === 'About Our Impact') {
            return <AboutOurImpact pageColor={pageColor} />;
          }
          if (type['en-US'] === 'About Our Process') {
            return <AboutOurProcess pageColor={pageColor} />;
          }
          if (type['en-US'] === 'Community Intake Form') {
            return <FormCommunity pageColor={pageColor} />;
          }
          if (type['en-US'] === 'Donation Form') {
            return <FormDonation pageColor={pageColor} />;
          }
          if (type['en-US'] === 'Volunteer Links') {
            return <FormVolunteer pageColor={pageColor} options={options} />;
          }
          return <div />;
        }

        if (contentType == 'blockImageOrVideo') {
          const { prev, next } = node;
          const { image, videoUrl, blockWidth } = node.data.target.fields;
          let imageAsset;
          {
            image
              ? (imageAsset = data.allContentfulAsset.edges.find(
                  edge =>
                    edge.node.contentful_id === fixId(image['en-US'].sys.id)
                ))
              : (imageAsset = null);
          }
          // return image component
          if (image && imageAsset) {
            return (
              <Image
                image={image && imageAsset && imageAsset.node}
                caption={
                  image &&
                  image['en-US'].fields.description &&
                  image['en-US'].fields.description['en-US']
                }
                blockWidth={blockWidth['en-US']}
                prev={prev}
                next={next}
              />
            );
          }
          // return video component
          if (videoUrl) {
            return (
              <Video
                video={videoUrl['en-US']}
                blockWidth={blockWidth['en-US']}
                prev={prev}
                next={next}
              />
            );
          }
        }

        if (contentType == 'blockImageText') {
          const { prev, next } = node;
          const { image, imageSide, imageType, text } = node.data.target.fields;
          let imageAsset;
          {
            image
              ? (imageAsset = data.allContentfulAsset.edges.find(
                  edge =>
                    edge.node.contentful_id === fixId(image['en-US'].sys.id)
                ))
              : (imageAsset = null);
          }
          return (
            <ImageText
              pageColor={pageColor}
              imageSide={imageSide['en-US']}
              imageType={imageType['en-US']}
              image={image && imageAsset && imageAsset.node}
              text={text['en-US']}
              options={options}
              prev={prev}
              next={next}
            />
          );
        }

        if (contentType == 'blockSlideshow') {
          const { prev, next } = node;
          const { images, displayAs, blockWidth } = node.data.target.fields;
          let arrOfImages = [];

          images['en-US'].map(({ fields, sys }) => {
            let imageAsset;

            imageAsset = data.allContentfulAsset.edges.find(
              edge => edge.node.contentful_id === fixId(sys.id)
            );

            arrOfImages.push({
              id: sys.id,
              title: fields.title && fields.title['en-US'],
              description: fields.description && fields.description['en-US'],
              image: imageAsset && imageAsset.node,
            });
          });
          if (displayAs['en-US'] === 'Collage') {
            return (
              <Collage
                blockWidth={blockWidth && blockWidth['en-US']}
                list={arrOfImages}
                prev={prev}
                next={next}
              />
            );
          }
          if (displayAs['en-US'] === 'Carousel') {
            return (
              <Slideshow
                blockWidth={blockWidth && blockWidth['en-US']}
                list={arrOfImages}
                pageColor={pageColor}
                prev={prev}
                next={next}
              />
            );
          }
        }

        if (contentType == 'blockListOfPeople') {
          const { prev, next } = node;
          const { listOfPeople } = node.data.target.fields;
          let arrOfPeople = [];
          listOfPeople['en-US'].map(({ fields, sys }) => {
            let imageAsset;
            {
              fields.image
                ? (imageAsset = data.allContentfulAsset.edges.find(
                    edge =>
                      edge.node.contentful_id ===
                      fixId(fields.image['en-US'].sys.id)
                  ))
                : (imageAsset = null);
            }
            arrOfPeople.push({
              id: sys.id,
              text: fields.text['en-US'],
              image: imageAsset && imageAsset.node,
            });
          });
          return (
            <ListOfPeople
              list={arrOfPeople}
              pageColor={pageColor}
              prev={prev}
              next={next}
            />
          );
        }

        if (contentType == 'blockImage') {
          const { prev, next } = node;
          const { image } = node.data.target.fields;
          let imageAsset;
          {
            image
              ? (imageAsset = data.allContentfulAsset.edges.find(
                  edge =>
                    edge.node.contentful_id === fixId(image['en-US'].sys.id)
                ))
              : (imageAsset = null);
          }
          return (
            <Image
              image={image && imageAsset && imageAsset.node}
              prev={prev}
              next={next}
            />
          );
        }

        if (contentType == 'blockSubscribeAndSocial') {
          const { prev, next } = node;
          const {
            facebookLink,
            instagramLink,
            twitterLink,
            linkedinLink,
            newsletterTitle,
            socialLinksTitle,
          } = node.data.target.fields;
          return (
            <SubscribeAndSocial
              pageColor={pageColor}
              facebookLink={facebookLink && facebookLink['en-US']}
              instagramLink={instagramLink && instagramLink['en-US']}
              twitterLink={twitterLink && twitterLink['en-US']}
              linkedinLink={linkedinLink && linkedinLink['en-US']}
              newsletterTitle={newsletterTitle && newsletterTitle['en-US']}
              socialLinksTitle={socialLinksTitle && socialLinksTitle['en-US']}
              prev={prev}
              next={next}
            />
          );
        }

        if (contentType == 'blockList') {
          const { prev, next } = node;
          const {
            columnCount,
            includeIcon,
            list,
            textColor,
          } = node.data.target.fields;
          return (
            <List
              pageColor={pageColor}
              columnCount={columnCount['en-US']}
              includeIcon={includeIcon['en-US']}
              textColor={textColor['en-US']}
              list={list['en-US']}
              option={options}
              prev={prev}
              next={next}
            />
          );
        }

        if (contentType == 'blockPullquote') {
          const { prev, next } = node;
          const { image, quote, attribution } = node.data.target.fields;
          let imageAsset;
          {
            image
              ? (imageAsset = data.allContentfulAsset.edges.find(
                  edge =>
                    edge.node.contentful_id === fixId(image['en-US'].sys.id)
                ))
              : (imageAsset = null);
          }
          return (
            <Pullquote
              pageColor={pageColor}
              image={image && imageAsset && imageAsset.node}
              quote={quote['en-US']}
              attribution={attribution && attribution['en-US']}
              prev={prev}
              next={next}
            />
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (!(node.data && node.data.target && node.data.target.fields)) {
          return;
        }
        const { title, description, file } = node.data.target.fields;
        const { prev, next } = node;
        const mimeType = file['en-US'].contentType;
        const mimeGroup = mimeType.split('/')[0];
        let imageAsset;
        switch (mimeGroup) {
          case 'image':
            imageAsset = data.allContentfulAsset.edges.find(
              edge => edge.node.contentful_id === fixId(node.data.target.sys.id)
            );

            return (
              <Image
                image={imageAsset && imageAsset.node}
                title={title ? title['en-US'] : null}
                caption={description ? description['en-US'] : null}
                prev={prev}
                next={next}
              />
            );
          case 'application':
            return (
              <a
                alt={description ? description['en-US'] : null}
                href={file['en-US'].url}
              >
                {title ? title['en-US'] : file['en-US'].details.fileName}
              </a>
            );
          // default:
          //   return (
          //     <span style={{ backgroundColor: 'red', color: 'white' }}>
          //       {' '}
          //       {mimeType} embedded asset{' '}
          //     </span>
          //   );
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (checkEmpty(children)) {
          const filtered = reject(node.content, i => {
            if (i.nodeType === 'text' && i.value === '') {
              return true;
            }
            if (
              i.nodeType === 'embedded-entry-inline' &&
              i.data.target &&
              i.data.target.sys.contentType &&
              i.data.target.sys.contentType.sys.id === 'blockCta'
            ) {
              return true;
            }
            return false;
          });
          return (
            <Box pageColor={pageColor}>
              <p
                className={`${
                  filtered.length == 0 ? 'text-center' : ''
                } ${children[0] &&
                  children[0][1] &&
                  children[0][1].slice(0, 1) === '“' &&
                  'hanging'}`}
              >
                {children}
              </p>
            </Box>
          );
        }
      },
      [BLOCKS.HR]: () => {
        return (
          <Box pageColor={pageColor}>
            <hr />
          </Box>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <Box pageColor={pageColor}>
            <h1>{children}</h1>
          </Box>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <Box pageColor={pageColor}>
            <h2>{children}</h2>
          </Box>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const { prev } = node;
        return (
          <Box
            margin={
              prev &&
              (prev === 'heading-3' ||
                prev === 'paragraph' ||
                prev === 'blockCta')
                ? 'mt-10 sm:mt-20 mx-auto'
                : 'mx-auto'
            }
            pageColor={pageColor}
          >
            <h3>{children}</h3>
          </Box>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return (
          <Box pageColor={pageColor}>
            <h4>{children}</h4>
          </Box>
        );
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return (
          <Box pageColor={pageColor}>
            <h5>{children}</h5>
          </Box>
        );
      },
      [BLOCKS.UL_LIST]: node => {
        return (
          <List
            pageColor={pageColor}
            columnCount={false}
            includeIcon={true}
            textColor={false}
            list={node}
            options={options}
            addUl
          />
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const { description, file } = node.data.target.fields;
        return (
          <a
            alt={description ? description['en-US'] : null}
            href={file['en-US'].url}
          >
            {children}
          </a>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: node => {
        const contentType =
          (node.data.target.sys.contentType &&
            node.data.target.sys.contentType.sys.id) ||
          null;

        if (contentType == 'blockList') {
          const {
            columnCount,
            includeIcon,
            list,
            textColor,
          } = node.data.target.fields;
          return (
            <List
              pageColor={pageColor}
              columnCount={columnCount['en-US']}
              includeIcon={includeIcon['en-US']}
              textColor={textColor['en-US']}
              list={list['en-US']}
              options={options}
              isInline
            />
          );
        }
        if (contentType == 'blockCta') {
          const { text, link, opensNewTab } = node.data.target.fields;
          return (
            <Button
              text={text['en-US']}
              isInternal={urlParse(link['en-US']).isInternal}
              link={urlParse(link['en-US']).url}
              opensNewTab={opensNewTab['en-US']}
              align="center"
              isInline
            />
          );
        }
      },
    },
  };
};

const PageContent = ({ content, pageColor, margin }) => (
  <Background>
    <Container margin={margin}>
      {content &&
        documentToReactComponents(
          withIndexedContent(content.json),
          options(pageColor)
        )}
    </Container>
  </Background>
);

PageContent.propTypes = {
  content: PropTypes.object.isRequired,
  pageColor: PropTypes.string,
  margin: PropTypes.string,
};

export default PageContent;
