import nanostyled from 'nanostyled';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

export const Container = nanostyled('section', {
  base: 'max-w-7xl py-16 xs:px-12 -mx-3px sm:mx-auto',
});

export const Overlay = nanostyled('div', {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 opacity-0 transition duration-200 ease-in-out group-hover:opacity-100',
  color: 'bg-brand-blue',
});

export const Title = nanostyled('h4', {
  base:
    'font-extrabold text-2xl sm:text-3p5xl xs:mr-8 transition duration-200 ease-in-out group-hover:text-white',
});

export const Description = nanostyled('p', {
  base:
    'max-w-4xl tracking-tight leading-normal font-light font-micro text-lg xs:text-xl sm:text-2xl my-5 xs:my-10 group-hover:text-white',
  margin: '',
});

export const StyledLink = nanostyled(Link, {
  base:
    'font-extrabold text-base xs:text-lg sm:text-xl uppercase border-b-3 border-solid mt-5 mb-2 xs:-mb-1 pb-1 xs:mt-3 xs:mt-0 flex-shrink-0 self-start xs:self-end transition duration-200 ease-in-out',
  color: 'border-black',
});

export const Flex = nanostyled('div', {
  base: 'flex flex-col xs:flex-row',
  flex: '',
  margin: '',
});

export const Item = nanostyled(Link, {
  base: 'flex border-2 border-solid px-8 xxs:px-10 py-16 relative group',
  color: 'border-black',
  width: 'w-full',
  mv: '',
});

export const Color = nanostyled('div', {
  base: 'lighten -z-1 absolute top-0 bottom-0 right-0 left-0',
  bg: '',
});

export const Type = nanostyled('h4', {
  base: 'mb-0 mr-4 !text-xl transition duration-200 ease-in-out group-hover:text-white',
  color: '',
});

export const Date = nanostyled('span', {
  base: 'mb-0 text-xl transition duration-200 ease-in-out group-hover:text-white',
});

export const StyledImg = nanostyled(Img, {
  base: 'w-full absolute top-0 bottom-0 left-0 right-0 opacity-15',
});
