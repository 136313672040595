import nanostyled from 'nanostyled';
import { Link } from 'gatsby';

export const Container = nanostyled(Link, {
  base: 'w-56 mt-8 mr-8 fixed top-0 right-0 z-10 no-underline hidden sm:block',
});

export const StyledSvg = nanostyled('svg', {
  base: 'w-full',
});
