import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderArrows1 from 'images/header/header-arrows1.png';
import patternHeaderArrows2 from 'images/header/header-arrows2.png';
import patternHeaderArrows3 from 'images/header/header-arrows3.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const Rect = posed.rect({
  from: { width: 0 },
  to: {
    width: 373,
    delay: 600,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const Rect1 = posed.rect({
  from: {},
  to: {
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderArrows = ({ style, isVisible }) => (
  <>
    <svg
      className="header-arrows hidden sm:block"
      viewBox="0 0 802.3 71.94"
      style={style}
    >
      <defs>
        <pattern
          id="headerarrows-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 1975 180"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderArrows1}
            width="1975"
            height="180"
          />
        </pattern>
        <pattern
          id="headerarrows-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 2057 70"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderArrows2}
            width="2057"
            height="70"
          />
        </pattern>
        <pattern
          id="headerarrows-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 182 168"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderArrows3}
            width="182"
            height="168"
          />
        </pattern>
        <clipPath id="headerarrows-clip-3">
          <path
            transform="scale(1.03) translate(-22 -0)"
            d="M775.63,11c9.29.79,14.59,7,15.71,16.65-1.5,8.24-6.27,13-16.28,14.93-8.41-1.86-15.49-5.69-16.71-13.67C759.92,19.8,765.55,14.41,775.63,11Z"
          />
        </clipPath>
        <clipPath id="headerarrows-rect-animate">
          <Rect
            pose={isVisible ? 'to' : 'from'}
            x="384.01"
            y="18.3"
            width="373"
            height="16"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headerarrows-1)"
          strokeWidth="20px"
          d="M323.56,21.55,287.21,56.07,258.79,21.71,215.51,55.16,188.68,22.71l-40.36,31.8L124.78,23.05,75.23,56.66,51,27.06,5.77,55.48"
        />
        <Rect1
          x="384.01"
          y="18.3"
          width="373"
          height="16"
          fill="url(#headerarrows-2)"
          clipPath="url(#headerarrows-rect-animate)"
        />
        <Path
          fill="none"
          stroke="url(#headerarrows-3)"
          strokeWidth="10px"
          d="M775.63,11c9.29.79,14.59,7,15.71,16.65-1.5,8.24-6.27,13-16.28,14.93-8.41-1.86-15.49-5.69-16.71-13.67C759.92,19.8,765.55,14.41,775.63,11Z"
          clipPath="url(#headerarrows-clip-3)"
          transform="scale(1.05) translate(-40 -2)"
        />
      </G>
    </svg>
    <svg
      className="header-arrows sm:hidden"
      viewBox="0 0 324 71.94"
      style={style}
    >
      <defs>
        <pattern
          id="m-headerarrows-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 1975 180"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderArrows1}
            width="1975"
            height="180"
          />
        </pattern>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headerarrows-1)"
          strokeWidth="20px"
          d="M323.56,21.55,287.21,56.07,258.79,21.71,215.51,55.16,188.68,22.71l-40.36,31.8L124.78,23.05,75.23,56.66,51,27.06,5.77,55.48"
        />
      </G>
    </svg>
  </>
);

HeaderArrows.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderArrows;
