import nanostyled from 'nanostyled';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const Container = nanostyled('section', {
  base:
    'bg-brand-blue flex flex-col lg:flex-row pointer-events-auto overflow-x-hidden',
});

export const Left = nanostyled('div', {
  base:
    'w-full relative lg:w-2/3 xxl:w-1/2 py-16 px-8 xxs:px-10 sm:p-32 flex items-center justify-center',
});

export const Right = nanostyled('div', {
  base:
    'w-full lg:w-1/3 xxl:w-1/2 border-t-2 border-b-2 lg:border-t-0 lg:border-b-0 lg:border-l-2 border-white border-solid flex flex-wrap flex-col xxl:flex-row',
});

export const ImageLink = nanostyled(Link, {
  base:
    'h-1/4vh xxl:h-1/3vh xxl:w-1/2 flex relative items-center border-solid border-white group cursor-pointer',
  border: '',
});

export const ImageA = nanostyled('a', {
  base:
    'h-1/4vh xxl:h-1/3vh xxl:w-1/2 flex relative items-center border-solid border-white group cursor-pointer',
  border: '',
});

export const StyledImg = nanostyled(Img, {
  base: 'w-full absolute top-0 bottom-0 left-0 right-0',
});

export const Overlay = nanostyled('div', {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 bg-brand-blue opacity-85 transition duration-200 ease-in-out group-hover:opacity-0',
});

export const Text = nanostyled('p', {
  base:
    'm-8 xxs:m-10 text-3xl xs:text-4xl sm:text-5xl xxl:text-5p5xl text-white font-light relative z-10 transition duration-200 ease-in-out group-hover:opacity-0',
});
