import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import { SearchWrapper, SearchInput, Submit } from './search.css';

export default connectSearchBox(({ refine, setShowSearch, ...rest }) => (
  <SearchWrapper>
    <SearchInput
      type="text"
      placeholder="Search NDC..."
      aria-label="Search"
      onChange={e => refine(e.target.value)}
      {...rest}
      onKeyPress={e => {
        e.key === 'Enter' ? setShowSearch(true) : null;
      }}
    />
    <Submit onClick={() => setShowSearch(true)}>
      <FontAwesomeIcon icon={faSearch} />
    </Submit>
  </SearchWrapper>
));
