import nanostyled from 'nanostyled';

export const Background = nanostyled('section', {
  base:
    'w-full mt-16 sm:mt-32 -mb-20 sm:-mb-36 py-16 sm:py-32 px-4 xs:px-10 sm:px-20',
  bg: '',
});

export const Container = nanostyled('div', {
  base: 'w-full max-w-4xl',
  margin: 'mx-auto',
});

export const Type = nanostyled('div', {
  base: 'typography white',
});

export const Flex = nanostyled('div', {
  base: 'flex flex-col sm:flex-row',
  justify: '',
});
