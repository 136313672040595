import nanostyled from 'nanostyled';
import Img from 'gatsby-image';

export const Container = nanostyled('section', {
  base: 'w-full typography flex flex-col',
  align: 'mx-auto',
  width: 'max-w-5xl',
  margin: '',
});

export const Flex = nanostyled('div', {
  base: 'flex',
  order: '',
});

export const StyledImage = nanostyled(Img, {
  base: 'max-w-screen',
});

export const Margin = nanostyled('div', {
  base: '',
  margin: '',
});

export const CaptionMargin = nanostyled('div', {
  base: 'flex',
  margin: '',
});

export const Caption = nanostyled('caption', {
  base:
    'mx-auto max-w-4xl w-full text-left font-micro font-light leading-normal text-base sm:text-lg mt-4 tracking-tight text-brand-gray',
  align: '',
});
