import nanostyled from 'nanostyled';
import { Link } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll';
import Img from 'gatsby-image';

export const Container = nanostyled('section', {
  base: 'w-full flex flex-col',
  width: '',
  align: '',
  height: '',
});

export const Split = nanostyled('section', {
  base: ' flex w-full flex-wrap -mx-px',
  height: '',
  flex: '',
});

export const LinkItem = nanostyled('div', {
  base: 'w-full text-white',
  color: '',
});

export const Square = nanostyled('div', {
  base: 'aspect-ratio-square',
});

export const StyledScrollLink = nanostyled(ScrollLink, {
  base: 'font-extrabold uppercase cursor-pointer hover:text-white leading-snug',
  border: 'border-b-2 border-solid border-white',
});

export const ImageLinkWrapper = nanostyled(Link, {
  base: 'w-full sm:w-1/2 lg:w-1/3 border-solid border border-white block',
});

export const ImageAWrapper = nanostyled('a', {
  base:
    'block no-underline w-full sm:w-1/2 lg:w-1/3 border-solid border border-white',
});

export const ImageLink = nanostyled('div', {
  base:
    'h-0 w-full aspect-ratio-square flex relative items-center border-solid border-white group cursor-pointer',
  border: '',
});

export const StyledImg = nanostyled(Img, {
  base: 'w-full absolute top-0 bottom-0 left-0 right-0',
});

export const Overlay = nanostyled('div', {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 opacity-85 transition duration-200 ease-in-out group-hover:opacity-0',
  color: 'bg-brand-blue',
});

export const DrawOverlay = nanostyled('div', {
  base: 'w-full absolute top-0 bottom-0 left-0 right-0 header-overlay lighten',
  color: 'bg-brand-blue',
});

export const TextContainer = nanostyled('div', {
  base:
    'm-4 sm:m-10 absolute inset-0 z-10 flex flex-col justify-center transition duration-200 ease-in-out group-hover:opacity-0',
});

export const Text = nanostyled('h3', {
  base: 'text-4xl xs:text-6xl sm:text-4xl md:text-5xl text-white font-light',
});

export const Icon = nanostyled(Img, {
  base:
    'h-20 xs:h-40 sm:h-32 md:h-40 w-20 xs:w-40 sm:w-32 md:w-40 -mt-8 xs:-mt-10 sm:-mt-16 md:-mt-20 mb-2 filter-invert',
});
