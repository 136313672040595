import nanostyled from 'nanostyled';
import Img from 'gatsby-image';

export const Container = nanostyled('section', {
  base: 'max-w-6xl w-full typography overflow-hidden',
  align: 'mx-auto',
  margin: '',
});

export const Flex = nanostyled('div', {
  base: 'flex flex-wrap -mx-4',
  order: '',
});

export const StyledImage = nanostyled(Img, {
  base: 'rounded-full h-0 aspect-ratio-square',
});

export const Item = nanostyled('div', {
  base:
    'w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 px-4 border-solid border-b-2 -mb-3px mt-12',
  color: 'border-gray-400',
});

export const Text = nanostyled('div', {
  base: 'list-of-people mb-10',
});

export const Margin = nanostyled('div', {
  base: 'mx-4 xs:mx-10 sm:mx-20',
});
