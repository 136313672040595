import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { Container, Wrapper, Margin } from './video.css';

const getWidth = width => {
  switch (width) {
    case 'Text Width':
      return 'max-w-4xl';
    case 'Extra Wide':
      return 'max-w-6xl';
    case 'Full Bleed':
      return 'max-w-screen';
    default:
      return 'max-w-4xl';
  }
};

const getMargin = width => {
  switch (width) {
    case 'Text Width':
      return 'mx-4 xs:mx-10 sm:mx-20';
    case 'Extra Wide':
      return 'xs:mx-4 sm:mx-10';
    case 'Full Bleed':
      return '';
    default:
      return 'mx-4 xs:mx-10 sm:mx-20';
  }
};

const Video = ({ video, blockWidth, next, prev }) =>
  video && (
    <Margin margin={getMargin(blockWidth)}>
      <Wrapper
        width={getWidth(blockWidth)}
        margin={`${next ? 'mb-10 sm:mb-20' : ''} ${
          prev ? 'mt-10 sm:mt-20' : ''
        }`}
      >
        <Container style={{ paddingTop: '56.25%' }}>
          <ReactPlayer
            style={{ position: 'absolute', top: 0, left: 0 }}
            width="100%"
            height="100%"
            url={video}
            volume={0}
            muted
            light
            playing
          />
        </Container>
      </Wrapper>
    </Margin>
  );

Video.propTypes = {
  video: PropTypes.string.isRequired,
  blockWidth: PropTypes.string,
};

export default Video;
