import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import slugify from 'slugify';
import { Helmet } from 'react-helmet';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import Layout from 'components/layout';
import PageContent from 'components/page-content';
import CaseStudyHeader from 'components/case-study-header';
import CaseStudyNav from 'components/case-study-nav';

const CaseStudyTemplate = ({ data, pageContext }) => {
  const {
    pageColor,
    title,
    content,
    projectNumber,
    description,
    headerImage,
    projectIcon,
    whatWeDid,
    partners,
    supporters,
  } = data.contentfulCaseStudy;
  const { previous, next } = pageContext;

  const pageTitleFull = title
    ? `${title} | The Neighborhood Design Center`
    : 'The Neighborhood Design Center';
  const fullDescription =
    (description && description.description.substring(0, 155)) ||
    (content &&
      content.json &&
      documentToPlainTextString(content.json).substring(0, 155)) ||
    null;
  const imageUrl = (headerImage && `https:${headerImage.fixed.src}`) || null;

  return (
    <Layout menuColor="white">
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>
      <div className="bg-white boundary overflow-x-hidden">
        <CaseStudyHeader
          title={title}
          pageColor={slugify(pageColor, { lower: true })}
          projectNumber={projectNumber}
          description={description && description.description}
          projectIcon={projectIcon}
          image={headerImage}
          whatWeDid={whatWeDid}
          partners={partners}
          supporters={supporters}
          previous={previous}
          next={next}
        />
        <PageContent
          // margin="mt-16 sm:mt-32 mb-0 sm:mb-0"
          content={content}
          pageColor={slugify(pageColor, { lower: true })}
        />
        <CaseStudyNav
          pageColor={slugify(pageColor, { lower: true })}
          previous={previous}
          next={next}
        />
      </div>
    </Layout>
  );
};

CaseStudyTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

export default CaseStudyTemplate;

export const query = graphql`
  query CaseStudyBySlug($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      id
      title
      categories
      pageColor
      projectNumber
      whatWeDid
      partners
      supporters
      description {
        description
      }
      headerImage {
        fluid(maxWidth: 4000) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(width: 1024, height: 512) {
          src
        }
      }
      projectIcon {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      content {
        id
        json
      }
    }
  }
`;
