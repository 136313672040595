import React, { useState, useEffect, createRef, memo } from 'react';
import PropTypes from 'prop-types';
import {
  InstantSearch,
  Index,
  Hits,
  connectStateResults,
  PoweredBy,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { Portal } from 'react-portal';

import Close from 'images/menu-close.svg';

import { Root, HitsWrapper } from './search.css';
import * as hitComps from './hitComps';
import Input from './input';

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? children : `No results for '${state.query}'`
);
const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? 's' : ''}`
);

const Search = ({ indices, collapse, hitsAsGrid }) => {
  const ref = createRef();
  const [loaded, setLoaded] = useState(false);
  const [query, setQuery] = useState('');
  const [focus, setFocus] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  const searchClient = {
    search(requests) {
      if (loaded === false) {
        setLoaded(true);
        return;
      }
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }
      return algoliaClient.search(requests);
    },
  };

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (key === 'Escape') {
        focus && setFocus(false);
        showSearch && setShowSearch(false);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    disableScrolling(showSearch);
  }, [showSearch]);

  const disableScrolling = isOpen => {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  };
  //useClickOutside(ref, () => setFocus(false));
  // return <div />;
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      onSearchStateChange={({ query }) => setQuery(query)}
      root={{ Root, props: { ref } }}
    >
      <Input
        onFocus={() => setFocus(true)}
        {...{ collapse, focus, setShowSearch }}
      />
      <Portal>
        {query && showSearch && (
          <>
            <button
              onClick={() => {
                setFocus(false);
                setShowSearch(false);
              }}
              className="fixed z-50 top-0 right-0 bottom-0 left-0 w-full bg-white opacity-50"
            />
            <HitsWrapper
              className="typography black a-no-border"
              show={query.length > 0 && showSearch ? 'block' : 'hidden'}
              asGrid={hitsAsGrid}
            >
              <button
                className="fixed flex flex-col items-center justify-center mx-2 xs:mx-4 w-12 text-md menu-search"
                onClick={() => {
                  setFocus(false);
                  setShowSearch(false);
                }}
              >
                <Close className={'w-8 h-6 menu-black'} />
                <span
                  className={
                    'mt-3 xs:mt-4 uppercase font-micro font-bold text-xxs xs:text-xs text-black'
                  }
                >
                  close
                </span>
              </button>
              <h3 className="!mt-0">Search Results</h3>
              <hr className="!border-white !mt-0 !mb-10" />
              {indices.map(({ name, title, hitComp }) => (
                <Index key={name} indexName={name}>
                  <header className="flex items-baseline">
                    <h2 className="!mt-2 !mb-0 mr-4">{title}</h2>
                    <Stats />
                  </header>
                  <Results>
                    <Hits
                      hitComponent={hitComps[hitComp](() => setFocus(false))}
                    />
                  </Results>
                  <hr className="!border-white !my-10" />
                </Index>
              ))}
              <PoweredBy />
            </HitsWrapper>
          </>
        )}
      </Portal>
    </InstantSearch>
  );
};

Search.propTypes = {
  indices: PropTypes.objectsRequired,
  collapse: PropTypes.bool,
  hitsAsGrid: PropTypes.bool,
};

export default memo(Search);
