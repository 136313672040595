import React from 'react';
import PropTypes from 'prop-types';

import MdMap from 'images/md-map.svg';
import Button from 'components/blocks/button';
import { Container, Background, Type } from './partners-map-section.css';

const mapUrl =
  'https://ndc-md-org.maps.arcgis.com/apps/Embed/index.html?webmap=cfd398a1c5a64435a01f200c6a035e0a&extent=-78.7892,38.1107,-74.7325,39.8759&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light';

const PartnersMapSection = ({ pageColor }) => (
  <Background bg={`bg-brand-${pageColor}`}>
    <Container>
      <a href={mapUrl}>
        <MdMap />
      </a>
      <Type>
        <h3>See who we’ve worked with since 1968.</h3>
      </Type>
      <div className="text-center mt-8 sm:mt-16">
        <Button
          text="Explore the Map"
          link={mapUrl}
          isInternal={false}
          opensNewTab={true}
          isInline
        />
        <Button
          text="Donate and Sponsor"
          link="/donate"
          isInternal={true}
          isInline
        />
      </div>
    </Container>
  </Background>
);

PartnersMapSection.propTypes = {
  pageColor: PropTypes.string,
};

export default PartnersMapSection;
