import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Head from 'components/head';
import Footer from 'containers/footer';
import { appContext } from 'store/createContext';

// class Layout extends React.Component {
//   constructor(props) {
//     super(props);
//   }

//   componentDidMount() {
//     const { changePage, menuColor } = this.props;
//     // const test = useContext(changePage);
//     console.log(this.context);
//     console.log('test');
//     changePage && changePage(menuColor || 'black');
//   }

//   render() {
//     const { children } = this.props;

//     return (
//       <div>
//         <GlobalStyle />
//         <Head />
//         <div className="slide-boundary z-1 relative">{children}</div>
//         <Footer />
//       </div>
//     );
//   }
// }

const Layout = ({ menuColor, children }) => {
  // test
  const { changePage } = useContext(appContext);
  useEffect(() => changePage(menuColor || 'black'), []);

  return (
    <div>
      <Head />
      <div className="slide-boundary z-1 relative">{children}</div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  changePage: PropTypes.func.isRequired,
  menuColor: PropTypes.string,
};

export default Layout;
