import nanostyled from 'nanostyled';
import Img from 'gatsby-image';

export const Container = nanostyled('section', {
  base: 'max-w-4xl w-full typography',
  align: 'mx-auto',
  margin: '',
});

export const Flex = nanostyled('div', {
  base: 'flex',
  order: '',
});

export const StyledImage = nanostyled(Img, {
  base: 'w-64 h-64 rounded-full mr-16',
});

export const Margin = nanostyled('div', {
  base: '',
  margin: 'mx-4 xs:mx-10 sm:mx-20',
});
