import nanostyled from 'nanostyled';
import Img from 'gatsby-image';

export const Container = nanostyled('section', {
  base: 'max-w-6xl w-full typography',
  align: 'mx-auto',
  color: '',
  margin: '',
});

export const Flex = nanostyled('div', {
  base: 'flex flex-col sm:flex-row',
  order: '',
});

export const StyledImage = nanostyled(Img, {
  base: 'w-48 h-48 lg:w-64 lg:h-64 flex-shrink-0 rounded-full mr-8 lg:mr-16',
});

export const Quote = nanostyled('h3', {
  base: 'hanging',
});

export const Attribution = nanostyled('h4', {
  base: 'mt-4 xs:mt-6 sm:mt-8 hanging-12',
});

export const Margin = nanostyled('div', {
  base: 'mx-4 xs:mx-10 sm:mx-20',
});
