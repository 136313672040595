import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import patternHeaderDashed1 from 'images/header/header-dashed1.png';
import patternHeaderDashed2 from 'images/header/header-dashed2.png';
import patternHeaderDashed3 from 'images/header/header-dashed3.png';
import patternHeaderDashed4 from 'images/header/header-dashed4.png';
import patternHeaderDashed5 from 'images/header/header-dashed5.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const Rect = posed.rect({
  from: { width: 0 },
  to: {
    width: 350,
    delay: 1200,
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const Rect2 = posed.rect({
  from: {},
  to: {
    transition: ({ dur = 600 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const HeaderDashed = ({ style, isVisible }) => (
  <>
    <svg
      className="header-dashed lg:hidden"
      viewBox="0 0 486.42 245.5"
      style={style}
    >
      <defs>
        <pattern
          id="m-headerdashed-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 359 305"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderDashed1}
            width="359"
            height="305"
          />
        </pattern>
        <pattern
          id="m-headerdashed-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 341 282"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderDashed2}
            width="341"
            height="282"
          />
        </pattern>
        <clipPath id="m-headerdashed-clip-2">
          <path
            transform="scale(1.6) translate(-160 -15)"
            d="M265,12.5l166,2-2,130"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#m-headerdashed-1)"
          strokeWidth="30px"
          transform="scale(1.1) translate(-8 -10)"
          d="M190.49,233h-173l-5-143"
        />
        <Path
          fill="none"
          stroke="url(#m-headerdashed-2)"
          strokeWidth="30px"
          transform="scale(1.1) translate(12 -13)"
          clipPath="url(#m-headerdashed-clip-2)"
          d="M265,12.5l166,2-2,130"
        />
      </G>
    </svg>

    <svg
      className="header-dashed hidden lg:block"
      viewBox="0 0 906.42 245.5"
      style={style}
    >
      <defs>
        <pattern
          id="headerdashed-1"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 359 305"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderDashed1}
            width="359"
            height="305"
          />
        </pattern>
        <pattern
          id="headerdashed-2"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 341 282"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderDashed2}
            width="341"
            height="282"
          />
        </pattern>
        <pattern
          id="headerdashed-3"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 768 25"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderDashed3}
            width="768"
            height="25"
          />
        </pattern>
        <pattern
          id="headerdashed-4"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 53 88"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderDashed4}
            width="53"
            height="88"
          />
        </pattern>
        <pattern
          id="headerdashed-5"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
          viewBox="0 0 101 84"
        >
          <image
            preserveAspectRatio="none"
            xlinkHref={patternHeaderDashed5}
            width="101"
            height="84"
          />
        </pattern>
        <clipPath id="headerdashed-clip-2">
          <path
            transform="scale(1.6) translate(-160 -15)"
            d="M265,12.5l166,2-2,130"
          />
        </clipPath>
        <clipPath id="headerdashed-clip-rect">
          <Rect
            pose={isVisible ? 'to' : 'from'}
            x="530"
            y="205"
            width="350"
            height="20"
          />
        </clipPath>
      </defs>
      <G pose={isVisible ? 'to' : 'from'}>
        <Path
          fill="none"
          stroke="url(#headerdashed-1)"
          strokeWidth="30px"
          transform="scale(1.1) translate(-8 -10)"
          d="M190.49,233h-173l-5-143"
        />
        <Path
          fill="none"
          stroke="url(#headerdashed-2)"
          strokeWidth="30px"
          transform="scale(1.1) translate(12 -13)"
          clipPath="url(#headerdashed-clip-2)"
          d="M265,12.5l166,2-2,130"
        />
        {/* <Path
          fill="none"
          stroke="url(#headerdashed-3)"
          strokeWidth="20px"
          d="M875,213.5c-151.29,4-292.93,6.77-394,2"
        />
         */}
        <Rect2
          fill="url(#headerdashed-3)"
          stroke="none"
          clipPath="url(#headerdashed-clip-rect)"
          x="530"
          y="205"
          width="350"
          height="20"
        />
        <Path
          fill="none"
          stroke="url(#headerdashed-4)"
          strokeWidth="20px"
          d="M861,191.5a198.56,198.56,0,0,1,30,43"
        />
        <Path
          fill="none"
          stroke="url(#headerdashed-5)"
          strokeWidth="20px"
          d="M851,235.5c18.77-15.08,38.47-33.49,54-37"
        />
      </G>
    </svg>
  </>
);

HeaderDashed.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

export default HeaderDashed;
