import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern1 from 'images/footer/footer-playground-drawing-1.png';
import pattern2 from 'images/footer/footer-playground-drawing-2.png';
import pattern3 from 'images/footer/footer-playground-drawing-3.png';

const Rect = posed.rect({
  from: { opacity: 0 },
  to: {
    opacity: 100,
    transition: ({ dur = 0 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 250 },
  to: {
    delay: 250,
    staggerChildren: ({ dur = 600 }) => dur,
  },
});

const FooterPlayground = ({ style, footerOpen }) => (
  <svg viewBox="0 0 911.16 380.69" height="380.69" style={style}>
    <defs>
      <style>
        {
          '.a8accd95-2081-4929-81b8-0ae75a4d4a24{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;letter-spacing:-0.02em;}.a4d602ef-0ab7-4e1d-874a-c4482cc68c4b{letter-spacing:-0.04em;}.bb187f85-fe59-44d7-a31c-c851f41ae1f7{letter-spacing:-0.04em;}.fcf59695-fd43-4012-b3e7-b32fb5f22c88{letter-spacing:-0.03em;}.a65c8831-c987-42a1-aed2-0d4b4a97b8b6{letter-spacing:-0.01em;}.be30235b-0262-46a8-9465-2e045a710024{letter-spacing:-0.04em;}.ab1ca543-4622-40e4-89d2-43b9f6b11ff8{letter-spacing:-0.02em;}.ac237780-e49d-4047-84d9-10367132c534,.bfc35321-a263-49a7-b64a-db3873ad6129,.e4334062-b4d5-4d7b-a959-78ac4fe895bd{fill:none;stroke:#231f20;stroke-miterlimit:10;}.ac237780-e49d-4047-84d9-10367132c534{stroke-width:0.99px;}.e4334062-b4d5-4d7b-a959-78ac4fe895bd{stroke-width:0.83px;}'
        }
      </style>
      <pattern
        id="footer-playground-1"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 200 193"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern1}
          width="200"
          height="193"
        />
      </pattern>
      <pattern
        id="footer-playground-2"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 185 227"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern2}
          width="185"
          height="227"
        />
      </pattern>
      <pattern
        id="footer-playground-3"
        patternUnits="objectBoundingBox"
        width="1"
        height="1"
        viewBox="0 0 171 169"
      >
        <image
          preserveAspectRatio="none"
          xlinkHref={pattern3}
          width="171"
          height="169"
        />
      </pattern>
    </defs>
    <g>
      <text
        transform="translate(0 200.08)"
        className="font-display font-extrabold a8accd95-2081-4929-81b8-0ae75a4d4a24"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the pl
        <tspan
          className="a4d602ef-0ab7-4e1d-874a-c4482cc68c4b"
          x="338.76"
          y="0"
        >
          ay
        </tspan>
        <tspan x="477.06" y="0">
          g
        </tspan>
        <tspan
          className="bb187f85-fe59-44d7-a31c-c851f41ae1f7"
          x="555.75"
          y="0"
        >
          r
        </tspan>
        <tspan
          className="fcf59695-fd43-4012-b3e7-b32fb5f22c88"
          x="604.35"
          y="0"
        >
          o
        </tspan>
        <tspan
          className="a65c8831-c987-42a1-aed2-0d4b4a97b8b6"
          x="679.95"
          y="0"
        >
          und
        </tspan>
        <tspan className="a65c8831-c987-42a1-aed2-0d4b4a97b8b6">
          <tspan x="0" y="120.85">
            is{' '}
          </tspan>
          <tspan
            className="be30235b-0262-46a8-9465-2e045a710024"
            x="128.23"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="fcf59695-fd43-4012-b3e7-b32fb5f22c88"
            x="197.11"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="272.7" y="120.85">
            u
          </tspan>
          <tspan
            className="ab1ca543-4622-40e4-89d2-43b9f6b11ff8"
            x="348.97"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="399.45" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <G pose={footerOpen ? 'to' : 'from'}>
        <Rect
          fill="url(#footer-playground-1)"
          x="445.41"
          y="0.5"
          width="108.7"
          height="109.28"
        />
        <Rect
          fill="url(#footer-playground-2)"
          x="662.81"
          y="222.38"
          width="96.61"
          height="119.55"
        />
        <Rect
          fill="url(#footer-playground-3)"
          x="566.59"
          y="289.36"
          width="90.01"
          height="90.91"
        />
      </G>

      <g data-name="outlined-text">
        <path d="M32.76,146.11v32.36c0,5.1,2.55,6,7.93,6h5.37v15.57a77.93,77.93,0,0,1-9.67.54c-17.32,0-24.57-5.64-24.57-19.87V146.11H1.48V130.8H11.82V110.25H32.76V130.8h13.3v15.31Z" />
        <path d="M119.1,154.7v45.38H98.15V158.86c0-7.65-3.35-11.81-11.14-11.81-6.45,0-13.16,4.16-13.16,14.36v38.67H52.9v-95.6H73.85v35.45a25.52,25.52,0,0,1,21.22-10.74C108.76,129.19,119.1,137.51,119.1,154.7Z" />
        <path d="M197.79,171.62h-51.3c1.48,9.93,7.65,14.63,15.85,14.63,7,0,11.54-2.82,14-7.78h19.87C192.41,193.1,179,201.7,162.34,201.7c-21.49,0-36-14.24-36-36.26s14.1-36.25,35.72-36.25,35.72,14.9,35.72,38.27Zm-51.17-13.3h30.62c-1.48-8.59-7-13.69-15.44-13.69C154.14,144.63,148.24,148.66,146.62,158.32Z" />
        <path d="M306.41,165.44c0,22-11.41,36.26-30.21,36.26-9.4,0-16-3.76-20.41-10.07V226.4H234.84V130.8h20.41v9.26c4.43-6.71,11.14-10.87,20.95-10.87C295,129.19,306.41,143.42,306.41,165.44Zm-21.22-.54c0-13.16-5.5-18.93-14.77-18.93s-14.9,6.31-14.9,19.47,5.5,19.47,14.9,19.47S285.19,178.06,285.19,164.9Z" />
        <path d="M313.66,104.48H334.6v95.6H313.66Z" />
        <path d="M390.46,200.08a19.71,19.71,0,0,1-1.75-7.78c-4.43,5.9-11.95,9.4-22.15,9.4-14.5,0-25.25-7.39-25.25-21.22,0-11.95,7.12-19.87,27.13-21.62l10.88-.94c5.9-.67,9-2.42,9-6.85,0-4.16-2.28-7-10.61-7s-11.54,2-12.22,9.13H344.67c1.08-15.31,10.88-24,33.17-24,21.75,0,30.88,8.19,30.88,21.35v36.79c0,5.1.94,10.07,2.82,12.75Zm-2.15-27.25v-5.11c-1.48,1.62-3.76,2.29-7,2.69l-8.59.94c-7.12.8-10.07,3.49-10.07,8.32s3.62,7.93,10.2,7.93C380.93,187.6,388.31,183.7,388.31,172.83Z" />
        <path d="M482.3,130.8l-25.11,72c-6,17.46-14,23.77-34.51,23.77-4,0-6-.14-8.19-.27V210.16h6.72c7.79,0,10.47-4.44,10.47-9.67,0-3.9-1.61-8.86-3.22-13.43L408.59,130.8h22.55l3.09,9.8c4,13.56,8.06,27.8,11.82,43.64,3.76-16,7.65-30.08,11.55-43.51l3.09-9.93Z" />
        <path d="M551.72,130.8v67.14c0,18.66-12.62,30.07-35.45,30.07-21.62,0-32.76-10.47-33.57-25.37h20.68q.81,9.66,13.3,9.66c9.4,0,14.1-5.1,14.1-13.56v-9.93c-4.17,6-10.48,9.8-19.88,9.8-18.39,0-30.61-14.37-30.61-34.78s11.81-34.64,30.21-34.64c9.94,0,16.65,4.16,20.81,10.74V130.8Zm-20.54,33c0-11.68-5.51-17.86-14.91-17.86s-14.77,6.71-14.77,18.13,5.51,17.72,14.77,17.72S531.18,175.51,531.18,163.83Z" />
        <path d="M606.5,130v19.07h-5.37c-11.68,0-18.39,5.77-18.39,18.8v32.22h-21V130.8H582.2v12.89c2.95-8.6,9.4-14,19.74-14C603.55,129.72,605,129.86,606.5,130Z" />
        <path d="M607.58,165.44c0-22,14.23-36.25,36.38-36.25s36.26,14.23,36.26,36.25S666,201.7,644,201.7,607.58,187.46,607.58,165.44Zm51.42,0c0-13.16-5.77-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.78,19.47,15.17,19.47S659,178.6,659,165.44Z" />
        <path d="M752.19,130.8v69.28H731.78v-9.8c-4.57,6.45-11.55,11.42-21.75,11.42-13.7,0-24-8.33-24-25.52V130.8h21V172c0,7.65,3.36,11.82,11.14,11.82,6.45,0,13.16-4.17,13.16-14.37V130.8Z" />
        <path d="M828.46,154.7v45.38h-21V158.86c0-7.65-3.36-11.81-11.15-11.81-6.44,0-13.15,4.16-13.15,14.36v38.67h-21V130.8h20.41v9.8a25.79,25.79,0,0,1,21.75-11.41C818.12,129.19,828.46,137.51,828.46,154.7Z" />
        <path d="M907.14,104.48v95.6H886.73v-9c-4.43,6.58-11.15,10.61-20.81,10.61-18.8,0-30.22-14.24-30.22-36.26s11.42-36.25,30.22-36.25c9.26,0,15.84,3.76,20.27,9.93V104.48Zm-20.55,61c0-13.16-5.5-19.47-14.9-19.47s-14.77,6.85-14.77,20,5.5,18.93,14.77,18.93S886.59,178.6,886.59,165.44Z" />
        <path d="M6,251.64H27v69.29H6ZM6.58,226h20v17.72h-20Z" />
        <path d="M33,298.91H53.44C54.25,306.43,58,309,66.73,309c8.46,0,11.69-2.55,11.69-6.71s-2.55-6.18-12-7.66l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21C34.1,258.09,45,250,65.26,250c23.36,0,31.55,10.21,32.22,23.77H78c-.27-7.52-5.23-9.8-12.75-9.8s-10.61,2.55-10.61,6.58,3.36,5.9,10.07,7L75.06,279c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.42-33,22.42C45.12,322.94,34.24,316.1,33,298.91Z" />
        <path d="M202.08,251.64l-25.11,72c-6,17.45-14,23.76-34.51,23.76-4,0-6-.13-8.19-.27V331H141c7.79,0,10.48-4.43,10.48-9.67,0-3.89-1.62-8.86-3.23-13.43l-19.87-56.26h22.56l3.09,9.81c4,13.56,8.05,27.79,11.81,43.64,3.76-16,7.66-30.08,11.55-43.51l3.09-9.94Z" />
        <path d="M200.33,286.29c0-22,14.23-36.26,36.39-36.26S273,264.26,273,286.29s-14.23,36.25-36.25,36.25S200.33,308.31,200.33,286.29Zm51.43,0c0-13.16-5.78-19.47-15-19.47s-15.17,6.31-15.17,19.47,5.77,19.47,15.17,19.47S251.76,299.45,251.76,286.29Z" />
        <path d="M344.94,251.64v69.29H324.53v-9.8c-4.56,6.44-11.54,11.41-21.75,11.41-13.7,0-24-8.32-24-25.51V251.64h20.94v41.23c0,7.65,3.36,11.81,11.15,11.81,6.44,0,13.16-4.16,13.16-14.37V251.64Z" />
        <path d="M399.73,250.84V269.9h-5.38c-11.68,0-18.39,5.78-18.39,18.8v32.23H355V251.64h20.41v12.89c3-8.59,9.4-14,19.74-14C396.77,250.57,398.25,250.7,399.73,250.84Z" />
        <path d="M401.33,298.91h20.41c.81,7.52,4.57,10.07,13.3,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-12-7.66l-9-1.48c-14.63-2.28-23.36-8.86-23.36-21.21,0-13.83,10.88-21.89,31.15-21.89,23.36,0,31.56,10.21,32.23,23.77H446.32c-.27-7.52-5.24-9.8-12.76-9.8S423,266.55,423,270.58s3.36,5.9,10.07,7L443.36,279c16.38,2.55,24.31,9.13,24.31,21.48,0,14.37-12.22,22.42-33,22.42C413.42,322.94,402.54,316.1,401.33,298.91Z" />
        <path d="M471.83,297.83h23.63v23.1H471.83Z" />
      </g>
    </g>
  </svg>
);

FooterPlayground.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterPlayground;
