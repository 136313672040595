import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/blocks/button';

import { Container } from './form-community.css';

class FormCommunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormOpen: false,
    };
    this.openForm = this.openForm.bind(this);
    this.closeForm = this.closeForm.bind(this);
  }

  openForm() {
    this.setState({
      isFormOpen: true,
    });
  }

  closeForm() {
    this.setState({
      isFormOpen: false,
    });
  }

  render() {
    const { margin, pageColor } = this.props;
    const { isFormOpen } = this.state;

    return (
      <Container
        margin={margin}
        className={pageColor ? `typography ${pageColor}` : 'typography'}
      >
        {isFormOpen ? (
          <div className="relative">
            <Button onClick={this.closeForm} align="center" text="Close Form" />
            <div
              className={`py-16 bg-brand-${pageColor} h-screen -mx-4 xs:-mx-10 sm:-mx-20 flex`}
            >
              <iframe
                className="w-full max-w-xl mx-auto"
                src="https://docs.google.com/forms/d/e/1FAIpQLSfx1qgbVlZyI37RD3uVdSQ2icoW0x_jNxw8Ot2344rBAR8PJA/viewform?embedded=true"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="NDC Community Intake Form"
              >
                Loading…
              </iframe>
            </div>
          </div>
        ) : (
          <Button onClick={this.openForm} align="center" text="Apply" />
        )}
      </Container>
    );
  }
}

FormCommunity.propTypes = {
  pageColor: PropTypes.string,
  margin: PropTypes.string,
};

export default FormCommunity;
