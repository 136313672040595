import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import pattern1 from 'images/footer/footer-lights-drawing-1.png';
import pattern2 from 'images/footer/footer-lights-drawing-2.png';
import pattern3 from 'images/footer/footer-lights-drawing-3.png';
import pattern4 from 'images/footer/footer-lights-drawing-4.png';
import pattern5 from 'images/footer/footer-lights-drawing-5.png';
import pattern6 from 'images/footer/footer-lights-drawing-6.png';
import pattern7 from 'images/footer/footer-lights-drawing-7.png';
import pattern8 from 'images/footer/footer-lights-drawing-8.png';
import pattern9 from 'images/footer/footer-lights-drawing-9.png';
import pattern10 from 'images/footer/footer-lights-drawing-10.png';
import pattern11 from 'images/footer/footer-lights-drawing-11.png';
import pattern12 from 'images/footer/footer-lights-drawing-12.png';

const Path = posed.path({
  from: { pathLength: 0 },
  to: {
    pathLength: 100,
    transition: ({ dur = 100 }) => ({
      duration: dur,
    }),
  },
});

const G = posed.g({
  from: { delay: 100 },
  to: {
    delay: 100,
    staggerChildren: ({ dur = 100 }) => dur,
  },
});

const FooterLights = ({ style, footerOpen }) => (
  <svg viewBox="0 0 703.03 429.96" height="429.96" style={style}>
    <defs>
      <style>
        {
          '.ae658cc3-c249-4f9d-83fb-58844dd9a052{font-size:134.27px;font-family:HelveticaNowDisplay-XBd, Helvetica Now Display;}.ae658cc3-c249-4f9d-83fb-58844dd9a052,.bf0e5562-adc8-42d2-a901-99a95a4cbfe0{letter-spacing:-0.02em;}.b4592cef-46f3-4532-8d41-ea9eba454333{letter-spacing:-0.03em;}.b8220b5c-c23f-46e2-97d0-24def1dbdda5{letter-spacing:-0.01em;}.becd21ea-cc2b-48ee-8665-c9914f278e3c{letter-spacing:-0.01em;}.b26a15ee-c845-437e-8319-dedbadcbb693{letter-spacing:-0.04em;}.ab7d53a0-4bcf-44fe-96a9-6cc8a23f73cf{letter-spacing:-0.04em;}.ba914546-6bb8-4dae-8a12-692449d4f606{letter-spacing:-0.03em;}.ac31e22f-d932-42df-9d81-df5a3cb44324{letter-spacing:-0.02em;}.a6cd4dc5-9c4c-49d0-984f-175aaec4f6eb{fill:none;stroke:#231f20;stroke-miterlimit:10;}'
        }
      </style>
    </defs>
    <pattern
      id="footer-lights-1"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 136 78"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern1}
        width="136"
        height="78"
      />
    </pattern>
    <pattern
      id="footer-lights-2"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 163 45"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern2}
        width="163"
        height="45"
      />
    </pattern>
    <pattern
      id="footer-lights-3"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 101 85"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern3}
        width="101"
        height="85"
      />
    </pattern>
    <pattern
      id="footer-lights-4"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 101 121"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern4}
        width="101"
        height="121"
      />
    </pattern>
    <pattern
      id="footer-lights-5"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 35 145"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern5}
        width="35"
        height="145"
      />
    </pattern>
    <pattern
      id="footer-lights-6"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 87 154"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern6}
        width="87"
        height="154"
      />
    </pattern>
    <pattern
      id="footer-lights-7"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 167 102"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern7}
        width="167"
        height="102"
      />
    </pattern>
    <pattern
      id="footer-lights-8"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 180 76"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern8}
        width="180"
        height="76"
      />
    </pattern>
    <pattern
      id="footer-lights-9"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 193 57"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern9}
        width="193"
        height="57"
      />
    </pattern>
    <pattern
      id="footer-lights-10"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 113 111"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern10}
        width="113"
        height="111"
      />
    </pattern>
    <pattern
      id="footer-lights-11"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 59 112"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern11}
        width="59"
        height="112"
      />
    </pattern>
    <pattern
      id="footer-lights-12"
      patternUnits="objectBoundingBox"
      width="1"
      height="1"
      viewBox="0 0 119 131"
    >
      <image
        preserveAspectRatio="none"
        xlinkHref={pattern12}
        width="119"
        height="131"
      />
    </pattern>
    <g>
      <text
        className="font-display font-extrabold ae658cc3-c249-4f9d-83fb-58844dd9a052"
        transform="translate(0 210.81)"
        data-name="text-text"
        fill="rgba(0,0,0,0.01)"
      >
        the lig
        <tspan
          className="b4592cef-46f3-4532-8d41-ea9eba454333"
          x="369.78"
          y="0"
        >
          h
        </tspan>
        <tspan className="b8220b5c-c23f-46e2-97d0-24def1dbdda5" x="443.9" y="0">
          t
        </tspan>
        <tspan
          className="becd21ea-cc2b-48ee-8665-c9914f278e3c"
          x="491.03"
          y="0"
        >
          s
        </tspan>
        <tspan className="becd21ea-cc2b-48ee-8665-c9914f278e3c">
          <tspan x="0" y="120.85">
            a
          </tspan>
          <tspan
            className="b26a15ee-c845-437e-8319-dedbadcbb693"
            x="73.04"
            y="120.85"
          >
            r
          </tspan>
          <tspan
            className="bf0e5562-adc8-42d2-a901-99a95a4cbfe0"
            x="121.65"
            y="120.85"
          >
            e{' '}
          </tspan>
          <tspan
            className="ab7d53a0-4bcf-44fe-96a9-6cc8a23f73cf"
            x="227.32"
            y="120.85"
          >
            y
          </tspan>
          <tspan
            className="ba914546-6bb8-4dae-8a12-692449d4f606"
            x="296.2"
            y="120.85"
          >
            o
          </tspan>
          <tspan x="371.8" y="120.85">
            u
          </tspan>
          <tspan
            className="ac31e22f-d932-42df-9d81-df5a3cb44324"
            x="448.06"
            y="120.85"
          >
            r
          </tspan>
          <tspan x="498.55" y="120.85">
            s.
          </tspan>
        </tspan>
      </text>

      <G pose={footerOpen ? 'to' : 'from'}>
        <Path
          fill="transparent"
          stroke="url(#footer-lights-1)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M214.18,323.1,146,356.46"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-2)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M222.62,220.28l-83-14.54"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-3)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M182.65,76.23,231.3,118"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-4)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M235.37,33.25l47.14,61"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-5)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M375.49,0l14,85.09"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-6)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M523.05,4.59l-36.47,77.1"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-7)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M660.9,92.24l-82.26,41.29"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-8)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M702.89,164,613.6,190.84"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-9)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M679.6,298.31l-97-19.79"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-10)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M479.5,355.31l55,54.44"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-11)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M341.39,429.81l17.43-56.42"
        />
        <Path
          fill="transparent"
          stroke="url(#footer-lights-12)"
          // stroke="red"
          strokeWidth="10px"
          // clipPath="url(#parks-clip-1)"
          d="M210.06,423.62,266.32,361"
        />
      </G>

      <g data-name="outlined-text">
        <path d="M32.76,156.83v32.36c0,5.11,2.55,6.05,7.93,6.05h5.37v15.57a77.93,77.93,0,0,1-9.67.54c-17.32,0-24.57-5.64-24.57-19.87V156.83H1.48v-15.3H11.82V121H32.76v20.55h13.3v15.3Z" />
        <path d="M119.1,165.43v45.38H98.15V169.59c0-7.65-3.35-11.82-11.14-11.82-6.45,0-13.16,4.17-13.16,14.37v38.67H52.9v-95.6H73.85v35.45a25.53,25.53,0,0,1,21.22-10.75C108.76,139.91,119.1,148.24,119.1,165.43Z" />
        <path d="M197.79,182.35h-51.3C148,192.28,154.14,197,162.34,197c7,0,11.54-2.82,14-7.79h19.87c-3.76,14.64-17.18,23.23-33.83,23.23-21.49,0-36-14.23-36-36.25s14.1-36.26,35.72-36.26,35.72,14.91,35.72,38.27Zm-51.17-13.3h30.62c-1.48-8.59-7-13.69-15.44-13.69C154.14,155.36,148.24,159.38,146.62,169.05Z" />
        <path d="M234.84,115.21h20.95v95.6H234.84Z" />
        <path d="M266,141.53h20.95v69.28H266Zm.54-25.65h20V133.6h-20Z" />
        <path d="M365.76,141.53v67.13c0,18.67-12.63,30.08-35.45,30.08-21.62,0-32.77-10.47-33.57-25.38h20.68q.79,9.67,13.29,9.67c9.4,0,14.1-5.1,14.1-13.56v-9.94c-4.16,6.05-10.48,9.81-19.87,9.81-18.4,0-30.62-14.37-30.62-34.78s11.82-34.65,30.21-34.65c9.94,0,16.65,4.17,20.82,10.75v-9.13Zm-20.55,33c0-11.68-5.5-17.86-14.9-17.86s-14.77,6.71-14.77,18.13,5.5,17.72,14.77,17.72S345.21,186.24,345.21,174.56Z" />
        <path d="M442,165.43v45.38H421.08V169.59c0-7.65-3.36-11.82-11.15-11.82-6.45,0-13.16,4.17-13.16,14.37v38.67H375.82v-95.6h20.95v35.45A25.53,25.53,0,0,1,418,139.91C431.68,139.91,442,148.24,442,165.43Z" />
        <path d="M476.66,156.83v32.36c0,5.11,2.55,6.05,7.93,6.05H490v15.57a77.93,77.93,0,0,1-9.67.54c-17.32,0-24.57-5.64-24.57-19.87V156.83H445.38v-15.3h10.34V121h20.94v20.55H490v15.3Z" />
        <path d="M492.91,188.79h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.71s-2.55-6.18-11.95-7.66l-9-1.47C502.71,180.73,494,174.15,494,161.8c0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.21,32.23,23.77H537.89c-.27-7.52-5.24-9.8-12.76-9.8s-10.6,2.55-10.6,6.58,3.35,5.91,10.07,7l10.34,1.48c16.38,2.55,24.3,9.13,24.3,21.48,0,14.37-12.22,22.43-33,22.43C505,212.83,494.12,206,492.91,188.79Z" />
        <path d="M51.7,331.66A19.76,19.76,0,0,1,50,323.87c-4.43,5.91-12,9.4-22.16,9.4-14.5,0-25.24-7.39-25.24-21.22,0-11.95,7.12-19.87,27.13-21.62l10.87-.94c5.91-.67,9-2.41,9-6.84,0-4.17-2.28-7-10.61-7s-11.55,2-12.22,9.14H5.91c1.07-15.31,10.88-24,33.16-24C60.83,260.76,70,269,70,282.11V318.9c0,5.1.94,10.07,2.82,12.76ZM49.55,304.4v-5.1c-1.48,1.61-3.76,2.28-7,2.68l-8.6.94c-7.11.81-10.07,3.49-10.07,8.33s3.63,7.92,10.21,7.92C42.16,319.17,49.55,315.28,49.55,304.4Z" />
        <path d="M123.8,261.57v19.06h-5.37c-11.68,0-18.4,5.78-18.4,18.8v32.23H79.09V262.37H99.5v12.89c3-8.59,9.39-14,19.73-14C120.85,261.3,122.32,261.43,123.8,261.57Z" />
        <path d="M196.31,303.19H145c1.48,9.94,7.66,14.64,15.85,14.64,7,0,11.55-2.82,14-7.79H194.7c-3.76,14.64-17.19,23.23-33.84,23.23-21.49,0-36-14.23-36-36.26,0-21.88,14.1-36.25,35.72-36.25s35.72,14.9,35.72,38.27ZM145.15,289.9h30.61c-1.47-8.6-7-13.7-15.44-13.7C152.67,276.2,146.76,280.23,145.15,289.9Z" />
        <path d="M301.17,262.37l-25.11,72c-6,17.46-14,23.77-34.51,23.77-4,0-6-.13-8.19-.27V341.73h6.72c7.78,0,10.47-4.43,10.47-9.67,0-3.89-1.61-8.86-3.22-13.43l-19.88-56.26H250l3.09,9.8c4,13.56,8.06,27.8,11.82,43.64,3.76-16,7.65-30.08,11.54-43.5l3.09-9.94Z" />
        <path d="M299.42,297c0-22,14.24-36.25,36.39-36.25S372.07,275,372.07,297s-14.24,36.26-36.26,36.26S299.42,319,299.42,297Zm51.43,0c0-13.15-5.77-19.47-15-19.47s-15.17,6.32-15.17,19.47,5.77,19.47,15.17,19.47S350.85,310.17,350.85,297Z" />
        <path d="M444,262.37v69.29H423.63v-9.8c-4.57,6.44-11.55,11.41-21.75,11.41-13.7,0-24-8.33-24-25.51V262.37h21v41.22c0,7.66,3.35,11.82,11.14,11.82,6.45,0,13.16-4.16,13.16-14.37V262.37Z" />
        <path d="M498.82,261.57v19.06h-5.37c-11.68,0-18.4,5.78-18.4,18.8v32.23H454.11V262.37h20.41v12.89c3-8.59,9.39-14,19.73-14C495.87,261.3,497.34,261.43,498.82,261.57Z" />
        <path d="M500.43,309.64h20.41c.8,7.52,4.56,10.07,13.29,10.07,8.46,0,11.68-2.55,11.68-6.72s-2.55-6.17-11.95-7.65l-9-1.48c-14.64-2.28-23.37-8.86-23.37-21.21,0-13.83,10.88-21.89,31.15-21.89,23.37,0,31.56,10.2,32.23,23.77H545.41c-.27-7.52-5.24-9.81-12.76-9.81s-10.6,2.56-10.6,6.58,3.35,5.91,10.07,7l10.34,1.47c16.38,2.55,24.3,9.13,24.3,21.49,0,14.37-12.22,22.42-33,22.42C512.51,333.67,501.64,326.82,500.43,309.64Z" />
        <path d="M570.92,308.56h23.63v23.1H570.92Z" />
      </g>
    </g>
  </svg>
);

FooterLights.propTypes = {
  style: PropTypes.object,
  footerOpen: PropTypes.bool.isRequired,
};

export default FooterLights;
