import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Flex,
  StyledImage,
  Quote,
  Attribution,
  Margin,
} from './pullquote.css';

const Pullquote = ({ pageColor, quote, attribution, image, prev, next }) => (
  <Margin>
    <Container
      color={pageColor}
      margin={`${next ? 'mb-16 sm:mb-32' : ''} ${prev ? 'mt-16 sm:mt-32' : ''}`}
    >
      <Flex>
        {image && <StyledImage fluid={image.fluid} />}
        <div>
          {quote.slice(0, 1) == '"' || quote.slice(0, 1) == '“' ? (
            <Quote>{quote}</Quote>
          ) : (
            <h3>{quote}</h3>
          )}
          {attribution && <Attribution>— {attribution}</Attribution>}
        </div>
      </Flex>
    </Container>
  </Margin>
);

Pullquote.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  pageColor: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  attribution: PropTypes.string,
  image: PropTypes.object,
  prev: PropTypes.string,
  next: PropTypes.string,
};

export default Pullquote;
